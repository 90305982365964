import React, { Component } from "react";
import {
  Route,
  Navigate,
} from "react-router-dom";
import { Routes } from 'react-router';
import Honeybadger from '@honeybadger-io/js'
import ErrorBoundary from '@honeybadger-io/js'

import Intercom from "react-intercom";
import axios from "axios";
import Auth from "./modules/Auth.js";

import SignIn from "./SignIn.js";
import ForgotPassword from "./ForgotPassword.js";
import ForgotPasswordConfirm from "./ForgotPasswordConfirm.js";
import ResetPassword from "./ResetPassword.js";
import SignUpOne from "./SignUpOne.js";
import SignUpTwo from "./SignUpTwo.js";
import SignUpThree from "./SignUpThree.js";
import SignUpFinal from "./SignUpFinal.js";
import ShopifyConnect from "./ShopifyConnect.js";
import LabOrderConfirmation from "./LabOrderConfirmation.js";

import Account from "./Account.js";
import CreditBalance from "./CreditBalance.js";

import ApiInvoices from "./ApiInvoices.js";
import Photographers from "./Photographers.js";

import LabOrders from "./LabOrders.js";
import Uploader from "./Uploader.js";
import UploaderProgress from "./UploaderProgress.js";

import OrderBuilder from "./OrderBuilder.js";
import OrderPreview from "./OrderPreview.js";
import OrderReview from "./OrderReview.js";

import CheckoutDetails from "./CheckoutDetails.js";
import CheckoutFinal from "./CheckoutFinal.js";

import CartPreview from "./CartPreview.js";

import PriceCalculator from "./PriceCalculator.js";

import PhotographerProfile from "./PhotographerProfile.js";

import PriceLists from "./PriceLists.js";

import MergeOrderReview from './MergeOrderReview.js';

class App extends Component {
  constructor() {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    axios.defaults.timeout = 1200000

    console.log("baseUrl is: " + axios.defaults.baseURL)
    axios.defaults.headers["Content-Type"] = "application/json";
    axios.defaults.headers["Accept"] = "application/json";
    super();
    this.props = { location: '/' };
    //const { match, location, history } = this.props;
    this.state = {
      auth: Auth.isAuthenticated(),
      redirectToReferrer: false,
    };
  }

  //404 page
  //<Route component={NoMatch}/>

  render() {
    function PrivateRoute({ component: Component, ...rest }) {
      return (
        //console.log("Auth " + Auth.isAuthenticated()),
        //console.log("component " + JSON.stringify({ ...rest })),
        (
          <Route
            {...rest}
            render={props =>
              Auth.isAuthenticated() ? (
                <Component {...props} />
              ) : (
                  <Navigate
                    push
                    to={{
                      pathname: "/login",
                      state: { from: props.location }
                    }}
                  />
                )
            }
          />
        )
      );
    }

    return (
      <div className="App">
        <Intercom appID={process.env.REACT_APP_INTERCOM_KEY} user_hash={localStorage.getItem("intercom_user_hash")} email={localStorage.getItem("email")} name={localStorage.getItem("name")} businessName={localStorage.getItem("businessName")} />

        <Route exact path="/login" component={SignIn} />

        <Route exact path="/signup/1-of-3" component={SignUpOne} referredLink={false} />
        <Route exact path="/refer/:id" component={SignUpOne} referredLink={true} />
        <Route exact path="/shopify-connect" component={ShopifyConnect} />
        <PrivateRoute exact path="/signup/2-of-3" component={SignUpTwo} />
        <PrivateRoute exact path="/signup/3-of-3" component={SignUpThree} />
        <PrivateRoute exact path="/signup/confirmation" component={SignUpFinal} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/forgot-password-confirmation" component={ForgotPasswordConfirm} />

        <Route exact path="/reset-password" component={ResetPassword} />
        <PrivateRoute exact path="/" component={LabOrders} />
        <PrivateRoute exact path="/account" component={Account} />
        <PrivateRoute exact path="/credit" component={CreditBalance} />
        <PrivateRoute exact path="/calculate" component={PriceCalculator} />
        <PrivateRoute exact path="/price-lists" component={PriceLists} />
        {/* Cart Preview */}
        <PrivateRoute exact path="/cart-preview/:id" component={CartPreview} />
        <Routes>
          <PrivateRoute exact path="/lab-orders" component={LabOrders} />

          <PrivateRoute exact path="/api-invoices" component={ApiInvoices} />
          <PrivateRoute exact path="/photographers/:guid" component={PhotographerProfile} />
          <PrivateRoute exact path="/photographers" component={Photographers} />

          <PrivateRoute exact path="/lab-order-confirmation" component={LabOrderConfirmation} />
          <PrivateRoute exact path="/lab-orders/:id/edit/order-builder" component={OrderBuilder} />
          <PrivateRoute exact path="/lab-orders/:id/edit/order-builder/line-item/:line_item_id" component={OrderBuilder} />
          <PrivateRoute exact path="/lab-orders/:id/review" component={OrderReview} />
          <PrivateRoute exact path="/lab-orders/:startOrderId/merge-order/:endOrderId/review" component={MergeOrderReview} />
          <PrivateRoute exact path="/lab-orders/:id/checkout-details" component={CheckoutDetails} />
          <PrivateRoute exact path="/lab-orders/upload" component={Uploader} />
          <PrivateRoute exact path="/lab-orders/:id/upload-progress" component={UploaderProgress} />
          <PrivateRoute exact path="/lab-orders/:id/checkout-final/" component={CheckoutFinal} />
          <PrivateRoute exact path="/lab-orders/:id" component={OrderPreview} />
        </Routes>

      </div>
    );
  }
}

export default App;
