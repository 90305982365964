import React, { Component } from "react";
import { Navigate, Link } from "react-router-dom";
import escapeRegExp from 'escape-string-regexp'

import NavBar from "./components/NavBar.js";
import axios from "axios";
import Auth from "./modules/Auth.js";
import MuseaApi from "./modules/MuseaApi.js";

import PhotographerCard from "./components/PhotographerCard.js";


class Photographers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      searching: false,
      errorState: false,
      query: "",
      filters: ["unapproved"],
      photographers: {'data': []},
      approvedColor: 'rgba(74, 144, 226, 1.0)',
      apiVendorColor: 'rgba(144, 19, 254, 1.0)',
      resetColor: 'rgba(112,112,112, 0.8)'
    };
    this.handleChange = this.handleChange.bind(this);
    this.setStyle = this.setStyle.bind(this);
    this.addToFilter = this.addToFilter.bind(this);
    this.findPhotographers = this.findPhotographers.bind(this);
    this.formatCreatedAtDate = this.formatCreatedAtDate.bind(this);
    this.filterQueryMatchingPhotographers = this.filterQueryMatchingPhotographers.bind(this)
  }

  componentDidMount() {
    let museaApi = new MuseaApi
    // museaApi.checkAndResetCache()
    this.getPhotographers()
  }

  handleChange(e) {
    const val = e.target.value;
    const name = e.target.name

    if (name === 'query' && val.length >= 3) {
      this.setState({searching: true})
      this.findPhotographers(val)
    } else {
      this.setState({
        [name]: val
      });
    }

    //console.log(this.state);
  }

  addToFilter(attribute) {
    //console.log("adding filter")
    let filters = this.state.filters
    let newFilters

    if (filters.includes(attribute)) {
      newFilters = filters.filter(item => item !== attribute)
    } else {
      filters.push(attribute)
      newFilters = filters
    }
    if (attribute === "reset") {
      newFilters = []
    }

    //console.log("newFilters " + newFilters.toString())
    this.setState({
      filters: newFilters
    })
  }

  setStyle(name, color, filters) {
    //this.setStyle("hide_my_orders", resetColor, filters)}
    //style={{borderColor: resetColor, color: resetColor}}
    let style = {}
    if (filters.includes(name)) {
      style = {borderColor: color,
        backgroundColor: color,
        color: 'white' }
    } else if (name === "hide_my_orders" || name === "hide_open") {
      if (filters.includes(name)) {
        style = {backgroundColor: '' + color + ' !important',
          color: 'white !important' }
      } else {
        style = {borderColor: color,
          backgroundColor: 'white',
          color: color }
      }
    } else {
      style = {borderColor: color,
        color: color }
    }



    //console.log("style for " + name + "\n" + style.backgroundColor)
    return style
  }

  filterQueryMatchingPhotographers(photographers, filters, photographerId) {
    let matchingPhotographers
    let appliedFilters = []
    filters.forEach(filter => {
      if (["approved"].includes(filter)) {
        appliedFilters.push("photographer.attributes.user_" + filter + " === true")
      } else if (filter === "hide_open") {
        appliedFilters.push("photographer.attributes.status !== 'open'")
      }
    })

    let filterString = appliedFilters.join(" && ")
    if (filterString.length > 0){
    matchingPhotographers = photographers.filter(photographer =>
                                      eval(filterString)
    )
    } else {
      matchingPhotographers = photographers
    }

    return matchingPhotographers
  }

  throwError(err) {
    let errorMessage, errorObject, stackTrace
    if (err.response && err.response.status === 401) {
      this.setState({
        userLoggedOut: true
      })
      return false
    } else {
      if (err.response) {
        errorObject = err.response.data.error
        stackTrace = err.response.data.error.more_info.join(" ,").split(",").join("\n")
      } else {
        errorObject = {status: 500, class_name: "" + err.name , developer_message: err.message}
        stackTrace = err.stack
      }
      errorMessage = "\nERROR CODE: " + errorObject.status + "\n ERROR CLASS: " + errorObject.class_name + " \nERROR MESSAGE: " + errorObject.developer_message
      console.log("ERROR MESSAGE: " + errorMessage + "\n MORE INFO: " + stackTrace)

      this.setState({
        loading: false,
        query: "",
        errorState: true,
        errorMessage: errorMessage
      })
    }
  }

  findPhotographers(query) {
    //e.preventDefault();
    var token = Auth.getToken()
    axios
      .get("/api/v2/find_photographer?query=" + query,
           {headers: {'token': token} },
           //{params: {photographers: {query: query}}},
          )
      .then(res => {
        console.log(res.data);
        let currentUserAdmin, currentUserApiVendor, viewAsPhotographerId, viewingAsPhotographer
        if (res.data.meta) {
          currentUserAdmin = res.data.meta.current_user_is_admin
          currentUserApiVendor = res.data.meta.current_user_is_api_vendor
          viewAsPhotographerId = res.data.meta.view_as_photographer_id
          viewingAsPhotographer = res.data.meta.view_as_photographer
        } else {
          currentUserAdmin = false
          currentUserApiVendor = false
          viewAsPhotographerId = null
          viewingAsPhotographer = null
        }
        this.setState({
          photographers: res.data,
          userIsAdmin: currentUserAdmin,
          userIsApiVendor: currentUserApiVendor,
          viewAsPhotographerId: viewAsPhotographerId,
          viewingAsPhotographer: viewingAsPhotographer,
          query: query,
          loading: false,
          searching: false,
          errorState: false
        });
        return true;
      })
      .catch(err =>
             this.throwError(err)
            )
  };

  getPhotographers = (e, data) => {
    //e.preventDefault();
    var token = Auth.getToken()
    axios
      .get("/api/v2/photographers", {headers: {'token': token}})
      .then(res => {
        //console.log(res.data);
        let currentUserAdmin, currentUserApiVendor, viewAsPhotographerId, viewingAsPhotographer
        if (res.data.meta) {
          currentUserAdmin = res.data.meta.current_user_is_admin
          currentUserApiVendor = res.data.meta.current_user_is_api_vendor
          viewAsPhotographerId = res.data.meta.view_as_photographer_id
          viewingAsPhotographer = res.data.meta.view_as_photographer
        } else {
          currentUserAdmin = false
          currentUserApiVendor = false
          viewAsPhotographerId = null
          viewingAsPhotographer = null
        }
        this.setState({
          redirectToReferrer: true,
          auth: Auth.isAuthenticated(),
          from: { pathname: "/" },
          photographers: res.data,
          userIsAdmin: currentUserAdmin,
          userIsApiVendor: currentUserApiVendor,
          viewAsPhotographerId: viewAsPhotographerId,
          viewingAsPhotographer: viewingAsPhotographer,
          query: "",
          loading: false,
          searching: false,
          errorState: false,
          parsedDates: []
        });
        return true;
      })
      .catch(err =>
             this.throwError(err)
            )
  };


  formatCreatedAtDate(photographer) {
    let options = {month: 'long', day: 'numeric', year: 'numeric' };
    let rawParsedDate
    if (photographer.created_at) {
      rawParsedDate = new Date(Date.parse(photographer.attributes.created_at)).toLocaleDateString("en-US", options) + " ORDERS"
    } else {
      rawParsedDate = new Date(Date.parse(photographer.attributes.created_at)).toLocaleDateString("en-US", options) + " ORDERS"
    }
    let parsedDate = rawParsedDate.toUpperCase()
    return parsedDate

  }

  render() {
    let photographerCards = []
    let {query, filters, userIsAdmin, photographers, loading, searching,  errorState, errorMessage, userLoggedOut} = this.state

    if (photographers && photographers['data'].length > 0 && userIsAdmin ) {
      photographerCards = photographers['data'].map(photographer => {
        return (<PhotographerCard key={photographer.id}
                guid={photographer.attributes.guid}
                creditAvailable={photographer.attributes.credit_available}
                photographer={photographer.attributes}
                email={photographer.attributes.email}
                />);
      })
    }

    if(loading) {
      return (
        <div>
          <NavBar />

          <div className="LabOrders">

          <div className="container-3 w-container">

            <div className="search w-form">
              <h1 style={{textAlign: 'center'}}>loading</h1>
            </div>
          </div>

       </div>
     </div>
    )} else if(searching) {
      return (
        <div>
          <NavBar />
          <div className="Photographers">

            <div>

              <div className="search w-form">
                <input
                type="text"
                className="search-input w-input"
                name="query"
                onChange={this.handleChange}
                placeholder="Search by Photographer Name, Business, or Email"
                />
              </div>

              <PhotographerCard key="header"
              guid="header"
              photographer={{first_name: "NAME", email: "EMAIL", business_name: "BUSINESS NAME", password: "PASSWORD", password_confirmation: "PASSWORD CONFIRMATION", user_approved: "APPROVED", creditAvailable: "CREDIT"}} />
          <div className="container-3 w-container">

            <div className="search w-form">
              <h1 style={{textAlign: 'center'}}>searching</h1>
            </div>
          </div>
        </div>
        </div>
      </div>
    )} else if(errorState !== false) {
      return(
        <div>
          <NavBar />
          <div className="LabOrders">

          <div className="container-3 w-container">

            <div className="search w-form">
             <div className="w-form-fail"
               style={{textAlign: "center",
                 display: 'inline-block',
                 overflowWrap: "break-word"}}>
               ERROR LOADING: {errorMessage}
             </div>
           </div>
          </div>
       </div>
     </div>
      )} else {
      return (
        <div>
          <NavBar />
          <div className="Photographers">

            <div>

              <div className="search w-form">
                <input
                type="text"
                className="search-input w-input"
                name="query"
                onChange={this.handleChange}
                placeholder="Search by Photographer Name, Business, or Email"
                />
              </div>

              <PhotographerCard key="header"
              guid="header"
              photographer={{first_name: "NAME", email: "EMAIL", business_name: "BUSINESS NAME", password: "PASSWORD", password_confirmation: "PASSWORD CONFIRMATION", user_approved: "APPROVED", creditAvailable: "CREDIT"}} />
              {photographerCards}
            </div>
        </div>
      </div>
      );
    }
  }
}

export default Photographers;
