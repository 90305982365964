import React, { Component } from "react";
import { Navigate, Link } from "react-router-dom";

import Auth from "./modules/Auth.js";
import axios from "axios";
import NavBar from "./components/NavBar.js";

//import creditLine from "./img/credit-page-line-3.png";
//import creditLine2 from "./img/credit-page-line-3 2.png";
//import creditLine3 from "./img/credit-page-line-3 2.png";
//import download from "./img/credit-page-outlineclouddownloadblack48dp.png";

class Uploader extends Component {

  constructor() {
    super();
    this.state = {
      creating: false,
      guid: '',
      redirectToReferrer: false,
      auth: Auth.isAuthenticated()
    };
   this.handleChange = this.handleChange.bind(this);
   this.throwError = this.throwError.bind(this)
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.innerHtml = "";
    document.body.appendChild(script);
  }

  handleChange(e) {
    const name = e.target.name;
    const val = e.target.value;
    this.setState({
      [name]: val
    });
    //console.log(this.state);
  }

  handleSubmit(e) {
    e.preventDefault()
    this.setState({
      creating: true
    });

    var token = Auth.getToken()
    axios
    .post('/api/v2/lab_orders',
          {order_name: this.state.orderName,
          }, {headers: {'token': token}})
          .then(res => {
            //console.log(result);
            var orderGuid = res.data.data.attributes.guid
            this.setState({
              guid: orderGuid
            });
          })
          .catch(err =>
                 this.throwError(err))
  }

  throwError(err) {
    let errorMessage, errorObject
    if (err.response.data["error"] && err.response.data["error"].status === 401) {
      this.setState({
        userLoggedOut: true
      })
      return false
    } else {
      if (err.response.data["error"] != null) {
        errorObject = err.response.data["error"]
        errorMessage = "\nERROR CODE: " + errorObject.status + "\nERROR CLASS: " + errorObject.class_name + " \nERROR MESSAGE: " + errorObject.user_message["message"]
        if (errorObject["more_info"] != null) {
          this.state.honeybadger.notify(err)
          alert("ERROR: " + errorMessage)
          console.log("ERROR: " + errorMessage + "\nMORE INFO: " + errorObject["more_info"].join(" ,").split(",").join("\n"))
        } else {
          this.state.honeybadger.notify(err)
          alert("ERROR: " + errorMessage)
          console.log(errorMessage + " More info not available.")
        }
      } else { 
        errorMessage = "\nERROR CODE: " + err.response.status + "\n ERROR MESSAGE: " + err.response.message
      }
    }
    this.setState({
      loading: false,
      query: "",
      errorState: true,
      creating: false,
      errorMessage: errorMessage
    })
  }

  render() {

    if (this.state.guid && this.state.guid.length > 0 ) {
      const redirectPath = `/lab-orders/${this.state.guid}/upload-progress`;
      return <Navigate push to={{ pathname: redirectPath, state: { guid: this.state.guid } }} />
    }

    let createOrderButton
    if (this.state.orderName && this.state.orderName.length > 0) {
      createOrderButton = <input type="submit" style={{width: '200px'}} value="SAVE AND UPLOAD" onClick={(e) => {this.handleSubmit(e)}} className="submit-button-2 w-button"/>
    } else {
      createOrderButton = <div value="UPLOAD" style={{width: '200px', color: 'white', height: '40px', opacity: '0.5', pointer: 'default', pointerEvents: 'none', textAlign: 'center'}} className="submit-button-2 w-button">SAVE AND UPLOAD</div>
    }

  if (this.state.creating) {
      return (
        <div>
          <NavBar />
          <div className="Uploader">
            <div style={{marginTop: '10%', marginLeft: '45%',
            fontSize: '22px', fontWeight: 700}}>
              creating order...
            </div>
          </div>
        </div>
      );
    } else if (this.state.errorState) {
      return (
        <div>
          <NavBar />
          <div className="Uploader">
            <div style={{marginTop: '10%', marginLeft: '25%',
              width: '50%', textAlign: 'center',
            fontSize: '22px', fontWeight: 700}}>
              ERROR LOADING: {this.state.errorMessage}
              <br/>
            <Link to="/lab-orders/upload">
                reload this page
            </Link>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <NavBar />
          <div className="w-form">
            <form id="email-form" name="email-form" data-name="Email Form" className="form-2">
              <input type="text" className="text-field-3 w-input" onChange={this.handleChange} name="orderName" placeholder="Order Name"/>
               {createOrderButton}
            </form>
          </div>


        </div>
      );
    }
  }
}

export default Uploader;
