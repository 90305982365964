import React, { Component } from "react";
import {
  //BrowserRouter as Router,
  Link,
  //Route,
  Navigate,
  //withRouter
} from "react-router-dom";

import Honeybadger from '@honeybadger-io/js'
import ErrorBoundary from '@honeybadger-io/js'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axios from "axios";
import MuseaApi from "./modules/MuseaApi.js";
import Auth from "./modules/Auth.js";
//import logo from "./img/sign-in-page-musea-colorwheel@2x.png";
import logo1080 from "./img/MUSEAbadge-black-p-1080.jpeg";
import logo500 from "./img/MUSEAbadge-black-p-500.jpeg"
import logo800 from "./img/MUSEAbadge-black-p-800.jpeg"
import logoBlack from "./img/MUSEAbadge-black.jpg"

//import "./css/signInWebflow.css";
//import "./css/newWebflow.css";
//import "./css/signInInvision.css";

//var logoPath = `${process.env.PUBLIC_URL} + "./img/sign-in-page-musea-colorwheel@2x.png"`
//var signInPath = `${process.env.PUBLIC_URL} + "./css/signIn.css"`
//console.log("logoPath " + logoPath)
//import logo from {logoPath}
//import signInPath

class SignIn extends Component {
  constructor() {
    super();
    const hb_config = {
      api_key: process.env.REACT_APP_HB_KEY,
      environment: process.env.REACT_APP_CURRENT_ENV,
      revision: 'current'
    }
    this.honeybadger = Honeybadger.configure(hb_config)
    this.props = {};
    this.state = {
      email: "",
      password: "",
      honeybadger: this.honeybadger,
      redirectToReferrer: false,
      notifyUserOfLogout: this.props.location && this.props.location.state ? this.props.location.state.notifyUserOfLogout : '',
      auth: Auth.isAuthenticated()
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if(this.props !== nextProps) {
      this.setState({
        notifyUserOfLogout: nextProps.notifyUserOfLogout,
      })
    }
  }

  handleChange(e) {
    const name = e.target.name;
    const val = e.target.value;
    this.setState({
      [name]: val
    });
    //console.log(this.state);
  }

  checkStatus = () => {
    axios
      .get("api/v2/status")
      .then(function(response) {
        console.log(response);
      })
      .catch(err => console.log(err));
  };

  handleLoginSubmit = (e, data) => {
    e.preventDefault();
    axios
      .post("/api/v2/login", {
        email: data.email,
        password: data.password
      })
      .then(res => {
        console.log(res);
        var token = res.data.token;
        var intercom_user_hash = res.data.intercom_user_hash;
        var email = res.data.email;
        var name = res.data.name;
        var businessName = res.data.business_name;
        Auth.authenticateUser(token, intercom_user_hash, email, name, businessName);
        this.setState({
          redirectToReferrer: true,
          auth: Auth.isAuthenticated(),
          from: { pathname: "/" }
        });
        return <Navigate to={{ pathname: "/lab-orders", state: {} }} />;
      })
      .catch(err => this.throwError(err));
  };

  throwError(err) {
    let errorMessage, errorObject
    if (err.response && err.response.status === 401) {
      errorObject = err.response.data.error
      errorMessage = "There was a problem with your email or password. Please try again or click RESET below if you have forgotten your password." + "\nERROR CODE: " + errorObject.status + " ERROR CLASS: " + errorObject.class_name + " ERROR MESSAGE: " + errorObject.user_message["message"]
      this.setState({
        loading: false,
        query: "",
        errorState: true,
        errorMessage: errorMessage
      })
    } else {
      if (err.response != null) {
        errorObject = err.response.data.error
        if (errorObject != null) {
          errorMessage = "\nERROR CODE: " + errorObject.status + "\nERROR CLASS: " + errorObject.class_name + " \nERROR MESSAGE: " + errorObject.user_message["message"]
          if (err.response.data["error"] != null) {
            errorObject = err.response.data["error"]
            errorMessage = "\nERROR CODE: " + errorObject.status + "\nERROR CLASS: " + errorObject.class_name + " \nERROR MESSAGE: " + errorObject.user_message["message"]
            if (errorObject["more_info"] != null) {
              this.state.honeybadger.notify(err)
              alert("ERROR: " + errorMessage)
              console.log("ERROR: " + errorMessage + "\nMORE INFO: " + errorObject["more_info"].join(" ,").split(",").join("\n"))
            } else {
              this.state.honeybadger.notify(err)
              alert("ERROR: " + errorMessage)
              console.log(errorMessage + " More info not available.")
            }
          } else { 
            errorMessage = "\nERROR CODE: " + err.response.status + "\nERROR MESSAGE: " + err.response.message
          }
        } else {
          alert("ERROR MESSAGE: " + err.stack)
          errorMessage = err.stack
        }
      }
    }

    this.setState({
      loading: false,
      query: "",
      errorState: true,
      errorMessage: errorMessage
    })
  }

  componentDidMount() {
    let museaApi = new MuseaApi
    museaApi.checkAndResetCache()

    this.checkStatus();
    let notifyUserOfLogout = this.props.location && this.props.location.state ? this.props.location.state.notifyUserOfLogout : ''
    if (notifyUserOfLogout) {
      this.notify()
    }
  }

  notify() {
    toast('You have been logged out!', { position: "top-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true });
  }

  render() {
    let logoSrcSet = `${logo500} 500w, ${logo800} 800w, ${logo1080} 1080w, ${logoBlack} 1200w`
    let { from } = { from: { pathname: "/" } };
    let { redirectToReferrer, errorState, errorMessage } = this.state;
    if (redirectToReferrer) return <Navigate to={from} />;
    let errorMessageDiv
    if (errorState) {
      errorMessageDiv = <div style={{
        textAlign: 'center',
        marginBottom: '20px',
        display: 'inline-block',
        width: '100%'
      }}
      className="w-form-fail"
      > {errorMessage} </div>
    } else {
      errorMessageDiv = <div></div>
    }

    return (
      <div className="SignIn">
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
          <img alt="musea logo"
          srcSet={logoSrcSet}
          sizes="144px"
          className="image"
          src={logoBlack}
          style={{width: "144"}}
          />

        <div className="form-block w-form">
          <form
            id="wf-form-SIGN-IN-FORM"
            className="form"
            onSubmit={e => this.handleLoginSubmit(e, this.state)}
          >
            <input
              type="email"
              name="email"
              placeholder="E-mail"
              className="text-field-2 w-input"
              onChange={this.handleChange}
            />
            <input
              type="password"
              name="password"
              placeholder="Password"
              className="text-field w-input"
              onChange={this.handleChange}
            />

            <div className="button-layout-container">
              <div className="button">
                <div className="rectangle9" />

                <input type="submit" className="button w-button" style={{cursor: 'pointer'}} value="SIGN IN" />
              </div>
            </div>
          </form>

          {errorMessageDiv}

        </div>

        <div className="text-block">
          FORGOT PASSWORD?
          <Link to="forgot-password">
            <span style={{marginLeft: '0.5em', textDecoration: 'underline', cursor: 'pointer'}}>RESET</span>
          </Link>
        </div>

        <div className="text-block">
            DON’T HAVE AN ACCOUNT?
          <Link to="signup/1-of-3">
              <span style={{marginLeft: '0.5em', textDecoration: 'underline', cursor: 'pointer'}}>SIGN UP</span>
          </Link>
        </div>

      </div>
    );
  }
}

export default SignIn;
