import React, { Component } from "react";
import Auth from "../modules/Auth";
import axios from "axios";

import Honeybadger from '@honeybadger-io/js'
import ErrorBoundary from '@honeybadger-io/js'

class CreditCardForm extends Component {
  constructor(props) {
    super(props);
    const hb_config = {
      api_key: process.env.REACT_APP_HB_KEY,
      environment: process.env.REACT_APP_CURRENT_ENV,
      revision: 'current'
    }
    const honeybadger = Honeybadger.configure(hb_config)
    this.state = {
      newCardVisibility: this.props.newCardVisibility,
      cardMask: this.props.cardMask,
      saveCard: this.props.saveCard,
      honeybadger: honeybadger,
      onlyCard: this.props.onlyCard,
      stripeId: this.props.stripeId,
      creatingCard: false,
      localPickup: this.props.localPickup,
      selectedToken: this.props.selectedToken
    };
   this.setupStripeElements = this.setupStripeElements.bind(this);
   this.toggleUseExisting = this.toggleUseExisting.bind(this)
   this.toggleDropdownOpen = this.toggleDropdownOpen.bind(this);
   this.throwErr = this.throwErr.bind(this)
  }

  componentDidMount() {
    if (window.Stripe.elements !== null) {
      this.setupStripeElements(this.state.stripeId)
    } else {
      this.setupStripeElements(this.state.stripeId)
      document.querySelector('#stripe-js').addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        this.setupStripeElements(this.state.stripeId)
      });
    }
    console.log(this.state)
  }

  toggleUseExisting(e) {
    this.toggleDropdownOpen(e)
    if (this.state.newCardVisibility === 'visible') {
       this.setState({
         newCardVisibility: 'hidden'
       });
    } else {
       this.setState({
         newCardVisibility: 'visible'
       });
    }
  }

  componentWillReceiveProps(nextProps) {
    if(this.props !== nextProps) {
      this.setState({
        stripeId: nextProps.stripeId,
        onlyCard: nextProps.onlyCard
      });
    }
  }

  setupStripeElements(stripeId) {
    let stripeKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY
    let stripe = window.Stripe(stripeKey)

    let elementStyles = {
      base: {
        color: 'rgba(133, 133, 133, 1.0)',
        fontWeight: 300,
        textIndent: '5px',
        padding: '3px',
        fontFamily: '"ProximaNova-Regular", Helvetica, Arial, serif',
        fontSize: '16px',
        textAlign: 'center',
        height: '45px',
        lineHeight: '45px',

        ':focus': {
          color: '#424770',
        },

        '::placeholder': {
          color: 'rgba(133, 133, 133, 1.0)',
        },

        ':focus::placeholder': {
          color: 'rgba(133, 133, 133, 1.0)',
        },
      },
      invalid: {
        color: '#9e2146',
        ':focus': {
          color: '#9e2146',
        },
        '::placeholder': {
          color: '#9e2146',
        },
      },
    }

    let elementClasses = {
      focus: 'focus',
      empty: 'empty',
      invalid: 'invalid',
      hidePostalCode: true
    }

    let card = stripe.elements().create('card', {
      hidePostalCode: true,
      style: elementStyles,
      classes: elementClasses,
    });
    card.mount('#newCardForm-card');
    card.on('change', function(event) {
      var errorElement = document.querySelector('.error');
      errorElement.classList.remove('visible');
      if (event.token) {
        example.classList.add('submitted');

        var form = document.querySelector('form');
        form.querySelector('input[name="token"]').setAttribute('value', event.token.id);
        form.submit();
      } else if (event.error) {
        errorElement.textContent = event.error.message;
        errorElement.classList.add('visible');
      }

    });
    let exampleName = 'cardForm-4'
    let formClass = '.' + exampleName;
    let example = document.querySelector(formClass);

    document.querySelector('form').addEventListener('submit', function(e) {
      e.preventDefault();
      stripe.createToken(card).then(event => {
        //console.log("form result " + event.token)
        //console.log("in form setOutcome")
        //console.log("result " + event.token)

        var creatingElement = document.querySelector('.creating');
        creatingElement.classList.remove('no-display');
        creatingElement.classList.add('visible');

        var formElement = document.querySelector('.cardArea');
        formElement.classList.add('no-display');
        formElement.classList.remove('visible');

        var errorElement = document.querySelector('.error');
        errorElement.classList.remove('visible');

        if (event.token) {
          example.classList.add('submitted');
          let tokenId = event.token.id
          var token = Auth.getToken()
          var locationArray = window.location.href.split("/")
          var guid = locationArray[locationArray.length - 2]
          //console.log('lab order: ' + guid)
          //console.log('token: ' + token)
          axios
          .post(`api/v2/accounts/create_card`, {token_id: tokenId, lab_order_id: guid}, {headers: {'token': token}})
          .then(res => {

            var creatingElement = document.querySelector('.creating');
            creatingElement.classList.remove('visible');
            creatingElement.classList.add('no-display');

            console.log('showing success element')
            var successElement = document.querySelector('.success');
            successElement.classList.remove('no-display');

            setTimeout(function(){
              var successElement = document.querySelector('.success');
              successElement.classList.add('no-display');
            }, 3000);

            console.log('hiding success element')
            var formElement = document.querySelector('.cardArea');
            formElement.classList.add('visible');
            formElement.classList.remove('no-display');
            document.location.reload()

          })
          .catch(err => {
            var creatingElement = document.querySelector('.creating');
            creatingElement.classList.remove('visible');
            creatingElement.classList.add('no-display');

            var formElement = document.querySelector('.cardArea');
            formElement.classList.add('visible');
            formElement.classList.remove('no-display');
            if (err.response && err.response.data && err.response.data.errors) {
              //this.state.honeybadger.notify(err)
              alert("Could not save card. Server error: " + err.response.data.errors.toString())
            } else {
              //this.state.honeybadger.notify(err)
              alert("Could not save card. Server error: " + err)
            }
          })
        } else if (event.error) {
          console.log("ERROR IN STRIPE:")
          console.log("type: " + event.error.type)
          console.log("docs: " + event.error.doc_url)
          console.log("param: " + event.error.param)
          console.log("code: " + event.error.code)
          console.log("message: " + event.error.message)

          errorElement.textContent = event.error.message;

          var creatingElement = document.querySelector('.creating');
          creatingElement.classList.remove('visible');
          creatingElement.classList.add('no-display');

          var formElement = document.querySelector('.cardArea');
          formElement.classList.add('visible');
          formElement.classList.remove('no-display');

          errorElement.classList.add('visible');
        }
      });

    });

  }

  throwErr(err) {
    var creatingElement = document.querySelector('.creating');
    creatingElement.classList.remove('visible');
    creatingElement.classList.add('no-display');

    var formElement = document.querySelector('.cardArea');
    formElement.classList.add('visible');
    formElement.classList.remove('no-display');
    this.state.honeybadger.notify(err)
    alert("Could not save card. Server error: " + err)
  }

  toggleDropdownOpen(e) {

    e.preventDefault()
    if (e.target.classList.contains("w--open")) {
      e.target.classList.remove("w--open")
      e.target.parentElement.style.zIndex = '100'
      e.target.style.zIndex = '100'
      document.querySelector("nav.dropdown-list-8").classList.remove('w--open');
    } else {
      //move back shipping form
      if (this.state.localPickup) {
        document.querySelector("div.dropdown-7").style.zIndex = '100'
      } else {
        document.querySelector("nav.dropdown-list-3").parentElement.style.zIndex = '100'
        document.querySelector("nav.dropdown-list-3").style.zIndex = '100'
      }
      //bring forward cc form
      e.target.classList.add("w--open")
      e.target.parentElement.style.zIndex = '999'
      e.target.style.zIndex = '999'
      document.querySelector("nav.dropdown-list-8").classList.add('w--open');
    }
  }

  savedCcs = () => {
    if (this.props.savedCards && this.props.savedCards.length > 0) {
      return this.formatSavedCards(this.props.savedCards)
    } else {
      let cardOptions = []
      cardOptions.push(<a key="none-saved" value="" style={{textAlign: 'center'}} className="dropdown-link-12 w-dropdown-link">NO SAVED CARDS</a>)
      cardOptions.push(<a key="new" style={{textAlign: 'center'}} onClick={(e) => {this.toggleUseExisting(e)}} value="new" className="dropdown-link-12 w-dropdown-link">ADD NEW CARD</a>)
      return cardOptions
    }
  }

  formatSavedCards(cardData) {
    const cardOptions = cardData.map(obj => {
      return <a key={obj.attributes.id} href="#"
      onClick={(e) => {this.props.updateLabOrder(e, "selected_card_to_use", obj.attributes.id)}}
        value={obj.attributes.id}
        className="dropdown-link-15 w-dropdown-link">
        {obj.attributes.masked_card}
      </a>;
      //<option key={obj.attributes.id} value={obj.attributes.id}> {obj.attributes.masked_card} </option>;
    })

    if (this.state.newCardVisibility === 'visible') {
      cardOptions.push(<a key="new" onClick={(e) => {this.toggleUseExisting(e)}} value="new" className="dropdown-link-12 w-dropdown-link">HIDE CARD FORM</a>)
    } else {
      cardOptions.push(<a key="new" onClick={(e) => {this.toggleUseExisting(e)}} value="new" className="dropdown-link-12 w-dropdown-link">ADD NEW CARD</a>)
    }
    return cardOptions
  }

  render() {
    let  useExistingDiv, marginRightValue, paddingLeftValue, marginTopValue, selectedCardDiv
    const {newCardVisibility} = this.state


    if (this.props.savedCards && this.props.savedCards.length > 0) {
      useExistingDiv = <div data-delay="0" onClick={(e) => {this.toggleDropdownOpen(e)}} className="dropdown-8 w-dropdown">
                <div className="w-dropdown-toggle">
                  <div className="w-icon-dropdown-toggle">
                  </div>
                  <div>CREDIT / DEBIT CARD</div>
                </div>
                <nav className="w-dropdown-list dropdown-list-8" onChange={(e) => {this.props.updateLabOrder(e, "selected_card_to_use", e.target.value)}}>
                  {this.savedCcs()}
                </nav>
              </div>

      //onClick={(e) => {this.toggleUseExisting(e)}}
    } else {
      useExistingDiv = <div></div>
    }

    if (this.props.cardPage === "account") {
      marginRightValue = '0'
      paddingLeftValue = '160px'
      marginTopValue = '30px'
      selectedCardDiv = <div></div>
    } else {
      marginRightValue = 'auto'
      paddingLeftValue = '0px'
      marginTopValue = '40px'
      selectedCardDiv = <div>
        <div className="div-block-42" style={{marginTop: '0px !important', marginBottom: '20px'}}>
          <div className="text-block-1114" style={{}}>
            SELECTED CARD: {this.state.cardMask}
            <br />
            {this.state.saveCard}
          </div>
        </div>
      </div>
    }

    let hideOrShowCardFormDiv
    if (newCardVisibility === "hidden") {
      hideOrShowCardFormDiv = {display: 'none'}
    } else {
      hideOrShowCardFormDiv = {visibility: 'visible'}
    }


    return (
      <div>
        <div>
          <div className="cardArea hidden" style={{marginBottom: '0px'}}>
            {useExistingDiv}
            <div className="w-form cardForm-4" style={hideOrShowCardFormDiv}>
              <form style={{marginTop: marginTopValue, paddingLeft: paddingLeftValue}} className="form-4">

              <div style={{padding: '0px',
                marginLeft: marginRightValue,
                marginRight: marginRightValue,
                textAlign: 'center',
                paddingLeft: '8px', height: '45px'}} className="text-field-32 w-input is-empty" placeholer="CARD NUMBER" name="newCardForm-card" id="newCardForm-card" />

              <button type="submit" style={{ width: '100px',
                borderRadius: '8px',
                fontFamily: "'Mark simonson proxima nova', Arial, sans-serif",
                fontSize: '12px',
                height: '40px',
                marginLeft: marginRightValue,
                marginRight: marginRightValue,
                padding: '9px 15px',
                color: 'white',
                cursor: 'pointer'
                }}
              className="submit-button-6 w-button"
              id="save-card-element"
              data-tid="elements_examples.form.pay_button">
                SAVE
              </button>

              <div className="error text-field-32"
                style={{
                  color: '#E4584C',
                  fontFamily: '"ProximaNova-RegularIt", Helvetica, Arial, serif',
                  fontSize: '14.0px',
                  width: '300px',
                  marginTop: '1%',
                  textAlign: 'center',
                  lineHeight: '14.0px',
                }}
                role="alert">
                  <span className="message"
                  style={{
                    color: '#E4584C',
                    fontFamily: '"ProximaNova-RegularIt", Helvetica, Arial, serif',
                    marginTop: '0',
                    fontSize: '10.0px',
                    textAlign: 'justify',
                    lineHeight: '14.0px',
                  }}>
                  </span>
                </div>
              </form>

            </div>
          </div>

          <div className="success no-display" style={{textAlign: 'center', color: 'green'}}>
            <div className="icon">
              <svg width="84px" height="84px" viewBox="0 0 84 84" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <circle className="border" cx="42" cy="42" r="40" strokeLinecap="round" strokeWidth="4" stroke="#008000" fill="none"></circle>
                <path className="checkmark" strokeLinecap="round" strokeLinejoin="round" d="M23.375 42.5488281 36.8840688 56.0578969 64.891932 28.0500338" strokeWidth="4" stroke="#008000" fill="none"></path>
              </svg>
            </div>
            <h3 className="title" data-tid="elements_examples.success.title">Card Saved</h3>
          </div>


          <div>
            <div className="creating no-display" style={{textAlign: 'center', marginBottom: '20px', fontWeight: '700'}}>
              creating card...
            </div>
          </div>

        </div>

        {selectedCardDiv}
    </div>
    )
  }
}

export default CreditCardForm;
