import React, { Component } from "react"
import styled, { keyframes } from 'styled-components';

import { fadeIn, bounce } from 'react-animations'

import Honeybadger from '@honeybadger-io/js'
import ErrorBoundary from '@honeybadger-io/js'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//import { Link } from "react-router-dom";
import { Navigate, Link } from "react-router-dom"
import MuseaApi from "./modules/MuseaApi.js"
import Auth from "./modules/Auth.js"
import axios from "axios"

import NavBar from "./components/NavBar.js"
import AddToCartSidebar from "./components/AddToCartSidebar.js"
import SelectionArea from "./components/SelectionArea.js"
import LineItemSidebar from "./components/LineItemSidebar.js"
import ImageGutter from "./components/ImageGutter.js"

class OrderBuilder extends Component {
  constructor(props) {
    super(props);
    const hb_config = {
      api_key: process.env.REACT_APP_HB_KEY,
      environment: process.env.REACT_APP_CURRENT_ENV,
      revision: 'current'
    }

    const honeybadger = Honeybadger.configure(hb_config)

    this.updateSelections = this.updateSelections.bind(this)
    this.updatePricingSelection = this.updatePricingSelection.bind(this)
    this.throwError = this.throwError.bind(this)
    this.getLabOrderPhotographs = this.getLabOrderPhotographs.bind(this)
    this.getLabOrderPhotograph = this.getLabOrderPhotograph.bind(this)
    this.addItemsToCart = this.addItemsToCart.bind(this)
    this.updateLineItems = this.updateLineItems.bind(this)
    this.getFormattedLineItems = this.getFormattedLineItems.bind(this)
    this.setCropOptionsForLineItem = this.setCropOptionsForLineItem.bind(this)
    this.selectNotesForPhoto = this.selectNotesForPhoto.bind(this)
    this.updateCropOrientation = this.updateCropOrientation.bind(this)
    this.updateLabOrder = this.updateLabOrder.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.toggleOpenDiv = this.toggleOpenDiv.bind(this)
    var guid = this.props.location.pathname["split"]("/")[2];
    if (this.props.location.state) {
      props = this.props.location.state
    } else {
      props = this.props
    }

    this.state = {
      loading: true,
      loadingImages: true,
      errorState: false,
      addingToCart: false,
      openDiv: null,
      guid: guid,
      honeybadger: honeybadger,
      lineItemGuid: props.lineItemGuid,
      location: this.props.location,
      lineItem: props.lineItem,
      cropOptions: props.cropOptions,
      selectedPhotoForNotes: props.lineItemGuid && props.selections && props.selections.length > 0 ? props.selections[0] : "",
      qty: props.quantity && props.quantity.length > 0 ? props.quantity : 1,
      selections: props.selections && props.selections.length > 0 ? props.selections : [],
      paperPricing: props.paperPricing && props.paperPricing.length > 0 ? props.paperPricing : "",
      paperPricingName: props.paperPricingName && props.paperPricingName.length > 0 ? props.paperPricingName : "",
      printSizePricing: props.printSizePricing && props.printSizePricing.length > 0 ? props.printSizePricing : "",
      printSizePricingName: props.printSizePricingName && props.printSizePricingName.length > 0 ? props.printSizePricingName : "",
      finishingCategory: props.finishingCategory && props.finishingCategory.length > 0 ? props.finishingCategory : "",
      finishingCategoryName: props.finishingCategoryName && props.finishingCategoryName.length > 0 ? props.finishingCategoryName : "",
      finishingPricing: props.finishingPricing && props.finishingPricing.length > 0 ? props.finishingPricing : "",
      finishingDetailPricing: props.finishingDetailPricing && props.finishingDetailPricing.length > 0 ? props.finishingDetailPricing : "",
    };
  }

  componentDidMount() {
    let museaApi = new MuseaApi
    museaApi.checkAndResetCache()
    //console.log("getting lab order in did mount")
    this.getLabOrder()
  }


  componentWillReceiveProps(nextProps) {
    // console.log("getting lab order in will receive props ln 91")
    this.getLabOrder()
  }
  //if(this.props !== nextProps) {
  //this.setState({
  //qty: nextProps.quantity && nextProps.quantity.length > 0 ? nextProps.quantity : 1,
  //selections: nextProps.selections && nextProps.selections.length > 0 ? nextProps.selections : [],
  //printSizePricing: nextProps.printSizePricing && nextProps.printSizePricing.length > 0 ? nextProps.printSizePricing :  "",
  //finishingServicePricing: nextProps.finishingServicePricing && nextProps.finishingServicePricing.length > 0 ? nextProps.finishingServicePricing :  "",
  //finishingServiceDetailPricing: nextProps.finishingServiceDetailPricing && nextProps.finishingServiceDetailPricing.length > 0 ? nextProps.finishingServiceDetailPricing :  "",
  //})
  //}
  //}

  async getLabOrder(e, data) {
    //e.preventDefault();
    // console.log("getLabOrder ln 107")
    var token = Auth.getToken()
    var guid = this.props.location.pathname["split"]("/")[2];
    axios
      .get(`api/v2/lab_orders/${guid}/retrieve_full_order`, { headers: { 'token': token } })
      .then(res => {
        //console.log("res " + res.data['data'].attributes);
        let museaApi = new MuseaApi()
        var parsedOrder = museaApi.mergeAttributesForLabOrder(res.data);
        // console.log("in getLabOrder line items are: " + parsedOrder.lineItems.length)
        this.setState({
          redirectToReferrer: true,
          auth: Auth.isAuthenticated(),
          from: { pathname: "/" },
          labOrder: parsedOrder,
          lineItems: parsedOrder.lineItems,
          lineItemCount: parsedOrder.lineItems.length,
          cropOptions: {
            //unit: '%',
            unit: 'px',
            disabled: false,
            locked: false,
            keepSelection: true,
            height: 100,
            width: 100,
            x: 0,
            y: 0,
          },
          loading: false,
          loadingImages: true,
          errorState: false,
          addingToCart: false
        });
        return true
      }).then(res => {
        if (this.state.lineItemGuid && this.state.lineItemGuid.length > 1) {
          //console.log("getting one image: " + this.state.selections[0])
          this.getLabOrderPhotographAsImages(this.state.selections[0])
        } else {
          //console.log("getting all images")
          this.getLabOrderPhotographs()
        }
      })
      .catch(err => {
        this.throwError(err)
        //let error = JSON.parse(err.response)
        //this.throwError(error)
      })
  };

  getLabOrderPhotographs() {
    //e.preventDefault();
    // var newLineItemCount = this.state.lineItemCount + jsonLineItems.length
    // this.setState({lineItemCount: newLineItemCount})
    // console.log("getting all images getLabOrderPhotographs ln 160")
    // console.log("if we got line item count from here on ln 161: " + this.state.labOrder.lineItems.length)
    var token = Auth.getToken()
    var guid = this.props.location.pathname["split"]("/")[2];
    axios
      .get(`api/v2/lab_orders/${guid}/lab_order_photographs`, { headers: { 'token': token } })
      // /api/v2/lab_orders/:id/lab_order_photographs
      .then(res => {
        //console.log("res " + res.data['data'].attributes);
        let museaApi = new MuseaApi()
        let labOrder = this.state.labOrder
        // console.log("if we got a line item count from after the call to the API: " + labOrder.lineItems.length)
        labOrder['images'] = museaApi.mergeImages(labOrder, res.data)
        //console.log("the images: " + labOrder['images'])
        this.setState({
          redirectToReferrer: true,
          auth: Auth.isAuthenticated(),
          from: { pathname: "/" },
          labOrder: labOrder,
          lineItems: labOrder.lineItems,
          labOrderReloaded: true,
          loadingImages: false,
          errorState: false,
          addingToCart: false
        });
        return true;
      })
      .catch(err => {
        this.throwError(err)
        //let error = JSON.parse(err.response)
        //this.throwError(error)
      })
  };

  getLabOrderPhotograph(photoGuid) {
    //e.preventDefault();
    var token = Auth.getToken()
    var guid = this.props.location.pathname["split"]("/")[2];
    axios
      .get(`api/v2/lab_orders/${guid}/photographs/${photoGuid}`, { headers: { 'token': token } })
      // /api/v2/lab_orders/:id/lab_order_photographs
      .then(res => {
        //console.log("res: " + res.data.data);
        return res.data.data
      })
      .catch(err => {
        this.throwError(err)
      })
  };

  getLabOrderPhotographAsImages(photoGuid) {
    //e.preventDefault();
    var token = Auth.getToken()
    var guid = this.props.location.pathname["split"]("/")[2];
    axios
      .get(`api/v2/lab_orders/${guid}/photographs/${photoGuid}`, { headers: { 'token': token } })
      // /api/v2/lab_orders/:id/lab_order_photographs
      .then(res => {
        //console.log("res: " + res.data.data);
        let museaApi = new MuseaApi()
        let labOrder = this.state.labOrder
        labOrder['images'] = museaApi.mergeImages(labOrder, res.data)
        // console.log("getLabOrderPhotographAsImages line item: " + labOrder.lineItems.length)
        this.setState({
          labOrder: labOrder,
          lineItemCount: labOrder.lineItems.length,
        })
        return true
      })
      .catch(err => {
        this.throwError(err)
      })
  };

  throwError(err) {
    let errorMessage, errorObject
    if (err.response.data["error"] && err.response.data["error"].status === 401) {
      this.setState({
        userLoggedOut: true
      })
      return false
    } else {
      if (err.response.data["error"] != null) {
        errorObject = err.response.data["error"]
        errorMessage = "\nERROR CODE: " + errorObject.status + "\nERROR CLASS: " + errorObject.class_name + " \nERROR MESSAGE: " + errorObject.user_message["message"]
        if (errorObject["more_info"] != null) {
          this.state.honeybadger.notify(err)
          alert("ERROR: " + errorMessage)
          console.log("ERROR: " + errorMessage + "\nMORE INFO: " + errorObject["more_info"].join(" ,").split(",").join("\n"))
        } else {
          this.state.honeybadger.notify(err)
          alert("ERROR: " + errorMessage)
          console.log(errorMessage + " More info not available.")
        }
      } else { 
        errorMessage = "\nERROR CODE: " + err.response.status + "\n ERROR MESSAGE: " + err.response.message
      }
    }
    this.setState({
      loading: false,
      selections: [],
      errorState: true,
      addingToCart: false,
      errorMessage: errorMessage
    })
  }


  addItemsToCart(lineItemGuid) {
    this.setAddToCartState(true)
    let potentialLineItems = this.getFormattedLineItems()
    let jsonLineItems = []
    Object.keys(potentialLineItems).forEach(key => {
      let jsonStringLineItem = JSON.stringify(potentialLineItems[key])
      let jsonLineItem = JSON.parse(jsonStringLineItem)
      jsonLineItems.push(jsonLineItem)
    })

    var token = Auth.getToken()
    var guid = this.props.location.pathname["split"]("/")[2];

    if (lineItemGuid && lineItemGuid.length > 0) {
      // console.log("lineItemGuid && lineItemGuid.length > 0")

      axios.patch(`api/v2/lab_orders/${guid}/line_items/${lineItemGuid}`,
        {
          line_items: jsonLineItems
        },
        { headers: { 'token': token } }
      )
        .then(res => {
          // console.log("getting updated order after post")
          let museaApi = new MuseaApi()
          var parsedOrder = museaApi.mergeAttributesForLabOrder(res.data);
          this.setState({
            redirectToReferrer: true,
            auth: Auth.isAuthenticated(),
            from: { pathname: "/" },
            labOrder: parsedOrder,
            lineItemCount: parsedOrder.lineItems.length,
            addingToCart: false,
            redirectToViewCart: true,
            selections: [],
            cropOptions: {
              //unit: '%',
              unit: 'px',
              disabled: false,
              locked: false,
              keepSelection: true,
              height: 100,
              x: 0,
              y: 0,
            },
            loading: false,
            errorState: false
          });
          // console.log("updated order after post: " + this.state.labOrder)
          // console.log("updated line item count after post: " + this.state.labOrder.lineItems.length)
          return true;
        })
        .catch(err => {
          this.throwError(err)
          //let error = JSON.parse(err.response)
          //this.throwError(error)
        })
    } else {
      // console.log("jsonLineItems", jsonLineItems)
      for (const originalItem of this.state.lineItems) {
        for (const potentialLineItem of jsonLineItems) {
          if (
            potentialLineItem.source_photograph_guid === originalItem.photoGuid &&
            potentialLineItem.print_size === originalItem.printSizeGuid &&
            (potentialLineItem.finishing_service === originalItem.finishingServiceGuid || (potentialLineItem.finishing_service === "" && originalItem.finishingServiceGuid === undefined))
          ) {
            // console.log("Original Item", originalItem)
            // console.log("Potential Item", potentialLineItem)
            potentialLineItem.quantity = originalItem.quantity + 1
            try {
              axios
                .patch(`api/v2/line_items/${originalItem.guid}/update_quantity`, {
                  line_items: [{ quantity: potentialLineItem.quantity }]
                },
                  { headers: { 'token': token } }
                )
              jsonLineItems.splice(jsonLineItems.indexOf(potentialLineItem), 1)
            }
            catch (err) {
              // console.log(err)
              this.throwError(err)
              //let error = JSON.parse(err.response)
              //this.throwError(error)
            }
          } 
        }
        // await jsonLineItems.filter(async (potentialLineItem, b) => {
        // })
      }

      if (jsonLineItems.length > 0) {
        // console.log("jsonLineItems.length > 0", jsonLineItems)
        var lineItemGroupCount = Math.ceil(jsonLineItems.length / 30)
        var lineItemGroups = Array.from(Array(lineItemGroupCount).keys())
        var lineItemIndex = 0
        var index = 0
        let lineItemResponse

        lineItemGroups.forEach((lineItems) => {
          // console.log("lineItemIndex before slice: " + lineItemIndex)
          var lineItemsToSend = jsonLineItems.slice(lineItemIndex, lineItemIndex += 30);
          var index =+ 1;
          // console.log("lineItemIndex after slice: " + lineItemIndex)
          // console.log("index: " + index)
          axios.post(`api/v2/lab_orders/${guid}/line_items`,
            {
              line_items: lineItemsToSend
            },
            { headers: { 'token': token } }
          )
          .then((res) => {
            let museaApi = new MuseaApi()
            var parsedOrder = museaApi.mergeAttributesForLabOrder(res.data);
            console.log("line 389 parsedOrder: " + parsedOrder.lineItems.length)
            this.setState({
              redirectToReferrer: true,
              auth: Auth.isAuthenticated(),
              from: { pathname: "/" },
              labOrder: parsedOrder,
              addingToCart: false,
              lineItemCount: parsedOrder.lineItems.length,
              selections: [],
              cropOptions: {
                //unit: '%',
                unit: 'px',
                disabled: false,
                locked: false,
                keepSelection: true,
                height: 100,
                x: 0,
                y: 0,
              },
              loading: false,
              loadingImages: true,
              errorState: false,
              lineItems: parsedOrder.lineItems
            });
            return true
          }).then(res => {
            //console.log("getting all images")
            this.getLabOrderPhotographs()
          })
          .catch(err => {
            this.throwError(err)
            //let error = JSON.parse(err.response)
            //this.throwError(error)
          })
        })
      } else {
        console.log("in else statement in addToCart line 424")
        this.getLabOrder()
      }
    }
  }

  setCropOptions() {
    // console.log("Set crop options");
    if (this.state.cropOptions && this.state.cropOptions !== null) {
      return this.state.cropOptions
    } else {
      let cropOptions = {
        //unit: '%',
        unit: 'px',
        disabled: false,
        locked: false,
        keepSelection: false,
        height: 100,
        width: 100,
        x: 0,
        y: 0,
      }
      this.setState({ cropOptions: cropOptions })
    }
  }

  updateCropOrientation(paperTypeGuid, printSizeGuid, products, action) {
    let selectedPaper = products.filter(product => (product.attributes.guid === paperTypeGuid));
    let selectedPrintSize = selectedPaper[0].attributes.print_size_pricings.filter(psp => (psp.data.id === printSizeGuid));
    let x_aspect = selectedPrintSize[0].data.attributes.x_aspect
    let y_aspect = selectedPrintSize[0].data.attributes.y_aspect
    // console.log("\n UPDATE CROP ORIENTATION: the x aspect for selected " + x_aspect + " the y aspect for print " + y_aspect + "\n")
    let cropOptions, foundLineItem
    if (action === "setupLineItem") {
      if (this.state.labOrder && this.state.labOrder !== null && this.state.lineItemGuid && this.state.lineItemGuid.length > 0) {
        foundLineItem = this.state.labOrder.lineItems.filter(li => (li.guid === this.state.lineItemGuid))
        if (foundLineItem.length > 0) {
          cropOptions = {
            selectedPaper: selectedPaper[0].attributes.name,
            unit: 'px',
            disabled: false,
            locked: false,
            keepSelection: true,
            aspect: x_aspect / y_aspect,
            x_aspect: x_aspect,
            y_aspect: y_aspect,
            height: foundLineItem[0].cropAttributes.height,
            width: foundLineItem[0].cropAttributes.width,
            x: foundLineItem[0].cropAttributes.x,
            y: foundLineItem[0].cropAttributes.y,
          }
        }
      } else {
        cropOptions = {
          //unit: '%',
          unit: 'px',
          aspect: x_aspect / y_aspect,
          x_aspect: x_aspect,
          y_aspect: y_aspect,
          locked: false,
          keepSelection: true,
          x: 0,
          y: 0,
          height: 100,
          width: 100,
          border: 'none',
          selectedPaper: selectedPaper[0].attributes.name
        }
      }
    } else {
      cropOptions = {
        selectedPaper: selectedPaper[0].attributes.name,
        //unit: '%',
        unit: 'px',
        aspect: x_aspect / y_aspect,
        x_aspect: x_aspect,
        y_aspect: y_aspect,
        locked: false,
        keepSelection: true,
        x: 0,
        y: 0,
        height: 100,
        width: 100,
        border: 'none',
      }
    }
    let labOrder = this.state.labOrder
    let updatedLabOrderImages = this.updateCropOptionsForImages(labOrder.images, cropOptions)
    labOrder["images"] = updatedLabOrderImages
    this.setState({
      cropOptions: cropOptions,
      labOrder: labOrder
    })
  }

  updateSelections(action, selectionId, currentSelections) {
    const labOrder = this.state.labOrder
    let selectedPhotoForNotes
    let newSelections
    if (currentSelections === null) {
      let currentSelections = []
      this.setState({
        selections: currentSelections,
        selectedPhotoForNotes: "ALL",
        photoFileNameForNotes: "ALL PHOTOS"
      })
    }
    if (action === "addAll") {
      // console.log(this.state.labOrder.image_ids);
      // currentSelections = labOrder.image_ids
      currentSelections = [];
      this.state.labOrder.images.forEach(function (img) {
        currentSelections.push(img.guid);
      });


      this.setState({
        selections: currentSelections,
        selectedPhotoForNotes: "ALL",
        photoFileNameForNotes: "ALL PHOTOS"
      })
    }
    else if (action === "clearSelections") {
      newSelections = []
      this.setState({
        selections: newSelections,
        printSizePricing: "",
        selectedPhotoForNotes: "",
        photoFileNameForNotes: "",
        printBorder: "",
        finishingCategoryName: "",
        finishingCategory: "",
        finishingPricing: "",

      })
    }
    else if (action === "removeImage") {
      //console.log("selectionId to remove is " + selectionId)
      //console.log("currentSelections is " + currentSelections)
      newSelections = currentSelections.filter(imageId => (imageId !== selectionId));
      //console.log("newSelections is " + newSelections)
      this.setState({
        selections: newSelections,
        selectedPhotoForNotes: "ALL",
        photoFileNameForNotes: "ALL PHOTOS"
      })
    }
    else {
      if (currentSelections.includes(selectionId)) {
        newSelections = currentSelections.filter(item => (item !== selectionId));
        this.setState({
          selections: newSelections,
          selectedPhotoForNotes: "ALL",
          photoFileNameForNotes: "ALL PHOTOS"
        })
      } else {
        currentSelections.push(selectionId)
        if (currentSelections.length === 1) {
          selectedPhotoForNotes = selectionId
        }
        this.setState({
          selections: currentSelections,
          selectedPhotoForNotes: "ALL",
          photoFileNameForNotes: "ALL PHOTOS"
        })
      }
    }
  }

  updateCropOptionsForImages(images, passedCropOptions) {
    let baseCropOptions = passedCropOptions
    if (baseCropOptions && baseCropOptions !== null) {
      // console.log("not updating crop")
    } else {
      baseCropOptions = {
        disabled: false,
        locked: false,
        keepSelection: false,
        height: 100,
        x: 0,
        y: 0,
      }
    }

    let updatedImages = images.map(image => {
      let cropOptions = {}
      Object.assign(cropOptions, baseCropOptions);
      cropOptions.imageGuid = image.guid
      cropOptions.imageRef = image.guid
      cropOptions.ref = image.guid
      image.cropOptions = cropOptions
      return image
    })
    return updatedImages
  }

  selectNotesForPhoto(imageGuid, photoFileName) {
    // console.log("setting state to selectedPhotoForNotes: " + imageGuid)
    let notesField = document.getElementsByName('lineItemNotes')[0]
    notesField.focus();
    notesField.select();

    this.setState({
      selectedPhotoForNotes: imageGuid,
      newlyActiveNotesField: true,
      photoFileNameForNotes: photoFileName
    })

  }

  setAddToCartState(val) {
    this.setState({
      addingToCart: val
    })
  }

  updatePricingSelection(val, name) {
    // console.log("updatePricingSelection");
    this.setState({
      [name]: val
    })
  }

  updateLineItems() {
    // console.log("updateLineItems");
    let lineItems = this.getFormattedLineItems()
    this.setState({
      potentialLineItems: lineItems
    })
  }

  getFormattedLineItems() {
    // console.log("getFormattedLineItems");
    let lineItems = {}
    const selections = this.state.selections
    const labOrder = this.state.labOrder
    let printBorder
    let notes
    if (this.state.printBorder && this.state.printBorder !== null && this.state.printBorder.length > 0) {
      printBorder = this.state.printBorder
    } else {
      printBorder = "none"
    }

    selections.forEach(obj => {
      if (this.state.notesForPhotos && this.state.notesForPhotos !== null && this.state.notesForPhotos[obj] !== undefined) {
        notes = this.state.notesForPhotos[obj]
      }
      else if (this.state.notesForPhotos && this.state.notesForPhotos["ALL"] !== null && this.state.notesForPhotos["ALL"].length > 0) {
        notes = this.state.notesForPhotos["ALL"]
      } else {
        notes = ""
      }
      let photo = labOrder.images.filter(item => (item.guid === obj));
      lineItems[obj] = {}

      // 
      lineItems[obj].imageName = photo[0]["photo_file_name"]
      // console.log(photo)
      // 
      //lineItems[obj].guid = obj
      lineItems[obj].quantity = this.state.qty
      lineItems[obj].print_size = this.state.printSizePricing
      lineItems[obj].finishing_service = this.state.finishingPricing
      lineItems[obj].finishing_service_detail = this.state.finishingDetailPricing
      lineItems[obj].print_border = printBorder
      lineItems[obj].notes = notes
      lineItems[obj].source_photograph_guid = obj
      if (photo.length > 0) {
        lineItems[obj].photograph = {}
        lineItems[obj].photograph.source_photograph_guid = obj
        if (photo[0].cropOptions !== null && photo[0].cropOptions.x !== null) {
          lineItems[obj].photograph.crop_x = photo[0].cropOptions.x
          lineItems[obj].photograph.crop_y = photo[0].cropOptions.y
          lineItems[obj].photograph.crop_h = photo[0].cropOptions.height
          lineItems[obj].photograph.crop_w = photo[0].cropOptions.width
          lineItems[obj].photograph.photo_meta = photo[0].cropOptions
        } else {
          lineItems[obj].photograph.crop_x = 0
          lineItems[obj].photograph.crop_y = 0
          lineItems[obj].photograph.crop_h = 100
          lineItems[obj].photograph.crop_w = 100
          lineItems[obj].photograph.photo_meta = {}
        }
      }
      //console.log("photo meta " + photo[0].cropOptions)
      //lineItems[obj] = JSON.stringify(lineItems[obj])
    })

    return lineItems
  }

  updateLabOrder(imageGuid, crop) {
    // console.log("updateLabOrder");
    let labOrder = this.state.labOrder
    let imageToUpdate = labOrder.images.filter(item => (item.guid === imageGuid));
    if (imageToUpdate.length > 0) {
      imageToUpdate[0].cropOptions = crop
      let updatedImages = labOrder.images.map(image => {
        if (image.guid !== crop.imageGuid) {
          //console.log("image guid " + image.guid + " aspect: " + image.cropOptions.aspect + "\n")
        } else {
          image.cropOptions = crop
          //console.log("updated aspect for image (ln402) " + imageGuid + " aspect: " + crop.aspect + "\n")
        }
        return image
      })
      //console.log("updating " + imageGuid + " crop: " + crop.x + "\n")
      labOrder.images = updatedImages
      this.setState({
        labOrder: labOrder
      })
    }
  }

  handleClickOutside(target) {
    if (target === this.state.openDiv) {
      alert('You clicked inside!');
    } else {
      alert('You clicked outside!');
    }
  }

  toggleOpenDiv(target, action) {
    if (action === "open") {
      this.setState({
        openDiv: target
      })
      alert('You clicked outside!');
    } else {
      this.setState({
        openDiv: null
      })
      alert('You clicked inside!');
    }
  }

  setCropOptionsForLineItem() {
    // console.log("setCropOptionsForLineItem");
    let cropOptionsForLineItem, foundLineItem
    if (this.state.labOrder && this.state.labOrder !== null && this.state.lineItemGuid && this.state.lineItemGuid.length > 0) {
      foundLineItem = this.state.labOrder.lineItems.filter(li => (li.guid === this.state.lineItemGuid))
      if (foundLineItem.length > 0) {
        let lineItem = foundLineItem[0]

        cropOptionsForLineItem = {
          unit: 'px',
          disabled: false,
          locked: false,
          keepSelection: true,
          aspect: lineItem.cropattributes.aspect,
          height: parseFloat(+lineItem.cropAttributes.height).toFixed(2),
          width: parseFloat(+lineItem.cropAttributes.width).toFixed(2),
          x: parseFloat(+lineItem.cropAttributes.x).toFixed(2),
          y: parseFloat(+lineItem.cropAttributes.y).toFixed(2),
        }
      }
    } else {
      cropOptionsForLineItem = this.state.cropOptions
    }

    return cropOptionsForLineItem
  }

  notify() {
    toast('You have no line items!', { position: "top-right", autoClose: 10000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true });
  }

  render() {
    const { location, labOrder, guid, lineItemGuid, lineItem, lineItemCount, selections, qty, paperPricing, paperPricingName, printSizePricing, printSizePricingName, printBorder, finishingCategory, finishingCategoryName, finishingPricing, finishingOptionOne, finishingOptionOneName, finishingDetailPricing, selectedPhotoForNotes, newlyActiveNotesField, photoFileNameForNotes } = this.state;

    // console.log("labOrder: " + labOrder)
    if (labOrder) {
      console.log("lineItemCount: " + labOrder.lineItems.length)
      console.log("lineItemCount state: " + this.state.lineItemCount)
    }
    if (this.state.redirectToViewCart) {
      return <Navigate to={{
        pathname: `/lab-orders/${this.state.guid}/review`, state: {
          orderGuid: `${this.state.guid}`,
          labOrder: { labOrder }
        }
      }} push />
    }

    let imageGutterSection, addToCartSidebarSection, selectionAreaSection
    if (labOrder && labOrder !== null && lineItemGuid && lineItemGuid.length > 0 && labOrder.images.length > 0) {
      let cropOptionsForLineItem
      cropOptionsForLineItem = this.setCropOptionsForLineItem
      imageGutterSection = <div></div>

      addToCartSidebarSection = <AddToCartSidebar guid={guid} checkoutButton={true} newlyActive={newlyActiveNotesField} photoFileName={photoFileNameForNotes} lineItemGuid={lineItemGuid} addItemsToCart={this.addItemsToCart} updateSelections={this.updateSelections} labOrder={labOrder} subtotal={labOrder.subtotal} selections={selections} updatePricingSelection={this.updatePricingSelection} printSizePricing={printSizePricing} printBorder={printBorder} finishingPricing={finishingPricing} finishingDetailPricing={finishingDetailPricing} finishingCategory={finishingCategory} selectedPhotoForNotes={selectedPhotoForNotes} updateLineItems={this.updateLineItems} />
      selectionAreaSection = <SelectionArea
        updateSelections={this.updateSelections}
        updateLabOrder={this.updateLabOrder}
        selections={selections}
        labOrder={labOrder}
        lineItemCount={this.state.lineItemCount}
        updateLineItems={this.updateLineItems}
        selectNotesForPhoto={this.selectNotesForPhoto}
        printSizePricing={printSizePricing}
        finishingPricing={finishingPricing}
        finishingDetailPricing={finishingDetailPricing}
        cropOptions={cropOptionsForLineItem} />

    } else if (labOrder && labOrder !== null) {
      imageGutterSection = <ImageGutter loadingImages={this.state.loadingImages} updateSelections={this.updateSelections} selections={selections} labOrder={labOrder} inCart={labOrder.images_in_cart} />
      selectionAreaSection = <SelectionArea
        updateSelections={this.updateSelections}
        updateLabOrder={this.updateLabOrder}
        selections={selections}
        labOrder={labOrder}
        updateLineItems={this.updateLineItems}
        selectNotesForPhoto={this.selectNotesForPhoto}
        printSizePricing={printSizePricing}
        finishingPricing={finishingPricing}
        finishingDetailPricing={finishingDetailPricing}
        cropOptions={this.state.cropOptions} />

      if (selections && selections.length > 0) {
        addToCartSidebarSection = <AddToCartSidebar guid={guid} checkoutButton={true} newlyActive={newlyActiveNotesField} photoFileName={photoFileNameForNotes} lineItemGuid={lineItemGuid} lineItemCount={this.state.lineItemCount} addItemsToCart={this.addItemsToCart} updateSelections={this.updateSelections} labOrder={labOrder} subtotal={labOrder.subtotal} selections={selections} updatePricingSelection={this.updatePricingSelection} printSizePricing={printSizePricing} printBorder={printBorder} finishingPricing={finishingPricing} finishingDetailPricing={finishingDetailPricing} finishingCategory={finishingCategory} selectedPhotoForNotes={selectedPhotoForNotes} updateLineItems={this.updateLineItems} />
      } else {
        addToCartSidebarSection = <AddToCartSidebar guid={guid} checkoutButton={true} hidePhotoNotesArea={true} hideAddToCartButton={true} newlyActive={newlyActiveNotesField} photoFileName={photoFileNameForNotes} lineItemGuid={lineItemGuid} lineItemCount={this.state.lineItemCount} addItemsToCart={this.addItemsToCart} updateSelections={this.updateSelections} labOrder={labOrder} subtotal={labOrder.subtotal} selections={selections} updatePricingSelection={this.updatePricingSelection} printSizePricing={printSizePricing} printBorder={printBorder} finishingPricing={finishingPricing} finishingDetailPricing={finishingDetailPricing} finishingCategory={finishingCategory} selectedPhotoForNotes={selectedPhotoForNotes} updateLineItems={this.updateLineItems} />
      }

    } else {
      imageGutterSection = <div></div>
      addToCartSidebarSection = <div></div>
      selectionAreaSection = <div></div>
    }

    if (location.state && location.state.cartCleared && !this.state.labOrderReloaded) {
      this.getLabOrder()
    }

    //if (location.state && location.state.notifyUserOfMissingLineItems) {
    //this.notify()
    //location.state = {}
    //this.setState({
    //location: location
    //})
    //}

    if (this.state.loading) {
      return (
        <div>
          <NavBar />
          <div className="columns-6 w-row OrderBuilder">
            <div className="w-col w-col-2"></div>
            <div className="w-col w-col-8">
              <div style={{ marginBottom: '50px', marginTop: '10%', fontWeight: '700' }} className="div-block-60">
                loading...
              </div>
            </div>

            <div className="w-col w-col-2"></div>
          </div>
        </div>
      );
    } else if (this.state.errorState) {
      return (
        <div>
          <NavBar />
          <div className="columns-6 w-row OrderBuilder">
            <div className="w-col w-col-2"></div>
            <div className="w-col w-col-8">
              <div style={{ marginBottom: '50px' }} className="div-block-60">

                ERROR LOADING: {this.state.errorMessage}
                <div style={{ marginLeft: '20px' }}>
                  <Link to={{
                    pathname: `/lab-orders/${this.state.guid}/edit/order-builder`,
                    state: {
                      orderGuid: `${this.state.guid}`
                    }
                  }}>
                    RELOAD ORDER PAGE
                  </Link>
                </div>

              </div>
            </div>

            <div className="w-col w-col-2"></div>
          </div>
        </div>
      );
    } else if (this.state.addingToCart) {
      return (
        <div>
          <NavBar />
          <div className="columns-6 w-row OrderBuilder">
            <div className="w-col w-col-2"></div>
            <div className="w-col w-col-8">
              <div style={{ marginBottom: '50px', marginTop: '10%', lineBreak: 'after' }} className="div-block-60">
                adding to cart...
              </div>
            </div>

            <div className="w-col w-col-2"></div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <NavBar />
          <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
          <div>
            <div className="columns-6 w-row OrderBuilder" style={{ marginBottom: '150px' }}>

              <div className="lineItemSidebar">
                <LineItemSidebar guid={guid} throwError={this.throwError} toggleOpenDiv={this.toggleOpenDiv} updateCropOrientation={this.updateCropOrientation} qty={qty} updateSelections={this.updateSelections} setCropOptionsForLineItem={this.setCropOptionsForLineItem} updatePricingSelection={this.updatePricingSelection} lineItemGuid={lineItemGuid} lineItem={lineItem} paperPricing={paperPricing} paperPricingName={paperPricingName} printSizePricing={printSizePricing} printSizePricingName={printSizePricingName} finishingCategory={finishingCategory} finishingCategoryName={finishingCategoryName} finishingOptionOne={finishingOptionOne} finishingOptionOneName={finishingOptionOneName} printBorder={printBorder} finishingPricing={finishingPricing} finishingDetailPricing={finishingDetailPricing} updateLineItems={this.updateLineItems} labOrder={labOrder} selections={selections} />
              </div>

              <div className="selectionArea">
                {selectionAreaSection}
              </div>

              <div className="cartSidebar">
                {addToCartSidebarSection}
              </div>

            </div>
          </div>


          <div className="imageGutter" style={{ marginBottom: '200px' }}>
            {imageGutterSection}
          </div>
        </div>
      );
    }
  }
}

export default OrderBuilder;
