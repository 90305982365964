import React, { Component } from "react";
import Auth from "../modules/Auth";
import { Navigate } from "react-router-dom";
import axios from "axios";
import validatedCheck from "../img/sign-up-1-v2-baselinecheckcircleblack48dp-copy-green@2x.png";

class LoginInfoForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      errorState: false,
      emailError: false,
      updatingLogin: false,
      passwordError: false,
      passwordsMatch: false
    };
    this.validateEmail = this.validateEmail.bind(this);
    this.emailValid = this.emailValid.bind(this);
    this.throwError = this.throwError.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  };

  componentDidMount() {

  }

  handleChange(e) {
    const name = e.target.name;
    const val = e.target.value;
    if (name === "passwordConfirmation") {
      this.validatePasswords(val)
    } else if (name === "email" && this.emailValid(val)) {
      this.validateEmail(val)
    } else {
      this.setState({
        [name]: val
      });
    }
    //console.log(this.state);
  }

  emailValid(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  validateEmail(email) {
    var token = Auth.getToken()
    axios
      .post('/api/v2/check_for_email', { email: email }, { headers: { token: token } })
      .then(res => {
        //console.log(result);
        this.setState({
          emailError: true
        });
      })
      .catch(err =>
        //console.log(result);
        this.setState({
          emailError: false,
          email: email
        })
      )
  }

  validatePasswords(passwordConfirmation) {
    if (this.state.password.length >= 8 && this.state.password === passwordConfirmation) {
      console.log("match")
      this.setState({
        passwordConfirmation: passwordConfirmation,
        passwordError: false,
        passwordsMatch: true
      });
      return
    } else {
      this.setState({
        passwordConfirmation: passwordConfirmation,
        passwordError: true,
        passwordsMatch: false
      });
      return
    }
  }

  handleSubmit(e) {
    e.preventDefault()
    let emailValue
    let emailField = document.getElementsByName('email')[0].value
    this.setState({
      updatingLogin: true
    })
    var token = Auth.getToken()
    if (this.state.email === emailField) {
      emailValue = this.state.email
    } else {
      emailValue = emailField
    }
    console.log("Email Value: ", emailValue, "Email Field: ", emailField)
    // axios
    // .patch(`/api/v2/users/${this.props.guid}`,
    //       {email: emailValue,
    //         password: this.state.password,
    //         password_confirmation: this.state.passwordConfirmation
    //       }, {headers: {token: token}})
    //       .then(res => {
    //         this.setState({
    //           updatingLogin: false,
    //           redirectToLogin: true
    //         })
    //         Auth.deauthenticateUser();
    //         return <Navigate push to={{ pathname: "/login", state: {} }} />; 
    //       })
    //       .catch(err =>
    //              this.throwError(err))
  }

  throwError(err) {
    let errorObject = err.response.data.error
    let errorMessage = "\nERROR CODE: " + errorObject.status + "\n ERROR CLASS: " + errorObject.class_name + " \nERROR MESSAGE: " + errorObject.developer_message
    console.log("ERROR MESSAGE: " + errorMessage + "\n MORE INFO: " + err.response.data.error.more_info.join(" ,").split(",").join("\n"))
    //alert(errorMessage)

    this.setState({
      loading: false,
      query: "",
      errorState: true,
      errorMessage: errorMessage
    })
  }

  render() {
    const { redirectToLogin, email, passwordsMatch, emailError, passwordError, updatingLogin } = this.state

    if (redirectToLogin) {
      Auth.deauthenticateUserAll();
      return <Navigate push to={{ pathname: "/login", state: {} }} />;
    }

    let updateUserButton, emailErrorField, passwordErrorField, emailValidImage, passwordValidImage, emailValidColor, passwordValidColor
    if (this.emailValid(email) && passwordsMatch && !emailError) {
      updateUserButton = <div>
        <input type="submit"
          className="button-11 w-button"
          onClick={(e) => { this.handleSubmit(e) }}
          value="CHANGE LOGIN" />
      </div>
    } else {
      updateUserButton = <div>
        <input type="submit"
          className="button-11 w-button"
          disabled
          style={{ opacity: '0.5' }}
          value="CHANGE LOGIN" />
      </div>
    }

    if (emailError) {
      emailErrorField = <div>
        <p className="w-form-fail"
          name="emailError"
          style={{
            marginTop: '5px',
            display: 'inline-block',
            width: '75%',
            color: 'red',
          }}>

          Email either not valid or already in use by another account. Please login or reset your password.
        </p>
      </div>
      emailValidImage = ""

    } else if (!emailError && this.emailValid(email)) {
      emailErrorField = <div></div>
      emailValidImage = "url('" + validatedCheck + "')"
      emailValidColor = 'green'
    } else {
      emailErrorField = <div> </div>
      emailValidImage = ""
      emailValidColor = 'red'
    }

    if (passwordError) {
      passwordErrorField = <div>
        <p className="w-form-fail"
          name="passwordMatchError"
          style={{
            marginTop: '5px',
            display: 'inline-block',
            color: 'red'
          }}>

          Password fields must match and be at least 8 characters long.
                </p>
      </div>
      passwordValidImage = ""
    } else if (!passwordError && passwordsMatch) {
      console.log("error field is a check")
      passwordErrorField = <div></div>
      passwordValidImage = "url('" + validatedCheck + "')"
      passwordValidColor = 'green'
    } else {
      passwordErrorField = <div> </div>
      passwordValidImage = "" + validatedCheck + "')"
      passwordValidColor = 'red'
    }


    if (updatingLogin) {
      return (
        <div className="w-form">
          <div className="form-4" style={{ marginTop: '20px', marginBottom: '50px', fontWeight: '700' }}>
            updating login info...
          </div>
        </div>
      )
    } else {
      return (
        <div className="w-form">
          <div className="form-4">
            <input type="text"
              className="text-field-8 w-input"
              name="email"
              onChange={this.handleChange}
              style={{
                background: emailValidImage + ' 98% 50% /20px no-repeat',
                color: emailValidColor
              }}
              placeholder="New Login Email" />
            {emailErrorField}

            <input type="password"
              name="password"
              onChange={this.handleChange}
              className="text-field-9 w-input"
              placeholder="Password" />
            {passwordErrorField}

            {updateUserButton}
            <small> Want to update your password? Visit <a href="https://app.musealab.com/forgot-password" target="_blank"> https://app.musealab.com/forgot-password </a> or Log out and click "Reset" link next to "Forgot Password?" under the Sign In button. </small>
          </div>
        </div>
      )
    }
  }
}

export default LoginInfoForm;

