import React, { Component } from "react";
import { Navigate, Link } from "react-router-dom";
import Auth from "../modules/Auth";
import axios from "axios";
import downloadBlack from "../img/outline_cloud_download_black_48dp.png";

import noPhoto from "../img/no-photo.png";
//import downloadCloud from "../img/outlineclouddownloadblack48dp.png"
import adminNotesIcon from "../img/adminNotesIcon.png"
import orderNotesIcon from "../img/orderNotesIcon.png"

//import "../css/newWebflow.css";

class PhotographerCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: this.props.guid,
      updatingPhotographer: false,
      password: this.props.password,
      passwordConfirmation: this.props.passwordConfirmation,
      photographer: this.props.photographer,
      guid: this.props.guid,
      email: this.props.email,
      creditAvailable: this.props.creditAvailable
    };

   this.validatePasswords = this.validatePasswords.bind(this);
   this.validateEmail = this.validateEmail.bind(this);
   this.emailValid = this.emailValid.bind(this);
   this.updatePhotographer = this.updatePhotographer.bind(this);
   this.throwError = this.throwError.bind(this)
   this.handleChange = this.handleChange.bind(this)
   this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        key: nextProps.guid,
        updatingPhotographer: false,
        password: nextProps.password,
        passwordConfirmation: nextProps.passwordConfirmation,
        photographer: nextProps.photographer,
        guid: nextProps.guid,
        email: nextProps.email,
        creditAvailable: nextProps.creditAvailable
      })
    }
  }

  handleChange(e) {
    const name = e.target.name;
    const val = e.target.value;
    if (name === "passwordConfirmation") {
      this.validatePasswords(val)
    } else if (name === "newEmail" && this.emailValid(val)) {
      this.validateEmail(val)
    } else {
      this.setState({
        [name]: val
      });
    }
    //console.log(this.state);
  }

  handleSubmit(e) {
    e.preventDefault()
    let emailValue
    let emailField = document.getElementsByName('email')[0].value
    this.setState({
      updatingLogin: true
    })
    var token = Auth.getToken()
    if (this.state.email === emailField) {
      emailValue = this.state.email
    } else {
      emailValue = emailField
    }

    axios
      .patch(`/api/v2/users/${this.props.guid}`,
        {email: emailValue,
          password: this.state.password,
          password_confirmation: this.state.passwordConfirmation
        }, {headers: {'token': token}})
          .then(res => {
            this.setState({
              updatingLogin: false,
              redirectToLogin: true
            })
            Auth.deauthenticateUser();
            return <Navigate push to={{ pathname: "/login", state: {} }} />; 
          })
          .catch(err =>
                 this.throwError(err))
  }


  throwError(err) {
    let errorMessage
    if (err.response != null) {
      let errorObject = err.response.data.error
      if (errorObject != null) {
        errorMessage = "\nERROR CODE: " + errorObject.status + "\n ERROR CLASS: " + errorObject.class_name + " \nERROR MESSAGE: " + errorObject.developer_message
        if (err.response.data.error.more_info != null){
          alert("ERROR MESSAGE: " + errorMessage )
          console.log("ERROR MESSAGE: " + errorMessage + "\nMORE INFO: " + err.response.data.error.more_info.join(" ,").split(",").join("\n"))
        } else {
          alert("ERROR MESSAGE: " + errorMessage )
          console.log(errorMessage + " More info not available.")
        }
      } else {
        errorMessage = "\nERROR CODE: " + err.response
      }
    } else {
      alert("ERROR MESSAGE: " + err.stack)
      errorMessage = err.stack
    }
  }

  validatePasswords(passwordConfirmation) {
    if (this.state.password.length >= 6 && this.state.password === passwordConfirmation) {
    console.log("match")
    this.setState({
      passwordConfirmation: passwordConfirmation,
      passwordError: false,
      passwordsMatch: true
    });
    return
    } else {
    this.setState({
      passwordConfirmation: passwordConfirmation,
      passwordError: true,
      passwordsMatch: false
    });
    return
    }
  }

  emailValid(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  validateEmail(email) {
    var token = Auth.getToken()
    axios
    .post('/api/v2/check_for_email',{email: email}, {headers: {'token': token}})
         .then(res => {
           //console.log(result);
           alert('Email in use')
           this.setState({
             emailError: true
           });
         })
         .catch(err =>
                this.setState({
                  emailError: false,
                  newEmail: email
                })
               )
  }

  toggleDropdownOpen(e, guid) {
    e.preventDefault()
    var dropdownId = `status-dropdown-${guid}`
    if (e.target.classList.contains("w--open")) {
      e.target.classList.remove("w--open")
      document.querySelector(`#${dropdownId}`).classList.remove('w--open');
    } else {
      e.target.classList.add("w--open")
      document.querySelector(`#${dropdownId}`).classList.add('w--open');
    }
  }

  loginAsPhotographer(email){
    var token = Auth.getToken()
    axios
    .post("/api/v2/login_as_user",
          {email: email},
          {headers: {'token': token}}
         )
    .then(res => {
      console.log(res);
      var token = res.data.token;
      var email = res.data.email;
      var name = res.data.name;
      var businessName = res.data.business_name;
      Auth.authenticateLoggedInAsUser(token, email, name, businessName);
      this.setState({
        redirectToReferrer: true,
        loggedInAsPhotographer: true,
        auth: Auth.isAuthenticated(),
        from: { pathname: "/" }
      });
    })
    .catch(err => this.throwError(err));
  }

  updatePhotographer() {
    let newValue, type
    if (this.state.passwordsMatch && this.state.passwordConfirmation.length >= 6) {
      type = "password"
      newValue = this.state.passwordConfirmation
    }
    if (!this.state.emailError && this.state.newEmail && this.state.newEmail.length >= 3) {
      type = "email"
      newValue = this.state.newEmail
    }
    if (this.state.updateAvailableCredit && this.state.updateAvailableCredit.length >= 1) {
      type = "credit"
      newValue = parseFloat(+this.state.updateAvailableCredit).toFixed(2)
    }

    if (type && newValue) {
      this.setState({ updatingPhotographer: true })
      var token = Auth.getToken()
      console.log("sending value: " + newValue)
      console.log("sending type: " + type)

      // .post(/api/v2/check_for_email',{email: email}, {headers: {'token': token}})
      axios.patch(`api/v2/users/${this.props.guid}`, { type: type, value: newValue}, {headers: {'token': token}})
      .then(res => {
        console.log(res)
        console.log("response data guid: " + res.data.data.attributes.guid)
        this.setState({
          updatingPhotographer: false,
          loading: false,
          searching: false,
          errorState: false,
          key: res.data.data.attributes.guid,
          password: "",
          passwordConfirmation: "",
          photographer: res.data.data.attributes,
          guid: res.data.data.attributes.guid,
          email: res.data.data.attributes.email,
          creditAvailable: res.data.data.attributes.credit_available
        });
        return true;
      })
      .catch(err => {
        this.setState({
          updatingPhotographer: false,
          loading: false,
          searching: false
        });
        this.throwError(err)
      })
    } else {
      return true
    }
  }

  updateUserStatus(guid, newStatus) {
    this.setState({ updatingPhotographer: true })

    var token = Auth.getToken()
    axios
      .patch(`api/v2/users/${this.props.guid}`, {type: "status", value: newStatus}, {headers: {'token': token}})
    .then(res => {
      this.setState({
        updatingPhotographer: false,
        photographer: res.data.data.attributes,
        loading: false,
        errorState: false
      });
      return true;
    })
    .catch(err => {
      this.throwError(err)
    })
  }

  render() {

    //key, orderId, orderNotes, orderAdminNotes, orderIsApi, orderApiVendor, orderHasFraming, orderHasMounting, orderHasMatting, labOrderCard, downloadAllLink, 
    //let {printsColor, mountColor, matColor, frameColor, canvasColor,
      //apiOrderColor, boutiqueColor, resetColor} = this.state

    if (this.state.loggedInAsPhotographer) {
      return <Navigate to={{ pathname: "/lab-orders", state: {} }} push />;
    }

    let updateUserButton, emailErrorField, passwordErrorField, emailValidImage, passwordValidImage, emailValidColor, passwordValidColor
    const {photographer, guid, creditAvailable } = this.state

    let userApprovedStatus
    if (photographer.user_approved) {
      userApprovedStatus = "APPROVED"
    } else {
      userApprovedStatus = "UNAPPROVED"
    }

    let loginAsButton, updateButton, photographerEmailField, passwordField, passwordConfField, updateCreditField, userApprovedStatusField
    if (photographer.email !== "EMAIL") {
      loginAsButton = <div style={{cursor: 'pointer'}}
      onClick={(e) => {this.loginAsPhotographer(photographer.email)}}
      className="button-41 w-button ">login as</div>
      updateButton = <div style={{opacity: 1, cursor: 'pointer'}} onClick={(e) => {this.updatePhotographer()}}
      className="button-41 w-button " >update user</div>
      photographerEmailField = <div className="text-block-137">
            <input type="text"
              className="text-field-8 w-input"
              name="newEmail"
              onChange={this.handleChange}
              style={{
                background: emailValidImage + ' 98% 50% /20px no-repeat',
                width: '175px',
                marginLeft: '20px',
                textAlign: 'center',
                color: emailValidColor }}
              placeholder={photographer.email} />
              {emailErrorField}
          </div>

      passwordField = <div className="text-block-139">
            <input type="text"
              name="password"
              style={{ width: '130px',
                marginLeft: '20px',
                textAlign: 'center',
              }}
              onChange={this.handleChange}
              className="text-field-9 w-input"
              placeholder="Password" />
          </div>

      passwordConfField = <div className="text-block-140">
            <input type="text"
              name="passwordConfirmation"
              onChange={this.handleChange}
              style={{
                background: passwordValidImage + ' 98% 50% /20px no-repeat',
                width: '130px',
                marginLeft: '20px',
                textAlign: 'center',
                color: passwordValidColor }}
              className="text-field-9 w-input"
              placeholder="Password Confirmation" />
              {passwordErrorField}
          </div>

      updateCreditField = <div className="text-block-140">
            <input type="text"
              name="updateAvailableCredit"
              onChange={this.handleChange}
              style={{
                background: passwordValidImage + ' 98% 50% /20px no-repeat',
                width: '130px',
                marginLeft: '20px',
                textAlign: 'center',
                color: passwordValidColor }}
              className="text-field-9 w-input"
              placeholder={parseFloat(+creditAvailable).toFixed(2)} />
          </div>

      let dropdownId = `status-dropdown-${guid}`
      userApprovedStatusField = <div data-delay="0"
                  onClick={(e) => {this.toggleDropdownOpen(e, guid)}}
                  style={{textAlign: 'center'}}
                  className="dropdown w-dropdown">
                  <div className="dropdown-toggle w-dropdown-toggle">
                    <div className="icon w-icon-dropdown-toggle"
                      style={{marginTop: '24px'}} >
                    </div>
                    <div className="text-block-11">
                      <strong style={{textTransform: 'uppercase'}}>{userApprovedStatus}</strong>
                    </div>
                  </div>
                  <nav className="w-dropdown-list" id={dropdownId}>
                    <div className="dropdown-link w-dropdown-link"
                    onClick={(e) => {this.updateUserStatus(guid, "approved")}}>
                      APPROVED</div>
                    <div className="dropdown-link-2 w-dropdown-link"
                    onClick={(e) => {this.updateUserStatus(guid, "unapproved")}}>
                      NOT APPROVED</div>
                  </nav>
                </div>
    } else {
      loginAsButton = <div></div>
      updateButton = <div></div>
      photographerEmailField = <div className="text-block-137"> EMAIL </div>
      passwordField = <div className="text-block-139"> PASSWORD </div>
      passwordConfField = <div className="text-block-140"> PASSWORD CONF </div>
      userApprovedStatusField = "STATUS"
    }


    if (this.state.updatingPhotographer) {
    return (
      <div style={{fontWeight: '700', textAlign: 'center'}}> 
        updating photographer...
      </div>
    )
    } else {
    return (
      <div className="w-layout-grid grid-9">

        <div id="photographer-name">
          <div className="text-block-136">
            {photographer.first_name} {photographer.last_name}
            <br/>
            {photographer.business_url}
            <br/>
            {photographer.social_media_url}
          </div>
        </div>

        <div id="photographer-email">
          {photographerEmailField}
        </div>

        <div id="photographer-business-name">
          <div className="text-block-138">
            {photographer.business_name}
          </div>
        </div>

        <div id="photographer-password">
          {passwordField}
        </div>

        <div id="photographer-password-conf">
          {passwordConfField}
        </div>

        <div id="photographer-status">
          <div className="text-block-141">
            {userApprovedStatusField}
          </div>
        </div>

        <div id="photographer-credit">
          <div className="text-block-142">
            {updateCreditField}
          </div>
        </div>

        <div id="photographer-login">
          {loginAsButton}
        </div>

        <div id="photographer-upadte">
          {updateButton}
        </div>
      </div>
    )
    }
  }

}

export default PhotographerCard;
