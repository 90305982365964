import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import Auth from "./modules/Auth.js"
import axios from "axios"
import MuseaApi from "./modules/MuseaApi.js"

import Honeybadger from '@honeybadger-io/js'
import ErrorBoundary from '@honeybadger-io/js'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import NavBar from "./components/NavBar.js";
import CartSidebar from "./components/CartSidebar.js";
import ShippingAddressForm from "./components/ShippingAddressForm.js";
import CreditCardForm from "./components/CreditCardForm.js";
import DiscountCodeForm from "./components/DiscountCodeForm.js";
import BoutiquePackagingForm from "./components/BoutiquePackagingForm.js";

class CheckoutDetails extends Component {
  constructor(props) {
    super(props);
    const hb_config = {
      api_key: process.env.REACT_APP_HB_KEY,
      environment: process.env.REACT_APP_CURRENT_ENV,
      revision: 'current'
    }
    this.honeybadger = Honeybadger.configure(hb_config)
    this.state = {
      loading: true,
      honeybadger: this.honeybadger,
      boutiqueShippingFormShown: false,
      saveCard: true,
      showShippingAddressForm: false,
      updatingLabOrder: false,
      stripe: null,
      creditCards: [],
      // savedCards: [],
      defaultCard: '',
      localPickup: false,
      sripeId: '',
      //guid: this.props.location.state.orderGuid,
      //labOrder: this.props.location.state.labOrder,
      errorState: false
    };
   this.getUserProfile = this.getUserProfile.bind(this)
   this.toggleShippingAddressForm = this.toggleShippingAddressForm.bind(this);
   this.updateLabOrder= this.updateLabOrder.bind(this)
   this.updateBoutiqueOnLabOrder = this.updateBoutiqueOnLabOrder.bind(this)
   this.toggleSaveCard = this.toggleSaveCard.bind(this)
   this.clearCart = this.clearCart.bind(this)
   this.deleteLineItem = this.deleteLineItem.bind(this)
   this.handleChange = this.handleChange.bind(this);
   this.toggleLocalPickup = this.toggleLocalPickup.bind(this)
  }


  componentDidMount() {
    let museaApi = new MuseaApi
    museaApi.checkAndResetCache()
    //if (!this.state.currentClientVersion) {
      //museaApi.getCurrentCientVersion().then(version => {
        //this.setState({
          //currentClientVersion: version
        //})
      //})
      //.catch(err =>
             //this.throwError(err))
    //}

    let stripeDiv = document.querySelector('#stripe-js')
    let stripeKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY
    this.getUserProfile()

    //debugger;
    if (window.Stripe) {
      this.setState({stripe: window.Stripe(stripeKey)});
    } else if (stripeDiv !== null ){
      stripeDiv.addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        this.setState({stripe: window.Stripe(stripeKey)});
      });
    } else {

    }


    this.getLabOrder()
  }

  componentWillReceiveProps(nextProps) {
    if(this.props !== nextProps) {
      this.setState({
      })
    }
  }

  handleChange(e) {
    if (e.target.validity.valid) {
      const name = e.target.name;
      const val = e.target.value;
      this.setState({
        [name]: val
      })
    } else {
      e.target.value = ""
    }
  }

  toggleSaveCard(e) {
    if (this.state.saveCard) {
      this.setState({
        saveCard: false
      });
    } else {
      this.setState({
        saveCard: true
      });
    }
  }



  toggleShippingAddressForm(e) {
    e.preventDefault()
    if (this.state.showShippingAddressForm){
      this.setState({
        showShippingAddressForm: false
      })
    } else {
      this.setState({
        showShippingAddressForm: true
      })
    }
  }

  getUserProfile() {
    var token = Auth.getToken()
    axios
    .get('/api/v2/profile', {headers: {token: token}})
         .then(res => {
           this.setState({
             creditCards: res.data.cards.data,
             addresses: res.data.addresses.data,
             user: res.data.user,
             defaultAddress: res.data.default_address,
             defaultCard: res.data.default_card
           });
         })
         .catch(err =>
                this.throwError(err)
               )
  }

  getLabOrder = (e, data) => {
    //e.preventDefault();
    var token = Auth.getToken()
    var guid = this.props.location.pathname["split"]("/")[2];
    axios
      .get(`api/v2/lab_orders/${guid}`, {headers: {'token': token}})
      .then(res => {

        let museaApi = new MuseaApi()
        var parsedOrder = museaApi.mergeAttributesForLabOrder(res.data);
        this.setState({
          redirectToReferrer: true,
          auth: Auth.isAuthenticated(),
          from: { pathname: "/" },
          guid: guid,
          labOrder: parsedOrder,
          formattedLineItems: parsedOrder.lineItems,
          loading: false,
          errorState: false
        });
        return true;
      })
      .catch(err => {
        this.throwError(err)
        //let error = JSON.parse(err.response)
        //this.throwError(error)
      })
  };

  updateBoutiqueOnLabOrder(val, notesValue) {
    this.setState({
      updatingLabOrder: true
    })

    let value
    var guid = this.props.location.pathname["split"]("/")[2];
    var token = Auth.getToken()

    if (val === "removeBoutique") {
      value = false
      notesValue = ''
    } else {
      value = true
    }

    axios
    .patch(`/api/v2/lab_orders/${guid}`,
          {type: "boutique_shipping",
            boutique_shipping_note: notesValue, value: value }, {headers: {token: token}})
          .then(res => {
            let museaApi = new MuseaApi()
            var parsedOrder = museaApi.mergeAttributesForLabOrder(res.data);
            this.setState({
              labOrder: parsedOrder,
              updatingLabOrder: false,
              saveCard: parsedOrder.used_saved_card,
              formattedLineItems: parsedOrder.lineItems,
            })
          })
          .catch(err =>
                 this.throwError(err))
  }

  updateLabOrder(e, type, value) {
    e.preventDefault()
    console.log("Update Lab Order")
    this.setState({
      updatingLabOrder: true
    })

    var guid = this.props.location.pathname["split"]("/")[2];
    //const name = e.target.name;
    const val = e.target.value;

    var token = Auth.getToken()

    if (type === "permission_to_share") {
      if (val === "no") {
        value = false
      } else {
        value = true
      }
    }

    axios
    .patch(`/api/v2/lab_orders/${guid}`,
          {type: type,
            value: value }, {headers: {token: token}})
          .then(res => {

            let museaApi = new MuseaApi()
            var parsedOrder = museaApi.mergeAttributesForLabOrder(res.data);
            this.setState({
              labOrder: parsedOrder,
              updatingLabOrder: false,
              saveCard: parsedOrder.used_saved_card,
              formattedLineItems: parsedOrder.lineItems,
            })
          })
          .catch(err =>
                 this.throwError(err))
  }

  throwError(err) {
    let errorMessage, errorObject
    if (err.response.data["error"] && err.response.data["error"].status === 401) {
      this.setState({
        userLoggedOut: true
      })
      return false
    } else {
      if (err.response.data["error"] != null) {
        errorObject = err.response.data["error"]
        errorMessage = "\nERROR CODE: " + errorObject.status + "\nERROR CLASS: " + errorObject.class_name + " \nERROR MESSAGE: " + errorObject.user_message["message"]
        if (errorObject["more_info"] != null) {
          this.state.honeybadger.notify(err)
          alert("ERROR: " + errorMessage)
          console.log("ERROR: " + errorMessage + "\nMORE INFO: " + errorObject["more_info"].join(" ,").split(",").join("\n"))
        } else {
          this.state.honeybadger.notify(err)
          alert("ERROR: " + errorMessage)
          console.log(errorMessage + " More info not available.")
        }
      } else { 
        errorMessage = "\nERROR CODE: " + err.response.status + "\n ERROR MESSAGE: " + err.response.message
      }
    }
      this.setState({
        loading: false,
        selections: [],
        updatingLabOrder: false,
        errorState: true,
        addingToCart: false,
        errorMessage: errorMessage
      })
  }

  toggleLocalPickup(e) {
    if (this.state.localPickup) {
      this.setState({
        localPickup: false,
        newAddress: false
      });
      this.updateLabOrder(e, "local_pickup", false)
    } else {
      this.setState({
        localPickup: true,
        newAddress: false
      });
      this.updateLabOrder(e, "local_pickup", true)
    }
  }

  toggleDropdownOpen(e) {
    e.preventDefault()
    if (e.target.classList.contains("w--open")) {
      e.target.classList.remove("w--open")
      document.querySelector("nav.dropdown-list-9").classList.remove('w--open');
    } else {
      e.target.classList.add("w--open")
      document.querySelector("nav.dropdown-list-9").classList.add('w--open');
    }
  }

  clearCart() {
    if (this.state.labOrder !== null && this.state.labOrder.lineItems !== null && this.state.labOrder.lineItems.length > 0) {
      this.state.labOrder.lineItems.map(lineItem => {
        this.deleteLineItem(lineItem.guid)
      })
    }
    return true
  }

  deleteLineItem(lineItemGuid){
    var token = Auth.getToken()
    //var guid = this.props.location.pathname["split"]("/")[2];
    axios
    .delete(`api/v2/line_items/${lineItemGuid}`,
            {headers: {'token': token}}
           )
           .then(res => {
             let museaApi = new MuseaApi()
             var parsedOrder = museaApi.mergeAttributesForLabOrder(res.data);
             this.setState({
               labOrder: parsedOrder,
               formattedLineItems: parsedOrder.lineItems,
               loading: false,
               errorState: false
             });
             return true;
           })
           .catch(err => {
             this.throwError(err)
             //let error = JSON.parse(err.response)
             //this.throwError(error)
           })
  }


      //<div className="div-block-42">
        //<div className="text-block-114">
          //CAN MUSEA SHARE THIS ORDER ON THEIR SOCIAL MEDIA CHANNELS?
        //</div>
        //<div data-delay="0" onClick={(e) => {this.toggleDropdownOpen(e)}} className="dropdown-9 w-dropdown">
          //<div className="w-dropdown-toggle">
            //<div>{socialMediaShareValue}</div>
            //<div className="w-icon-dropdown-toggle"></div>
          //</div>
          //<nav className="w-dropdown-list dropdown-list-9">
            //<a href="#" onClick={(e) => {this.updateLabOrder(e, "permission_to_share", "yes")}} style={{width: '175px'}} className="dropdown-link-9 w-dropdown-link">YES</a>
            //<a href="#" onClick={(e) => {this.updateLabOrder(e, "permission_to_share", "no")}} style={{width: '175px'}} className="dropdown-link-9 w-dropdown-link">NO</a>
          //</nav>
        //</div>
      //</div>
      //if (labOrder && labOrder !== null && labOrder.permission_to_share !== null) {
        //if (labOrder.permission_to_share) {
          //let socialMediaShareValue = "YES"
        //} else {
          //let socialMediaShareValue = "NO"
        //}
      //} else {
        //let socialMediaShareValue = "NO"
      //}

  notify() {
    toast('You need a shipping address and credit card to checkout!', { position: "top-right", autoClose: 10000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true });
  }

  render() {
    const {loading, labOrder, boutiqueShippingFormShown} = this.state
    let stripeId, guid, cartSidebar, orderName, boutiqueShippingForm, creditAvailable, newCardAttr, labOrderDiscountCodeUsed, redirectUrl, cardMask, saveCard, checkedValue, orderReady

    if (this.state.userLoggedOut) {
      return <Navigate to={{ pathname: '/login' , state: { notifyUserOfLogout: true } }} push />
    }

    if (labOrder && labOrder.lineItems && labOrder.lineItems.length < 1) {
      let redirectOrderUrl = `/lab-orders/${labOrder.guid}/edit/order-builder`
      return <Navigate to={{ pathname: redirectOrderUrl , state: {cartCleared: true, guid: this.state.guid} }} push />
    }

    guid = this.props.location.pathname["split"]("/")[2];

    if (labOrder && labOrder !== null) {
      redirectUrl = `/lab-orders/${labOrder.guid}/checkout-final`
    } else {
      redirectUrl = ''
    }

    if (this.state.creditCards && this.state.creditCards.length > 0) {
      newCardAttr = 'hidden'
    } else if (labOrder && labOrder !== null && labOrder.selected_token !== null ){
      newCardAttr = 'hidden'
    } else {
      newCardAttr = 'visible'
    }

    if (boutiqueShippingFormShown || (labOrder && labOrder !== null && labOrder.eligible_for_boutique_shipping)) {
      boutiqueShippingForm = <div><BoutiquePackagingForm updateBoutiqueOnLabOrder={this.updateBoutiqueOnLabOrder} updateLabOrder={this.updateLabOrder} boutiqueNote={labOrder.boutique_shipping_note} boutiqueShippingSelection={labOrder.boutique_shipping} /></div>
    } else {
      //let boutiqueShippingForm = <div></div>;
      boutiqueShippingForm = <div></div>
    }


    if (this.state.user) {
      stripeId = this.state.user.data.attributes.stripe_customer_id
      creditAvailable = this.state.user.data.attributes.credit_available
    } else {
      stripeId = ""
      creditAvailable = 0.0
    }

    //<div className="socialMediaShare">
    //<SocialMediaShare />
    //</div>
    //"CHECKOUT"




    if (labOrder && labOrder !== null) {
      orderName = labOrder.order_name
      if (labOrder.selected_card_mask && labOrder.selected_card_mask.length > 0 && labOrder.shipping_address && labOrder.shipping_address.guid && labOrder.shipping_address.guid.length > 0 || parseFloat(labOrder.credit_applied) == (parseFloat(labOrder.subtotal) + parseFloat(labOrder.shipping_total)) ) {
        orderReady = true
      } else if (labOrder.selected_card_mask && labOrder.selected_card_mask.length > 0 && labOrder.local_pickup === true) {
        orderReady = true
      } else {
        orderReady = false
      }
      cartSidebar = <div> <CartSidebar guid={labOrder.guid} labOrder={labOrder} orderReady={orderReady} showLocalPickup='true' localPickup={this.state.localPickup} toggleLocalPickup={this.toggleLocalPickup} redirectUrl={redirectUrl} buttonText="REVIEW & CONFIRM" labOrderSubtotal={parseFloat(+labOrder.subtotal).toFixed(2)} labOrderDiscount={parseFloat(+labOrder.discount).toFixed(2)} labOrderCredit={parseFloat(+labOrder.credit_applied).toFixed(2)} labOrderBoutiqueTotal={parseFloat(+labOrder.boutique_shipping_price).toFixed(2)} shippingTotal={parseFloat(+labOrder.shipping_total).toFixed(2)} total={parseFloat(+labOrder.total).toFixed(2)} clearCart={this.clearCart} /> </div>
      if (labOrder && labOrder.discount_code && labOrder.discount_code.code) {
        labOrderDiscountCodeUsed = labOrder.discount_code.code
      } else {
        labOrderDiscountCodeUsed = "NONE SELECTED"
      }

      if (labOrder.selected_card_mask && labOrder.selected_card_mask.length > 0) {
        cardMask = labOrder.selected_card_mask

        checkedValue = labOrder.used_saved_card
        saveCard = <div className="checkbox-field-5 w-checkbox" style={{marginLeft: 'auto', marginRight: 'auto', width: '250px'}}>
              <input type="checkbox" checked={checkedValue} onClick={(e) => { this.updateLabOrder(e, "used_saved_card", !checkedValue) }} id="SAVE CARD" name="saveCard" className="w-checkbox-input"/>
              <label htmlFor="SAVE CARD" className="checkbox-label-6 w-form-label">SAVE CARD FOR FUTURE USE</label>
            </div>
      } else {
        cardMask = "NONE SELECTED"
        saveCard = <div></div>
      }
    } else {
      orderName = "CHECKOUT DETAILS FOR ORDER"
      cartSidebar = <div></div>
      labOrderDiscountCodeUsed = null
      cardMask = "NO INFO"
      saveCard = <div></div>
      labOrderDiscountCodeUsed = "NO INFO"
    }

    if (this.props.location.state && this.props.location.state.notifyUserOfMissingShippingAddress) {
      if (document.getElementsByClassName('Toastify__toast Toastify__toast--default').length < 1) {
        this.notify()
      }
    }

    if (loading || this.state.updatingLabOrder) {
      return (
        <div>
          <NavBar />
          <div className="w-row">
            <div className="column-2000 w-col w-col-9">
              <div id="w-node-dd7d45327c78-d0052ddd">
                <div style={{textAlign: 'center', fontWeight: '800', marginLeft: '10%', marginTop: '10%'}} className="text-block-103">loading...</div>
                <div className="div-block-39"></div>

              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <NavBar />
          <ToastContainer position="top-right" autoClose={10000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
          <div className="w-row">
            <div className="column-2000 w-col w-col-9">
              <div id="w-node-dd7d45327c78-d0052ddd">
                <div className="text-block-103">{orderName}</div>
                <div className="div-block-39"></div>
              </div>

              <ShippingAddressForm labOrder={labOrder} page="checkout" labOrderGuid={labOrder.guid} toggleShippingAddressForm={this.toggleShippingAddressForm} showAddressForm={this.state.showShippingAddressForm} selectedAddress={labOrder.shipping_address} getUserProfile={this.props.getUserProfile} getLabOrder={this.getLabOrder} updateLabOrder={this.updateLabOrder} localPickup={this.state.localPickup} savedAddresses={this.state.addresses} newAddress={this.state.newAddress} />

              <CreditCardForm  updateLabOrder={this.updateLabOrder} localPickup={labOrder.local_pickup} cardMask={cardMask} saveCard={saveCard} newCardVisibility={newCardAttr} savedCards={this.state.creditCards} checkoutDetails='true' defaultCard={this.state.defaultCard} stripeId={stripeId}/>
              
              <DiscountCodeForm updateLabOrder={this.updateLabOrder} guid={guid} creditUsed={labOrder.credit_applied} discountCodeUsed={labOrderDiscountCodeUsed} creditAvailable={creditAvailable} />
              <div className="div-block-42" style={{marginTop: '20px', marginBottom: '20px'}}>
                <div className="text-block-1114" style={{}}>
                  CODE APPLIED: {labOrderDiscountCodeUsed}
                </div>
              </div>
              {boutiqueShippingForm}



            </div>

            <div className="column-19 w-col w-col-3">
              <div>
                {cartSidebar}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default CheckoutDetails;
