import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import logo1080 from "./img/MUSEAbadge-black-p-1080.jpeg";
import logo500 from "./img/MUSEAbadge-black-p-500.jpeg"
import logo800 from "./img/MUSEAbadge-black-p-800.jpeg"
import logoBlack from "./img/MUSEAbadge-black.jpg"

import validatedCheck from "./img/sign-up-1-v2-baselinecheckcircleblack48dp-copy-green@2x.png";
import Auth from "./modules/Auth.js";
import axios from "axios";

class ResetPassword extends Component {

  constructor() {
    super();
    this.state = {
      creating: false,
      redirectToReferrer: false,
      passwordResetComplete: false,
      email: '',
      challengeToken: '',
      validatedChallengeToken: false,
      passwordError: false,
      emailError: false,
      challengeTokenError: false,
      passwordsMatch: false,
      errorState: false,
      auth: Auth.isAuthenticated()
    };
   this.handleChange = this.handleChange.bind(this);
   this.validatePasswords = this.validatePasswords.bind(this);
   this.validateChallengeToken = this.validateChallengeToken.bind(this);
   this.validateEmail = this.validateEmail.bind(this);
   this.emailValid = this.emailValid.bind(this);
   this.throwError = this.throwError.bind(this)
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.innerHtml = "";
    document.body.appendChild(script);
  }

  handleChange(e) {
    const name = e.target.name;
    const val = e.target.value;
    if (name === "passwordConfirmation") {
      this.validatePasswords(val)
    } else if (name === "challengeToken" && this.state.email.length > 1 && val.replace(/\s+/g, '').length === 6) {
      var token = val.replace(/\s+/g, '')
      this.validateChallengeToken(token)
    } else if (name === "email" && this.emailValid(val)) {
      this.validateEmail(val)
    } else {
      this.setState({
        [name]: val
      });
    }
    //console.log(this.state);
  }

  emailValid(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  validateChallengeToken(token) {
    axios
    .post('/api/v2/validate_challenge_token',
         {email: this.state.email, challenge_token: token
         }, {headers: {}})
         .then(res => {
           this.setState({
             challengeTokenError: false,
             validatedChallengeToken: true,
             challengeToken: token
           });
         })
         .catch(err =>
                this.setState({
                  challengeTokenError: true,
                  validatedChallengeToken: false
                })
               )
  }

  validateEmail(email) {
    axios
    .post(`/api/v2/check_for_email`,{email: email}, {headers: {}})
         .then(res => {
           //console.log(result);
           this.setState({
             emailError: false,
             email: email
           });
         })
         .catch(err =>
                //console.log(result);
                this.setState({
                  emailError: true
                })
               )
  }

  validatePasswords(passwordConfirmation) {
    if (this.state.password.length >= 6 && this.state.password === passwordConfirmation) {
    console.log("match")
    this.setState({
      passwordConfirmation: passwordConfirmation,
      passwordError: false,
      passwordsMatch: true
    });
    return
    } else {
    this.setState({
      passwordConfirmation: passwordConfirmation,
      passwordError: true,
      passwordsMatch: false
    });
    return
    }
  }

  handleSubmit(e) {
    e.preventDefault()
    axios
    .post('/api/v2/reset_password',
          {email: this.state.email,
            password: this.state.password,
            password_confirmation: this.state.passwordConfirmation
          }, {})
          .then(res => {
            this.setState({
              passwordResetComplete: true
            });
          })
          .catch(err =>
                 this.throwError(err))
  }

  throwError(err) {
    let errorMessage
    if (err.response != null) {
      let errorObject = err.response.data.error
      errorMessage = "\nCODE: " + errorObject.status + "\nERROR CLASS: " + errorObject.class_name + "\nERROR MESSAGE: " + errorObject.developer_message
      if (err.response.data.error.more_info != null){
        console.log("ERROR MESSAGE: " + errorMessage + "\nMORE INFO: " + err.response.data.error.more_info.join(" ,").split(",").join("\n"))
      } else {
        console.log(errorMessage + " More info not available.")
      }
    } else {
      errorMessage = err.stack
    }

    this.setState({
      loading: false,
      selections: [],
      errorState: true,
      addingToCart: false,
      errorMessage: errorMessage
    })
  }


  render() {
    let logoSrcSet = `${logo500} 500w, ${logo800} 800w, ${logo1080} 1080w, ${logoBlack} 1200w`
    let resetPasswordButton, emailErrorField, challengeTokenErrorField, passwordErrorField, passwordValidImage, emailValidImage, emailValidColor, challengeTokenValidImage, challengeTokenValidColor

    if (this.state.passwordResetComplete) {
      return <Navigate push to={{ pathname: '/', state: {} }} />
    }

    if (this.state.validatedChallengeToken && this.state.passwordsMatch) {
      resetPasswordButton = <div>
                <input type="submit"
                  className="button w-button"
                  onClick={(e) => {this.handleSubmit(e)}}
                  value="SAVE"/>
                </div>
    } else {
      resetPasswordButton = <div>
                <input type="submit"
                  className="button w-button"
                  disabled
                  style={{opacity: '0.5'}}
                  value="SAVE"/>
                </div>
    }

    if (this.state.emailError) {
      emailErrorField = <div>
        <p className="w-form-fail" name="emailError" style={{display: 'inline-block', marginTop: '5px'}}>

          Email does not exist. Please register as a new user.
        </p>
      </div>

      emailValidImage = ""
      emailValidColor = 'red'
    } else if (!this.state.emailError && this.emailValid(this.state.email)) {
      emailErrorField = <div></div>
      emailValidImage = "url('" + validatedCheck + "')"
      emailValidColor = 'green'
    } else {
      emailErrorField = <div></div>
      emailValidImage = ""
    }

    if (this.state.challengeTokenError) {
      challengeTokenErrorField = <div>

        <p className="w-form-fail" name="resetTokenError" style={{display: 'inline-block', marginTop: '5px'}}>
          Reset token either expired or is not valid for this email address.
        </p>
      </div>
      challengeTokenValidImage = ""
      challengeTokenValidColor = 'red'
    } else if (this.state.validatedChallengeToken){
      challengeTokenErrorField = <div></div>
      challengeTokenValidImage = "url('" + validatedCheck + "')"
      challengeTokenValidColor = <div></div>
    } else {
      challengeTokenErrorField = <div></div>
      challengeTokenValidImage = ""
    }

    if (this.state.passwordError) {
      passwordErrorField = <div>
                <p name="passwordMatchError" className="w-form-fail" style={{display: 'inline-block', marginTop: '5px' }}>
                  Password fields must match and be at least 8 characters long.
                </p>
      </div>

      passwordValidImage = ""
    } else if (!this.state.passwordError && this.state.passwordsMatch) {
      console.log("error field is a check")
      passwordErrorField = <div></div>
      passwordValidImage = "url('" + validatedCheck + "')"
    } else {
      passwordErrorField = <div> </div>
      passwordValidImage = ""
    }


    if (this.state.errorState) {
    return (
      <div>
        <div className="ForgotPassword" style={{}}>
          <img alt="musea logo"
            srcset={logoSrcSet}
            sizes="144px"
            className="image"
            src={logoBlack}
            style={{width: "144"}}
            />

            <div className="w-form-fail" style={{
                textAlign: 'center',
                marginTop: '50px',
                marginBottom: '20px',
                display: 'inline-block',
                width: '100%'
              }} >
              <div>
                Oops! Something went wrong while submitting the form.
                <br/>
                {this.state.errorMessage}
              </div>
            </div>

            <div className="text-block">
              <Link to="/reset-password">
                <span style={{marginLeft: '0.5em', textDecoration: 'underline', cursor: 'pointer'}}>RELOAD PAGE</span>
              </Link>
            </div>

       </div>
      </div>
    );
    } else {
      return (
        <div>
          <div className="ForgotPassword" style={{}}>

          <img alt="musea logo"
            srcset={logoSrcSet}
            sizes="144px"
            className="image"
            src={logoBlack}
            style={{width: "144"}}
            />

          <div className="form-block w-form">
            <div class="text-block-2">
              NEW PASSWORD
            </div>

              <div className="form">
                <div className="Email">
                  <input type="text"
                    className="text-field-2 w-input"
                    name="email"
                    style={{
                      background: emailValidImage + ' 98% 50% /20px no-repeat',
                      color: emailValidColor }}
                    onChange={this.handleChange}
                    placeholder="E-mail"/>

                  {emailErrorField}
                </div>

                <div className="ChallengeToken">
                  <input type="text"
                    className="text-field-2 w-input"
                    name="challengeToken"
                    style={{
                      background: challengeTokenValidImage + ' 98% 50% /20px no-repeat',
                      color: challengeTokenValidColor }}
                    pattern="[a-zA-Z0-9 ]+"
                    onChange={this.handleChange}
                    placeholder="Reset Token"/>

                  {challengeTokenErrorField}
                </div>

                <div className="NewPassword">

                  <input type="password"
                    className="text-field-2 w-input"
                    name="password"
                    onChange={this.handleChange}
                    placeholder="PASSWORD"/>

                </div>

                <div className="ConfirmNewPassword">

                  <input type="password"
                    className="text-field-2 w-input"
                    name="passwordConfirmation"
                    onChange={this.handleChange}
                    style={{ background: passwordValidImage + ' 98% 50% /20px no-repeat' }}
                    placeholder="CONFIRM PASSWORD"/>

                  {passwordErrorField}

                </div>

                {resetPasswordButton}

              </div>

              <div className="text-block" style={{marginTop: '10px'}}>
                BACK TO
                <Link to="/login">
                  <span style={{marginLeft: '0.5em', textDecoration: 'underline', cursor: 'pointer'}}>SIGN IN PAGE</span>
                </Link>
              </div>

            </div>
          </div>
        </div>
      );

    }
  }
}

export default ResetPassword;
