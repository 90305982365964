import React, { Component } from "react";
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Link } from "react-router-dom";

import MuseaApi from "../modules/MuseaApi.js"
import Auth from "../modules/Auth.js"
import axios from "axios"
import noPhoto from "../img/no-photo.png";
import decreaseQtyArrow from "../img/view-cart-page-outlinearrowrightblack48dp 1.png"
import increaseQtyArrow from "../img/view-cart-page-outlinearrowleftblack48dp 4.png"
import deleteIconCan from "../img/trash_can.png"

class LineItemSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lineItem: this.props.lineItem,
      changeQty: this.props.changeQty,
      editDisabled: this.props.editDisabled,
      isReviewScreen: this.props.isReviewScreen,
      index: this.props.index,
      updating: false,
      checksForCroppedImageCount: 0,
      guid: this.props.guid,
      userIsAdmin: this.props.userIsAdmin,
      labOrderGuid: this.props.labOrderGuid,
      lineItemId: this.props.lineItemId,
      coverPhotoUrl: this.props.coverPhotoUrl,
      croppedPhotoUrl: this.props.croppedPhotoUrl,
      photoGuid: this.props.photoGuid,
      imageName: this.props.imageName,
      externalId: this.props.externalId,
      notes: this.props.notes,
      adminNotes: this.props.adminNotes,
      products: [],
      paperOptions: this.props.paperOptions,
      finishingServiceOptions: [],
      printSize: this.props.printSize,
      printSizeGuid: this.props.printSizeGuid,
      printBorder: this.props.printBorder,
      printBorderRaw: this.props.printBorderRaw,
      paper: this.props.paper,
      paperGuid: this.props.paperGuid,
      printPrice: this.props.printPrice,
      finishingCategory: this.props.finishingCategory,
      finishingServiceGuid: this.props.finishingServiceGuid,
      finishingServiceDetailGuid: this.props.finishingServiceDetailGuid,
      finishingDetailOne: this.props.finishingDetailOne,
      finishingDetailTwo: this.props.finishingDetailTwo,
      finishingDetailThree: this.props.finishingDetailThree,
      finishingPrice: this.props.finishingPrice,
      frameGlassName: this.props.frameGlassName,
      cropAttributes: this.props.cropAttributes,
      quantity: this.props.quantity,
      totalPrice: this.props.totalPrice
    };
    this.makeClientCrop = this.makeClientCrop.bind(this);
    this.checkForCroppedImage = this.checkForCroppedImage.bind(this);
    this.getLabOrder = this.getLabOrder.bind(this);
  }

  componentDidMount() {
    //let coverPhoto = new Image()

    //coverPhoto.src = this.props.coverPhotoUrl
    //coverPhoto.crossOrigin = 'anonymous'

    //console.log("env " + process.env.REACT_APP_CURRENT_ENV)
    //console.log("env is dev " + (process.env.REACT_APP_CURRENT_ENV === 'develop'))
    //if (process.env.REACT_APP_CURRENT_ENV === 'develop') {
      //coverPhoto.setAttribute('crossorigin', 'anonymous')
      ////crossOrigin = "anonymous"
    //} else {
      ////coverPhoto.setAttribute('crossorigin', 'anonymous')
      ////coverPhoto.crossOrigin = process.env.REACT_APP_PUBLIC_URL
      ////let envUrl = process.env.REACT_APP_PUBLIC_URL
      //let envUrl = 'anonymous'
      ////let envUrl = 'use-credentials'
      //coverPhoto.setAttribute('crossorigin', 'anonymous')
    //}

    //coverPhoto.onload = () => {
      //this.makeClientCrop(coverPhoto)
    //}

  }

  throwError(err) {
    let errorMessage
    if (err.response != null) {
      let errorObject = err.response.data.error
      errorMessage = "\nCODE: " + errorObject.status + "\nERROR CLASS: " + errorObject.class_name + "\nERROR MESSAGE: " + errorObject.developer_message
      if (err.response.data.error.more_info != null){
        console.log("ERROR MESSAGE: " + errorMessage + "\nMORE INFO: " + err.response.data.error.more_info.join(" ,").split(",").join("\n"))
      } else {
        console.log(errorMessage + " More info not available.")
      }
    } else {
      errorMessage = err.stack
    }

    this.setState({
      loading: false,
      selections: [],
      errorState: true,
      addingToCart: false,
      errorMessage: errorMessage
    })
  }


  //<img anima-src={croppedImageUrl} alt="line item" className="lineItemPhoto" src={croppedImageUrl}/>

  async makeClientCrop(coverPhoto) {
    //call the methods that will create a 64-bit version of thumbnail here.
    var imgObject = coverPhoto
    let fileName = "croppedLineItem.jpg"
    const croppedImg = await this.getCroppedImg(imgObject, this.props.cropAttributes, fileName);
    //console.log("croppedImg URL is " + URL.createObjectURL(croppedImg))

    this.setState({
      croppedImageUrl: URL.createObjectURL(croppedImg)
    })
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    //const scaleX = image.naturalWidth / canvas.width;
    //const scaleY = image.naturalHeight / canvas.height;
    let startXcoord = (crop.x / 100) * image.naturalWidth //* scaleX
    let startYcoord = (crop.y / 100) * image.naturalHeight //* scaleY
    let croppedHeight = (crop.height / 100) * image.naturalHeight // * scaleY
    let croppedWidth = (crop.width / 100) * image.naturalWidth //* scaleX

    let cropHeight = (crop.height / 100) * image.naturalHeight
    let cropWidth = (crop.width / 100) * image.naturalWidth

    //console.log("xcoord " + startXcoord)
    //console.log("ycoord " + startYcoord)
    //console.log("height " + croppedHeight)
    //console.log("width " + croppedWidth)

    canvas.width = cropWidth
    canvas.height = cropHeight

    ctx.drawImage(
      image,
      startXcoord,
      startYcoord,
      croppedWidth,
      croppedHeight,
      0,
      0,
      cropWidth,
      cropHeight
    );

    // As Base64 string
     //const base64Image = canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        blob.name = fileName;
        resolve(blob);
      }, 'image/jpeg');
    });
  }

  componentWillReceiveProps(nextProps) {
    if(this.props !== nextProps) {
      this.setState({
        lineItem: nextProps.lineItem,
        changeQty: nextProps.changeQty,
        editDisabled: nextProps.editDisabled,
        index: nextProps.index,
        isReviewScreen: nextProps.isReviewScreen,
        updating: false,
        userIsAdmin: nextProps.userIsAdmin,
        labOrderGuid: nextProps.labOrderGuid,
        guid: nextProps.guid,
        lineItemId: nextProps.lineItemId,
        coverPhotoUrl: nextProps.coverPhotoUrl,
        croppedPhotoUrl: nextProps.croppedPhotoUrl,
        imageName: nextProps.imageName,
        externalId: nextProps.externalId,
        notes: nextProps.notes,
        adminNotes: nextProps.adminNotes,
        printSizeGuid: nextProps.printSizeGuid,
        printBorder: nextProps.printBorder,
        printBorderRaw: nextProps.printBorderRaw,
        paper: nextProps.paper,
        paperGuid: nextProps.paperGuid,
        printPrice: nextProps.printPrice,
        finishingCategory: nextProps.finishingCategory,
        finishingServiceGuid: nextProps.finishingServiceGuid,
        finishingServiceDetailGuid: nextProps.finishingServiceDetailGuid,
        finishingDetailOne: nextProps.finishingDetailOne,
        finishingDetailTwo: nextProps.finishingDetailTwo,
        finishingDetailThree: nextProps.finishingDetailThree,
        finishingPrice: nextProps.finishingPrice,
        frameGlassName: nextProps.frameGlassName,
        cropAttributes: nextProps.cropAttributes,
        quantity: nextProps.quantity,
        totalPrice: nextProps.totalPrice
      });
    }
  }

  setLoadingAndChangeQty(action, quantity, guid) {
    this.setState({
      updating: true
    })
    this.props.changeQty(action, quantity, guid)
    //this.props.changeQty("increase", quantity, guid)
  }

  async checkForCroppedImage(coverPhotoUrl) {
    console.log("checking for crop function")
    if (this.state.checksForCroppedImageCount <= 1 && coverPhotoUrl && !coverPhotoUrl.includes("cropped")) {
      await this.waitSeconds(4000)
      this.getLabOrder()
    }
    return true
  }

  waitSeconds(ms) {
    var start = new Date().getTime();
    var end = start;
    while(end < start + ms) {
      end = new Date().getTime();
    }
  }

  getLabOrder = (e, data) => {
    //e.preventDefault();
    var token = Auth.getToken()
    var guid = this.state.labOrderGuid
    //console.log("guid is: " + this.state.labOrderGuid)
    axios
    .get(`api/v2/lab_orders/${guid}`, {headers: {'token': token}})
    .then(res => {
      let museaApi = new MuseaApi()
      var parsedOrder = museaApi.mergeAttributesForLabOrder(res.data);
      //console.log("parsedOrder line items: " + parsedOrder.lineItems)
      /**
       * TODO changed this
       * var lineItem = parsedOrder.lineItems.filter(li => li.guid == this.state.guid)
       */
      var lineItem = parsedOrder.lineItems.filter(li => li.guid === this.state.guid)
      if (lineItem.length > 0) {
        //console.log("line item found")
        console.log("line item photoUrl " + lineItem[0].photoUrl)
        if (lineItem[0].photoUrl.includes("cropped")) {
          //console.log("found cropped line item, setting state")
          this.setState({
            coverPhotoUrl: lineItem[0].photoUrl,
            checkingForCrop: false
          });
        } else {
          //console.log("lab order did not have cropped photoUrl continuing cycle")
          let checksCount
          if (this.state.checksForCroppedImageCount) {
            checksCount = this.state.checksForCroppedImageCount + 1
          } else {
            checksCount = 1
          }

          if (this.state.checksForCroppedImageCount <= 1) {
            this.setState({
              checksForCroppedImageCount: checksCount,
              checkingForCrop: true
            });
            //this.checkForCroppedImage(this.state.coverPhotoUrl)
          } else {
            this.setState({
              checksForCroppedImageCount: checksCount,
              checkingForCrop: false
            });
          }
        }
      } else {
        //console.log("no line item after filter")
      }
        return true;
    })
    .catch(err => {
      console.log("error checking for cropped image: " + err)
    })
  };

  async imageRender(coverPhotoUrl, croppedPhotoUrl, checksForCroppedImageCount) {
    let photoUrl, imageSrcSet
    if (croppedPhotoUrl && croppedPhotoUrl !== null ) {
      console.log("photoUrl is being set to " + croppedPhotoUrl)
      // console.log("setting cover photo ln295 as: " + coverPhotoUrl)
      photoUrl = croppedPhotoUrl;
      imageSrcSet = `${croppedPhotoUrl} 500w`
    } else if (coverPhotoUrl && coverPhotoUrl !== null && coverPhotoUrl.length > 0) {
      console.log("photo url in line item summary: " + coverPhotoUrl)
      console.log("checks for cropped image count: " + checksForCroppedImageCount)
      // console.log("setting cover photo ln300 as: " + coverPhotoUrl)
      photoUrl = coverPhotoUrl;
      imageSrcSet = `${coverPhotoUrl} 500w`

      if (!coverPhotoUrl.includes("cropped") && checksForCroppedImageCount <= 1 && this.state.isReviewScreen) {
        console.log("checking for crop after setting photoUrl")
        this.checkForCroppedImage(this.state.coverPhotoUrl)
      }
      // console.log("setting cover photo ln307 as: " + coverPhotoUrl)
      photoUrl = coverPhotoUrl;
      imageSrcSet = `${coverPhotoUrl} 500w`
    } else {
      console.log("photoUrl is nophoto")
      // console.log("setting cover photo ln314 as: " + coverPhotoUrl)
      photoUrl = noPhoto;
      imageSrcSet = `${noPhoto} 500w`
    }

    let lineItemImage
    if (photoUrl.includes("cropped")) {
      lineItemImage = <div id="w-line-item-image" className="div-block-16">
      <img src={photoUrl}
      srcSet={imageSrcSet}
      style={{maxWidth: '160px'}}
      alt=""
      className="image-39"/>
      </div>
    } else if (this.state.checkingForCrop) {
      lineItemImage = <div id="w-line-item-image" style={{textAlign: 'center', fontWeight: '600', marginTop: '20px'}} className="div-block-16">
      checking for cropped image. could take up to 15 seconds...
          </div>
    } else {
      lineItemImage = <div id="w-line-item-image" className="div-block-16">
      <a data-for='image' data-tip="React-tooltip">
        <img src={photoUrl}
      srcSet={imageSrcSet}
      style={{maxWidth: '160px'}}
      alt=""
      className="image-39"/>
      </a>
      <ReactTooltip id='image' place="bottom" type="dark" effect="float">
      <span>If image crop is not present on initial load <br/> please reload after a few seconds <br/> as the cropped image might still be processing. </span>
        </ReactTooltip>
      </div>
    }
    return lineItemImage
  }

  setupLineItem() {
  }



  render() {
    let {updating, editDisabled, lineItem, index, isReviewScreen, guid, labOrderGuid, coverPhotoUrl, croppedPhotoUrl, notes, printSize, printSizeGuid, printBorder, paper, paperGuid, finishingCategory, imageName, photoGuid, finishingServiceGuid, frameGlassName, finishingServiceDetailGuid, finishingDetailOne, finishingDetailTwo, finishingDetailThree, quantity, totalPrice, checksForCroppedImageCount} = this.state
    let editLink, fullDetail
    console.log("lineItem: " + lineItem)
    console.log("lineItem cropped: " + lineItem.photograph_cropped_url)

    if (finishingCategory && finishingCategory.toLowerCase().includes("frame") && frameGlassName !== null) {
      if (finishingDetailTwo !== null) {
        fullDetail = finishingDetailTwo + " " + finishingDetailOne + " on " + frameGlassName
      } else {
        fullDetail = finishingDetailOne + " on " + frameGlassName
      }
    } else if (finishingCategory && finishingDetailOne !== null) {
      fullDetail = finishingDetailOne
      if (finishingDetailTwo !== null) {
      fullDetail = fullDetail + " " + finishingDetailTwo
      }
    } else {
      fullDetail = ""
    }


    if (this.state.editDisabled) {
      editLink = <div></div>
    } else {
      editLink = <Link to={{
                pathname: `/lab-orders/${labOrderGuid}/edit/order-builder/line-item/${guid}`,
                state: {
                  lineItemGuid: `${guid}`,
                  lineItem: lineItem,
                  labOrderGuid: `${labOrderGuid}`,
                  cropOptions: this.state.lineItem.cropOptions,
                  notes: notes,
                  selections: [photoGuid],
                  products: this.state.products,
                  paperOptions: this.state.paperOptions,
                  paperPricing: `${paperGuid}`,
                  paperPricingName: `${paper}`,
                  printSizePricing: `${printSizeGuid}`,
                  printSizePricingName: `${printSize}`,
                  finishingCategory: `${finishingCategory}`,
                  finishingCategoryName: `${finishingCategory}`,
                  finishingPricing: `${finishingServiceGuid}`,
                  frameGlassName: `${frameGlassName}`,
                  finishingDetailPricing: `${finishingServiceDetailGuid}`,
                  printBorder: printBorder
                }
              }}>
                <div className="bold-text-5">
                  <strong>
                    EDIT
                  </strong>
                </div>
              </Link>
    }

    //if (this.state.printSizeGuid !== null) {
      //finishingServices = MuseaAPI.getFinishingServices(this.state.printSizeGuid)
      //finishingServiceOptions = MuseaAPI.mergeAttributesForFinishingOptions(finishingServices)
    //}

    //if (finishingCategory === "frame") {
      //finishingDetailOne = lineItem.frameProfile
    //}

    //if (printBorder) {
      //printBorderValue = printBorder
    //} else {
      //printBorderValue = "no border"
    //}

    //if (croppedImageUrl != null && croppedImageUrl.length > 0) {
      //photoUrl = croppedImageUrl;

    let lineItemImage
    let photoUrl, imageSrcSet
    this.imageRender(coverPhotoUrl, croppedPhotoUrl, checksForCroppedImageCount)

    // console.log("croppedPhotoUrl ln416 is: " + croppedPhotoUrl)
    // console.log("coverPhotoUrl ln417 is: " + coverPhotoUrl)

    photoUrl = coverPhotoUrl;
    imageSrcSet = `${coverPhotoUrl} 500w`
    lineItemImage = <div id="w-line-item-image" className="div-block-16">
      <a data-for='image' data-tip="React-tooltip">
        <img src={photoUrl}
      srcSet={imageSrcSet}
      style={{maxWidth: '160px'}}
      alt=""
      className="image-39"/>
      </a>
      <ReactTooltip id='image' place="bottom" type="dark" effect="float">
      <span>If image crop is not present on initial load <br/> please reload after a few seconds <br/> as the cropped image might still be processing. </span>
        </ReactTooltip>
      </div>

    let notesString, notesColor, notesDecor;
    if (notes != null && notes.length > 0) {
      notesString = notes
      notesColor = 'red'
      notesDecor = '700'
    } else {
      notesString = "none"
      notesColor = 'black'
      notesDecor = '400'
    }

    //let downloadOption
    //if (userIsAdmin) {
      //downloadOption = <div>
        //<a>
          //<img anima-src={downloadCloud} alt="download cloud" className="outlineclouddownloadblack48dp" src={downloadCloud}/>
        //</a>
      //</div>
    //} else {
      //downloadOption = <div></div>
    //}

    //console.log("qtyNoEdit is  " + this.props.qtyNoEdit)
    //console.log("canDeleteItem is  " + this.props.canDeleteItem)
    let qtyArrows, qtyColor, qtyDecor
    if (this.props.qtyNoEdit) {
      if (quantity > 1) {
        console.log("qty arrows are off and RED")
        qtyColor = 'red'
        qtyDecor = '700'
        qtyArrows = <div></div>
      } else {
        //console.log("qty arrows are off")
        qtyColor = 'black'
        qtyDecor = '400'
        qtyArrows = <div></div>
      }
    } else if (quantity > 1) {
      //console.log("qty is over one " + quantity)
      qtyColor = 'red'
      qtyDecor = '700'
      qtyArrows = <div style={{marginTop: '-9px', marginLeft: '1px'}}>

        <img anima-src={increaseQtyArrow}
        onClick={() => this.setLoadingAndChangeQty("increase", quantity, guid)}
        alt="increase qty arrow"
        className="increaseQtyArrow"
        style={{cursor: 'pointer'}}
        src={increaseQtyArrow}/>
        <br/>
        <img anima-src={decreaseQtyArrow}
        onClick={() => this.setLoadingAndChangeQty("decrease", quantity, guid)}
        alt="decrease qty arrow"
        className="decreaseQtyArrow"
        style={{cursor: 'pointer'}}
        src={decreaseQtyArrow}/>
      </div>
    } else {
      qtyColor = 'black'
      qtyDecor = '400'
      qtyArrows = <div style={{marginTop: '-9px', marginLeft: '1px'}}>
        <img anima-src={increaseQtyArrow}
        onClick={() => this.setLoadingAndChangeQty("increase", quantity, guid)}
        alt="increase qty arrow"
        className="increaseQtyArrow"
        style={{cursor: 'pointer'}}
        src={increaseQtyArrow}/>
      </div>
    }

    let deleteIcon
    if (this.props.canDeleteItem) {
      deleteIcon = <div style={{marginTop: '20px'}}>
        <img anima-src={deleteIconCan}
        alt="delete icon"
        className="deleteIcon"
        style={{cursor: 'pointer'}}
        onClick={() => this.props.deleteLineItem(guid)}
        src={deleteIconCan}/>
      </div>
    } else {
      deleteIcon = <div></div>
    }


    if (updating) {
      return (
        <div className="grid-6">
          <div id="w-line-item-index">
            <div className="text-block-66">
              {index}
            </div>
          </div>
          <div id="w-line-item-image" style={{marginTop: '50%', fontSize: '14pt', fontWeight: '600'}} className="div-block-16">
            updating...
          </div>
        </div>
      )
    } else {
      return (
        <div className="grid-6">
          <div id="w-line-item-index">
            <div className="text-block-66">
              {index}
            </div>
          </div>

          {lineItemImage}

          <div id="w-line-item-print-details" className="div-block-17">
            <div className="text-block-67">
              <strong className="bold-text-5">
                PRINT DETAILS
               </strong>
               <br/>
               {paper.toUpperCase()} {printSize.toUpperCase()}
               <br/>
               {imageName}
               <br/>

               {editLink}
            </div>
          </div>


          <div id="w-line-item-finish-category" className="div-block-20">
            <div className="text-block-69">
              <strong className="bold-text-8">
                FINISH TYPE
              </strong>
              <br/>
              {finishingCategory}
            </div>
          </div>

          <div id="w-line-item-finish-details">
            <div className="text-block-71">
              <strong className="bold-text-7">
                DETAILS
              </strong>
              <strong className="bold-text-6">
                {fullDetail}
                <br/>
                {finishingDetailThree}
              </strong>
            </div>
          </div>

          <div id="w-line-item-notes" className="div-block-22">
            <div className="text-block-72">
              <strong>
                NOTES
                <br/>
              </strong>
              <span style={{color: notesColor, fontWeight: notesDecor}}> {notesString} </span>
            </div>
          </div>

          <div id="w-line-item-qty">
            <div className="text-block-84" style={{color: qtyColor, fontWeight: qtyDecor}}>
              QTY
            </div>
            <div className="text-block-74"  style={{display: 'inline-flex',
              marginLeft: '45%',
              marginTop: '5px' }}>
              <span style={{color: qtyColor, fontWeight: qtyDecor}}>{quantity}</span>
              {qtyArrows}
            </div>
          </div>

          <div id="w-line-item-total" className="div-block-31">
            <div className="text-block-75">
              TOTAL
            </div>
            <div className="text-block-76">
              ${totalPrice}
            </div>
          </div>

          <div id="w-line-item-delete" className="div-block-31">
            {deleteIcon}
          </div>

        </div>
        )
    }
  }
}

export default LineItemSummary;
