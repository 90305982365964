import React, { Component } from "react";
import CopyToClipboard from 'react-copy-to-clipboard';
import { Link, Navigate } from "react-router-dom";
import downloadBlack from "./img/outline_cloud_download_black_48dp.png";

import Honeybadger from '@honeybadger-io/js'
import ErrorBoundary from '@honeybadger-io/js'

import NavBar from "./components/NavBar.js";
import Auth from "./modules/Auth";
import axios from "axios";
import validatedCheck from "./img/sign-up-1-v2-baselinecheckcircleblack48dp-copy-green@2x.png";

class PhotographerProfile extends Component {
  constructor(props) {
    super(props);
    const hb_config = {
      api_key: process.env.REACT_APP_HB_KEY,
      environment: process.env.REACT_APP_CURRENT_ENV,
      revision: 'current'
    }

    this.honeybadger = Honeybadger.configure(hb_config)

    if (this.props.location.state) {
      props = this.props.location.state
    } else {
      props = this.props
    }

    this.state = {
      errorState: false,
      referredPhotographers: [],
      photographersReferred: 0,
      referredPhotographersLink: '',
      updatingUser: false,
      referralCodeValidated: false,
      referralCodeError: false,
      newReferralCode: '',
      // Adding referral
      creditErrorState: false,
      // creditReferredSelected: true,
      creditReferralCodeSubmitted: false,
      creditReferralCodeValidated: false,
      creditReferralCodeError: false
      
    };
    this.validateReferralCode = this.validateReferralCode.bind(this);
    this.throwError = this.throwError.bind(this)
    this.getReferrals = this.getReferrals.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)


    // Adding referral
    this.creditValidateReferralCode = this.creditValidateReferralCode.bind(this);
    this.creditThrowError = this.creditThrowError.bind(this)
    this.creditReferralHandleSubmit = this.creditReferralHandleSubmit.bind(this)
    
  }


  creditValidateReferralCode(code) {
    axios
      .post('/api/v2/check_referral_code', { code: code }, { headers: {} })
      .then(res => {
        //console.log(result);
        this.setState({
          creditReferralCodeValidated: true,
          referredByCode: code
        });
      })
      .catch(err =>
        //console.log(result);
        this.setState({
          creditReferralCodeValidated: false,
          creditReferralCodeError: true
        })
      )
  }

  creditReferralHandleSubmit(e) {
    e.preventDefault()
    var token = Auth.getToken()
    console.log("hitting endpoint /api/v2/update_new_user")
    axios
      .patch('/api/v2/update_new_user', { code: this.state.referredByCode }, { headers: { 'token': token } })
      .then(res => {
        window.alert("You successfully validated your referral code!")
        this.setState({
          creditReferralCodeSubmitted: true
        })
      })
      .catch(err => {
        window.alert("Please try again!")
        this.throwError(err)
      }
      )
  }

  creditThrowError(err) {
    let errorObject = err.response.data.error
    let errorMessage = "\nERROR CODE: " + errorObject.status + "\n ERROR CLASS: " + errorObject.class_name + " \nERROR MESSAGE: " + errorObject.developer_message
    console.log("ERROR MESSAGE: " + errorMessage + "\n MORE INFO: " + err.response.data.error.more_info.join(" ,").split(",").join("\n"))
    //alert(errorMessage)

    this.setState({
      loading: false,
      errorState: true,
      errorMessage: errorMessage
    })
  }

  // 

  componentDidMount() {
    this.getPhotographerProfile()
    //onClick={(e) => {this.handleSubmit(e)}}
  }

  getPhotographerProfile(){
    var token = Auth.getToken()
    axios.get(`/api/v2/photographers/${this.props.location.pathname["split"]("/")[2]}`, {headers: {token: token}})
    .then(res => {
      console.log(res);
    }).catch(err => alert(err))
  }

  showCopiedText() {
    let referralUrl = "" + process.env.REACT_APP_PUBLIC_URL + "/refer/" + this.state.user.data.attributes.referral_code
    alert("Copied Link to Clipboard: " + referralUrl)
  }

  getReferrals() {
    var token = Auth.getToken()
    let url = `/api/v2/accounts/referrals`
    axios
      .get(url, { headers: { token: token } })
      .then(res => {
        console.log(res.data.referrals);
        this.setState({
          referredPhotographers: res.data.referrals
        });
      })
      .catch(err =>
        alert(err)
      )
  }

  handleChange(e) {
    const name = e.target.name;
    const val = e.target.value;
    if (name === "referralCode" && val.length >= 3) {
      this.validateReferralCode(val)
    } else {
      this.setState({
        [name]: val
      });
    }
    //console.log(this.state);
  }

  validateReferralCode(code) {
    axios
      .post('/api/v2/check_referral_code', { code: code }, { headers: {} })
      .then(res => {
        this.setState({
          referralCodeValidated: false,
          referralCodeError: true
        })
      })
      .catch(err => {
        console.log("validate referral code", code)
        this.setState({
          referralCodeValidated: true,
          referralCode: code
        })
      })
  }

  throwError(err) {
    let errorMessage, errorObject, stackTrace
    if (err.response && err.response.status === 401) {
      this.setState({
        userLoggedOut: true
      })
      return false
    } else {
      if (err.response != null) {
        errorObject = err.response.data.error
        if (errorObject != null) {
          errorMessage = "\nERROR CODE: " + errorObject.status + "\n ERROR CLASS: " + errorObject.class_name + " \nERROR MESSAGE: " + errorObject.developer_message
          if (err.response.data.error.more_info != null) {
            this.honeybadger.notify(err)
            alert("ERROR MESSAGE: " + errorMessage)
            console.log("ERROR MESSAGE: " + errorMessage + "\nMORE INFO: " + err.response.data.error.more_info.join(" ,").split(",").join("\n"))
          } else {
            this.honeybadger.notify(err)
            alert("ERROR MESSAGE: " + errorMessage)
            console.log(errorMessage + " More info not available.")
          }
        } else {
          errorMessage = "\nERROR CODE: " + err.response
        }
      } else {
        // this.state.honeybadger.notify(err)
        this.honeybadger.notify(err)
        alert("ERROR MESSAGE: " + err.stack)
        errorMessage = err.stack
      }
    }

    this.setState({
      loading: false,
      query: "",
      updatingUser: false,
      errorState: true,
      errorMessage: errorMessage
    })
  }

  handleSubmit(e) {
    e.preventDefault()
    this.setState({ updatingUser: true })
    var token = Auth.getToken()
    console.log("Handle submit", this.state.guid)
    axios
      .patch(`/api/v2/users/${this.state.guid}`,
        {
          referral_code: this.state.referralCode,
        }, { headers: { token: token } })
      .then(res => {
        this.setState({
          updatingUser: false,
          user: res.data.user,
          photographersReferred: res.data.user.data.attributes.referred_users,
          guid: res.data.user.data.attributes.guid,
        });
      })
      .catch(err => {
        this.throwError(err)
      })
  }


  formattedReferredPhotographersReport(referredPhotographers) {
    if (referredPhotographers !== null && referredPhotographers.length > 0) {
      const formattedPhotographers = referredPhotographers.map(obj => {
        return <div key={obj.toString()} className="grid-4" style={{ marginTop: "1em", gridRowGap: "3px" }}>
          <div id="w-node-referral-name" className="text-block-41">{obj.name}</div>
          <div id="w-node-referral-business-name" className="text-block-44">{obj.business_name}</div>
          <div id="w-node-referral-email" className="text-block-47">{obj.email}</div>
          <div id="w-node-referral-credit-earned" className="text-block-50">${parseFloat(+obj.credit_earned).toFixed(2)}</div>
        </div>

      })
      return formattedPhotographers
    } else {
      return <div></div>
    }
  }

  render() {
    let creditAvailable, referralUrl, referralCode, currentlyLinkedToAccount,
      saveButton, referralCodeErrorField, codeValidImage, referredPhotographersLink
    const { referredPhotographers, photographersReferred, referralCodeValidated, referralCodeError, updatingUser, user } = this.state

    // Adding referral
    let referredByForm, creditUpdateUserButton, creditReferralCodeField, creditReferralCodeErrorField, creditCodeValidImage, creditReferredSelectedOptionValue, creditReferralSuccess
    
    if (this.state.referralCodeSubmitted) {
        return <Navigate push to={{ pathname: "/signup/3-of-3", state: {} }} />;
    }

    if (this.state.creditReferralCodeValidated) {
      creditReferralCodeErrorField = <div></div>
      creditCodeValidImage = "url('" + validatedCheck + "')"

    } else if (this.state.creditReferralCodeError) {
      creditReferralCodeErrorField = <p className="w-form-fail"
        style={{
          marginTop: '5px',
          color: 'red'
        }}>

        This referral code does not exist.
              </p>
      creditCodeValidImage = ""
    } else {
      creditReferralCodeErrorField = <div></div>
      creditCodeValidImage = ""
    }

    if (this.state.creditReferralCodeValidated) {
      creditUpdateUserButton = <input type="submit"
        onClick={(e) => { this.creditReferralHandleSubmit(e) }}
        className="button-15 w-button"
        value="SUBMIT" />

    } else {
      creditUpdateUserButton = <input type="submit"
        className="button-15 w-button"
        disabled
        style={{ opacity: '0.5' }}
        value="SUBMIT" />
      creditReferredSelectedOptionValue = "YES"
    }

    creditReferralCodeField = <input type="text"
      className="text-field-19 w-input"
      name="referredByCode"
      onChange={this.creditReferralHandleChange}
      style={{ width: '450px', background: creditCodeValidImage + ' 98% 50% /20px no-repeat' }}
      placeholder="ENTER REFERRAL CODE" />

    if (user) {
      creditAvailable = user.data.attributes.credit_available
      referralUrl = "" + process.env.REACT_APP_PUBLIC_URL + "/refer/" + user.data.attributes.referral_code
      referralCode = user.data.attributes.referral_code
      referredPhotographersLink = user.data.attributes.referral_report_link
      if (user.data.attributes.referred_by_email && user.data.attributes.referred_by_email !== null) {
        currentlyLinkedToAccount = user.data.attributes.referred_by_email
      } else {
        currentlyLinkedToAccount = "NO REFERRAL PHOTOGRAPHER"
      }

    } else {
      creditAvailable = 0.00
      referralUrl = ''
      referralCode = ''
      referredPhotographersLink = ''
      currentlyLinkedToAccount = ""
    }


    let referralReportDownload
    if (referredPhotographersLink && referredPhotographersLink !== null && referredPhotographersLink.length > 0) {
      referralReportDownload = <span > <a download href={referredPhotographersLink}
        style={{ color: 'black' }}>
        <img alt="logo"
          anima-src={downloadBlack}
          style={{ marginLeft: '15px' }}
          className="image-15"
          src={downloadBlack} />
      </a>
      </span>
    } else {
      referralReportDownload = <div></div>
    }

      if (referralCodeValidated) {
      referralCodeErrorField = <div></div>
      codeValidImage = "url('" + validatedCheck + "')"
      saveButton = <input type="submit"
        value="SAVE"
        onClick={(e) => { this.handleSubmit(e) }}
        style={{
          opacity: '1.0',
          marginTop: '20px'
        }}
        className="button-9 w-button" />

    } else if (referralCodeError) {
      referralCodeErrorField = <p className="w-form-fail"
        style={{
          marginTop: '5px',
          display: 'block',
          textAlign: 'center',
          color: 'red'
        }}>

        This referral code is already in use.
              </p>
      codeValidImage = ""
      saveButton = <input type="submit"
        value="SAVE"
        style={{
          opacity: '0.5',
          pointerEvents: 'none',
          cursor: 'default',
          marginTop: '20px'
        }}
        className="button-9 w-button" />

    } else {
      referralCodeErrorField = <div></div>
      codeValidImage = ""
      saveButton = <input type="submit"
        value="SAVE"
        style={{
          opacity: '0.5',
          pointerEvents: 'none',
          cursor: 'default',
          marginTop: '20px'
        }}
        className="button-9 w-button" />

    }if (!this.state.creditReferralCodeSubmitted) {
      referredByForm = <div>
        <div className="container-4 w-container">
          <div className="text-block-20">
            <strong className="bold-text-3">
              Were you referred by another photographer?
                  </strong>
          </div>
          <div className="container-5 w-container">
            <div className="w-form" style={{ textAlign: 'center' }}>
              <div id="email-form-2"
                className="form-7" >
                {creditReferralCodeField}
                {creditReferralCodeErrorField}
                {creditReferralSuccess}

              </div>
            </div>
          </div>

          <div className="w-container">
            {creditUpdateUserButton}
          </div>
        </div>
        <br></br>
      </div>

    }

    if (updatingUser) {
      return (
        <div>
          <NavBar />
          <div className="CreditBalance">
            <div className="container-11 w-container">
              <div className="text-block-32">
              </div>
            </div>

            <div className="container-13 w-container">
              <div className="text-block-34 text-block-35">
                updating user...
            </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <NavBar />
          <div className="CreditBalance">

            <div className="container-11 w-container">
              <div className="text-block-32">
                CREDIT ${parseFloat(+creditAvailable).toFixed(2)}
                <span style={{marginLeft: '15px'}}>  PHOTOGRAPHERS REFERRED   {photographersReferred} </span>
              </div>
            </div>

            <div className="container-11 w-container">
              <div className="text-block-32">
                CURRENTLY REFERRED BY:
                <span style={{marginLeft: '15px'}}>  {currentlyLinkedToAccount} </span>
              </div>
            </div>
            {referredByForm}
            <div className="container-3 w-container">
              <div className="text-block-34 text-block-35">
                YOUR REFERRAL CODE
            </div>


              <div className="w-form">
                <form id="email-form-2" name="email-form-2" data-name="Email Form 2">

                  <input type="text"
                    className="text-field-23 w-input"
                    name="referralCode"
                    onChange={this.handleChange}
                    style={{ background: codeValidImage + ' 98% 50% /20px no-repeat' }}
                    placeholder={referralCode} />

                  {saveButton}
                  {referralCodeErrorField}
                </form>
              </div>
            </div>


            <div className="container-12 w-container">
              <div className="text-block-33">REFERRAL URL</div>
              <div className="w-form">
                <input type="text"
                  disabled
                  className="text-field-22 w-input"
                  value={referralUrl}
                  name="referralUrl" />

                <CopyToClipboard text={referralUrl}
                  onCopy={() => this.showCopiedText()}>
                  <div style={{ textAlign: 'center' }} className="submit-button-4 w-button">COPY LINK </div>
                </CopyToClipboard>

              </div>
            </div>

            <div className="div-block-13">
              <div className="text-block-36">
                REFERRAL REPORT {referralReportDownload}
              </div>
            </div>

            <div>

              <div className="grid-4" style={{ gridRowGap: "3px" }}>
                <div className="text-block-37">
                  Name
                </div>
                <div className="text-block-38">
                  Business Name
                </div>
                <div className="text-block-39">
                  E-Mail
                </div>
                <div className="text-block-40">
                  Credit Earned
                </div>
              </div>
              {this.formattedReferredPhotographersReport(referredPhotographers)}

            </div>

          </div>
        </div>
      );
    }
  }
}

export default PhotographerProfile;
