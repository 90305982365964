import React, { Component } from "react";
import { Tooltip as ReactTooltip } from 'react-tooltip';
//import ImageLoader from "./ImageLoader.js";
import noPhoto from "../img/end-photo.png";

class ImageGutter extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loadingImages: true,
      images: this.props.labOrder.images,
      lineItems: this.props.labOrder.lineItems,
      inCart: this.props.inCart,
      selections: this.props.selections,
      errorState: false
    };
    this.formattedImages = this.formattedImages.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      //console.log("nextProps " + nextProps)
      this.setState({
        selections: nextProps.selections,
        images: nextProps.labOrder.images,
        lineItems: nextProps.labOrder.lineItems,
        loadingImages: nextProps.loadingImages,
        inCart: nextProps.inCart,
        labOrder: nextProps.labOrder
      });
    }
  }

  truncateImageName(photoFileName) {
    let remainingCharacters, finalTrunc, firstTrunc, truncatedName, photoFileNameNoExtension
    //{image.photo_file_name.substring(0,7)}..{image.photo_file_name.substring(image.photo_file_name.length-7, image.photo_file_name.length)
    photoFileNameNoExtension = photoFileName.split(".")[0]
    if (photoFileNameNoExtension && photoFileNameNoExtension.length > 0) {
      remainingCharacters = photoFileNameNoExtension.length - 4

      firstTrunc = photoFileNameNoExtension.substring(0, 4)

      if (remainingCharacters > 0 && remainingCharacters >= 10) {
        finalTrunc = ".." + photoFileNameNoExtension.substring(photoFileNameNoExtension.length - 10, photoFileNameNoExtension.length)
      } else if (remainingCharacters > 0 && remainingCharacters <= 10) {
        finalTrunc = photoFileNameNoExtension.substring(photoFileNameNoExtension.length - remainingCharacters, photoFileNameNoExtension.length)
      } else {
        finalTrunc = ""
      }

      if (finalTrunc && finalTrunc.length > 0) {
        truncatedName = firstTrunc + finalTrunc
      } else {
        truncatedName = firstTrunc
      }
    } else {
      truncatedName = "NO FILE NAME"
    }
    return truncatedName
  }

  componentDidMount() {
  }


  formattedImages(images, selections, inCart) {
    let sortedImages = this.sortNames(images);
    let imageContainers = sortedImages.map((image, index) => {
      document.documentElement.style.setProperty("--colNum", (index + 3));
      let truncatedImageName = this.truncateImageName(image.photo_file_name)
      let imageSrc
      if (image.smallest_photo && image.smallest_photo.length > 0) {
        imageSrc = image.smallest_photo
      } else {
        imageSrc = image.presigned_photo_url
      }

      if (image != null && inCart != null && inCart.includes(image.guid)) {
        return <div key={image.guid}>
          <div id="loaded-image" className="imageContainer"
            style={{
              display: 'inline-grid',
              position: 'relative',
              background: 'radial-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5))'
            }}
            onClick={() => { this.props.updateSelections("selectImage", image.guid, selections) }}>
            <div className="text-block-163" style={{
              position: 'absolute',
              color: 'white',
              fontSize: '12px',
              fontWeight: '700',
              zIndex: '999',
              //marginLeft: '1.5em',
              width: '120px',
              textAlign: 'center !important',
              //marginTop: '3em'
            }}>
              <div>
                ADDED TO CART
                </div>
            </div>
            <div >
              <div className="gutterPhoto"
                style={{
                  background: "linear-gradient(180deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url(" + imageSrc + ")"
                }} >
                <img anima-src={imageSrc}
                  className="gutterPhoto"
                  style={{
                    opacity: '0.4'
                  }}
                  alt={image.photo_file_name}
                  src={imageSrc} />

              </div>
            </div>

          </div>
          <div className="text-block-163" style={{ fontSize: '9pt', fontWeight: '600', textTransform: 'uppercase' }}>
            <a data-for={image.guid} data-tip="React-tooltip">
              {truncatedImageName}
            </a>
            <ReactTooltip id={image.guid} place="bottom" type="dark" effect="float">
              <span>{image.photo_file_name}</span>
            </ReactTooltip>
          </div>
        </div>
      } else if (image != null && selections.includes(image.guid)) {

        if (image != null && inCart != null && inCart.includes(image.guid)) {
          return <div key={image.guid}>
            <div id="loaded-image" className="imageContainer"
              style={{ display: 'inline-grid', position: 'relative' }}
              onClick={() => { this.props.updateSelections("selectImage", image.guid, selections) }} >
              <div className="text-block-163 imageName" style={{
                position: 'absolute',
                //marginLeft: '3em',
                width: '120px',
                //marginTop: '5em'
              }}>
              </div>
              <div>
                <img anima-src={imageSrc}
                  className="gutterPhoto"
                  style={{
                    opacity: '0.5'
                  }}
                  alt={image.photo_file_name}
                  src={imageSrc} />
              </div>
            </div>
            <div className="text-block-163" style={{ fontSize: '9pt', fontWeight: '600', textTransform: 'uppercase' }}>
              <a data-for={image.guid} data-tip="React-tooltip">
                {truncatedImageName}
              </a>
              <ReactTooltip id={image.guid} place="bottom" type="dark" effect="float">
                <span>{image.photo_file_name}</span>
              </ReactTooltip>
            </div>
          </div>
        } else if (image != null) {
          if (image.presigned_photo_url) {
            return <div key={image.guid} >
              <div className="imageContainer" style={{ textAlign: 'center', position: 'relative', display: 'inline-grid' }} onClick={() => { this.props.updateSelections("selectImage", image.guid, selections) }}>
                <img anima-src={imageSrc}
                  className="gutterPhoto"
                  style={{
                    opacity: '0.5'
                  }}
                  alt={image.photo_file_name}
                  src={imageSrc} />
              </div>

              <div className="text-block-163" style={{ fontSize: '9pt', fontWeight: '600', textTransform: 'uppercase' }}>
                <a data-for={image.guid} data-tip="React-tooltip">
                  {truncatedImageName}
                </a>
                <ReactTooltip id={image.guid} place="bottom" type="dark" effect="float">
                  <span>{image.photo_file_name}</span>
                </ReactTooltip>
              </div>
            </div>
          }
        }
      } else if (image != null) {
        if (image.presigned_photo_url) {
          return <div key={image.guid} >
            <div className="imageContainer" style={{ textAlign: 'center', position: 'relative', display: 'inline-grid' }} onClick={() => { this.props.updateSelections("selectImage", image.guid, selections) }}>
              <img anima-src={imageSrc}
                className="gutterPhoto"
                alt={image.photo_file_name}
                src={imageSrc} />
            </div>

            <div className="text-block-163" style={{ fontSize: '9pt', fontWeight: '600', textTransform: 'uppercase' }}>
              <a data-for={image.guid} data-tip="React-tooltip">
                {truncatedImageName}
              </a>
              <ReactTooltip id={image.guid} place="bottom" type="dark" effect="float">
                <span>{image.photo_file_name}</span>
              </ReactTooltip>
            </div>
          </div>
        }
      }
    });
    imageContainers.push(<div className="imageContainer" style={{ textAlign: 'center', position: 'relative', display: 'inline-grid' }} key={"end"}>
      <a data-for='end' data-tip="React-tooltip">
        <img alt='empty placeholder' className="gutterPhoto" src={noPhoto} />
      </a>
      <ReactTooltip id='end' place="bottom" type="dark" effect="float">
        <span>This is an empty placeholder image <br /> to indicate end of your photos <br /> and prevent hiding any of your images. <br /> You can ignore it.</span>
      </ReactTooltip>
    </div>)
    return imageContainers
  }


  sortNames = (names) => {
    let mappedNames = names.map(function (value, index) {
      let fileName = value["photo_file_name"].split(".")
      fileName = fileName[0]
      // console.log("FileName", fileName)
      let numericPart, stringPart;
      let splitString = fileName.split("_")
      if (!isNaN(splitString[0])) {
        numericPart = parseInt(splitString[0])
        if (splitString[1]) {
          stringPart = splitString[1].toLowerCase()
        } else {
          stringPart = ""
        }
      } else {
        if (splitString[1]) {
          numericPart = parseInt(splitString[1])
        } else {
          numericPart = 9999999
        }
        stringPart = splitString[0].toLowerCase()
      }
      return {
        index: index,
        numericPart: numericPart,
        stringPart: stringPart,
      }
    })
    mappedNames.sort((a, b) => {
      if (a.numericPart > b.numericPart)
        return 1;
      if (a.numericPart < b.numericPart)
        return -1;
      // if (a.stringPart > b.stringPart)
      //   return 1;
      // if (a.stringPart < b.stringPart)
      //   return -1;
      return 0;
    })
    let result = [];
    mappedNames.forEach((value) => result.push(names[value["index"]]))
    return result;
  }

  render() {
    const { images, inCart, selections, loadingImages } = this.state;
    //var slides = Math.floor(window.innerWidth / 125.0)

    if (loadingImages) {
      return (
        <div className="section-3" style={{ height: '200px', marginBottom: '0px' }}>
          <div className="w-layout-grid grid-11 grid-12" style={{ marginTop: '15px', textAlign: 'center', fontWeight: '700', marginBottom: '0px' }}>
            loading images from server...
          </div>
        </div>
      )
    } else {
      return (
        <div className="section-3" style={{ height: '200px', marginBottom: '0px', backgroundColor: 'white', zIndex: 9999 }}>
          <div className="w-layout-grid grid-11 grid-12" style={{ marginBottom: '0px' }}>
            {this.formattedImages(images, selections, inCart)}
          </div>
        </div>
      )
    }
  }
}

export default ImageGutter;
