import React, { Component } from "react";
import { Navigate, Link } from "react-router-dom";
import { Tooltip as ReactTooltip } from 'react-tooltip';

import Auth from "./modules/Auth.js";
import axios from "axios";
import NavBar from "./components/NavBar.js";
import LineItemSidebar from "./components/LineItemSidebar.js";
import MuseaApi from "./modules/MuseaApi.js"
import Button from '@mui/material/Button';

class PriceCalculator extends Component {

    constructor() {
        super();
        this.state = {
            selections: [],
            loadingProducts: true,
            products: [],
            sortedBy: "",
            lineItemGuid: '',
            lineItem: '',
            qty: 1,
            paperPricing: "",
            paperPricingName: "",
            printSizePricing: "",
            printSizePricingName: "",
            printBorder: "",
            finishingServiceOptions: {},
            finishingCategory: "",
            finishingCategoryName: "",
            finishingOptionOne: "",
            finishingOptionOneName: "",
            finishingOptionTwo: "",
            finishingOptionTwoName: "",
            frameGlass: "",
            frameGlassName: "",
            frameStain: "",
            frameStainName: "",
            finishingPricing: "",
            finishingDetailPricing: "",
            errorState: false,
            resultPrice: null,
        };
        this.clearSelections = this.clearSelections.bind(this);
        this.handleFinishingServiceChange = this.handleFinishingServiceChange.bind(this);
        this.handlePaperPricingChange = this.handlePaperPricingChange.bind(this);
        this.handlePrintSizeChange = this.handlePrintSizeChange.bind(this);
        this.handlePrintBorderChange = this.handlePrintBorderChange.bind(this);
        this.handleFinishingServiceCategoryChange = this.handleFinishingServiceCategoryChange.bind(this);
        this.handleFinishingOptionOneChange = this.handleFinishingOptionOneChange.bind(this);
        this.handleFinishingOptionTwoChange = this.handleFinishingOptionTwoChange.bind(this);
        this.handleFrameGlassChange = this.handleFrameGlassChange.bind(this);
        this.handleFrameStainChange = this.handleFrameStainChange.bind(this);
        //this.handleFinishingServiceDetailChange = this.handleFinishingServiceDetailChange.bind(this);
        this.handleQtyChange = this.handleQtyChange.bind(this);
        this.setAllStatesForLineItem = this.setAllStatesForLineItem.bind(this);
    }

    componentDidMount() {
        this.getProductsFromApi()
    }

    validateLineItem = (val) => {
        return true
    }
    toggleDropdownOpen(e, openDiv) {
        if (e !== null && e.type !== null) {
            e.preventDefault()
        }
        var openDivClass = "." + openDiv
        var openDivClassTop = "." + openDiv + "-top"
        let elem
        if (e.target.classList.contains("w--open")) {
            e.target.classList.remove("w--open")
            document.querySelector(openDivClass).classList.remove('w--open');
            elem = document.querySelector(openDivClassTop);
            elem.style.zIndex = '100'
            //this.props.toggleOpenDiv(e.target, "close")
        } else {
            var openDivs = document.querySelectorAll('.w--open')
            if (openDivs !== null && openDivs.length >= 1) {
                openDivs.forEach(div => {
                    div.classList.remove("w--open")
                    div.parentElement.style.zIndex = '100'
                    div.style.zIndex = '100'
                    //console.log("classList " + div.classList)
                    //console.log("zindex " + div.style.zIndex)
                })
            }
            e.target.classList.add("w--open")
            document.querySelector(openDivClass).classList.add('w--open');
            elem = document.querySelector(openDivClassTop);
            elem.style.zIndex = '999'
            //this.props.toggleOpenDiv(e.target, "open")
            //document.addEventListener('mousedown', this.handleClickOutside(e.target));
        }
    }

    async setAllStatesForLineItem() {
        let MuseaAPI = new MuseaApi()
        let formattedOptions = MuseaAPI.formatPrintSizeOptions(this.state.paperPricing, this.state.products, this.handlePrintSizeChange)
        this.setupFinishingServiceOptions(formattedOptions)
    }
    handleQtyChange(e) {
        if (e.target.validity.valid) {
            this.setState({
                qty: e.target.value
            })
        } else {
            console.log("not doing nothing")
        }
    }

    throwError(err) {
        let errorObject, stackTrace
        if (err.response) {
            errorObject = err.response.data.error
            if (err.response.data.error.more_info !== undefined) {
                stackTrace = err.response.data.error.more_info.join(" ,").split(",").join("\n")
            } else {
                stackTrace = ""
            }
        } else {
            errorObject = { status: 500, class_name: "" + err.name, developer_message: err.message }
            stackTrace = err.stack
        }
        let errorMessage = "\nERROR CODE: " + errorObject.status + "\n ERROR CLASS: " + errorObject.class_name + " \nERROR MESSAGE: " + errorObject.developer_message
        console.log("ERROR MESSAGE: " + errorMessage + "\n MORE INFO: " + stackTrace)

        this.setState({
            loading: false,
            query: "",
            errorState: true,
            errorMessage: errorMessage
        })
    }

    async setupFinishingServiceOptions(formattedOptions) {
        let category, categoryName, finishingServices, finishingServiceOptions, catOneOptions, catTwoOptions, frameGlassOptions, frameStainOptions, finishingOptionOne, finishingOptionOneName, finishingOptionTwo, finishingOptionTwoName, frameGlass, frameGlassName, frameStain, frameStainName
        let MuseaAPI = new MuseaApi()

        if (this.state.printSizePricing !== null) {
            finishingServices = await MuseaAPI.getFinishingServices(this.state.printSizePricing)
            finishingServiceOptions = MuseaAPI.mergeAttributesForFinishingOptions(finishingServices, this.handleFinishingOptionOneChange)
            // this.props.updateCropOrientation(this.state.paperPricing, this.state.printSizePricing, this.state.products, "setupLineItem")
            //this.props.setCropOptionsForLineItem()

            if (this.state.finishingCategory !== null) {
                category = this.state.finishingCategory.toLowerCase()
                categoryName = this.state.finishingCategory.toUpperCase()
                console.log("category line 582: " + category)

                //this.setupFinishingOptionsForCategory(this.state.finishingCategory)

                let MuseaAPI = new MuseaApi()

                catOneOptions = await MuseaAPI.setupFinishCatOneOptions(this.state.finishingCategory, finishingServiceOptions, this.state.paperPricingName)

                if (category === "mount") {
                    let defaultMount = catOneOptions[0].key
                    catTwoOptions = MuseaAPI.setupFinishCatTwoOptions(defaultMount, "mount", this.state.finishingCategory, finishingServiceOptions, this.handleFinishingOptionTwoChange)

                    let finishingOptionOneDetails = this.formatMountNameFromFinish(this.props.lineItem, catOneOptions)
                    finishingOptionOneName = finishingOptionOneDetails[0]
                    finishingOptionOne = finishingOptionOneDetails[1]

                    let finishingOptionTwoDetails = this.formatMountDetailFromFinish(this.props.lineItem, catTwoOptions)
                    finishingOptionTwoName = finishingOptionTwoDetails[0]
                    finishingOptionTwo = finishingOptionTwoDetails[1]

                } else if (category === "four_ply_mat") {
                    catTwoOptions = []
                    let finishingOptionOneDetails = this.formatOptionOneNameFromFinish(this.props.lineItem, catOneOptions)
                    finishingOptionOneName = finishingOptionOneDetails[0]
                    finishingOptionOne = finishingOptionOneDetails[1]
                } else if (category === "eight_ply_mat") {
                    catTwoOptions = []
                    let finishingOptionOneDetails = this.formatOptionOneNameFromFinish(this.props.lineItem, catOneOptions)
                    finishingOptionOneName = finishingOptionOneDetails[0]
                    finishingOptionOne = finishingOptionOneDetails[1]
                } else if (category === "premium_frame") {

                    let finishingOptionOneDetails = this.getFrameProfileKeyFromFinish(this.props.lineItem, catOneOptions)
                    finishingOptionOneName = finishingOptionOneDetails[0]
                    finishingOptionOne = finishingOptionOneDetails[1]

                    catTwoOptions = MuseaAPI.setupFinishCatTwoOptions(finishingOptionOne, "category", this.state.finishingCategory, finishingServiceOptions, this.handleFinishingOptionTwoChange)

                    let finishingOptionTwoDetails = this.getFrameMatKeyFromFinish(this.props.lineItem, catTwoOptions)
                    finishingOptionTwoName = finishingOptionTwoDetails[0]
                    finishingOptionTwo = finishingOptionTwoDetails[1]

                    frameGlassOptions = MuseaAPI.setupFrameGlassOptions(finishingOptionTwo, finishingServiceOptions, finishingOptionOne, this.handleFrameGlassChange)

                    let frameGlassDetails = this.getFrameGlassKeyFromFinish(this.props.lineItem, frameGlassOptions)
                    frameGlassName = frameGlassDetails[0]
                    frameGlass = frameGlassDetails[1]

                    frameStainOptions = MuseaAPI.setupFrameStainOptions(frameGlass, finishingServiceOptions, this.handleFrameStainChange)

                    let frameStainDetails = this.getFrameStainKeyFromFinish(this.props.lineItem, frameStainOptions)
                    frameStainName = frameStainDetails[0]
                    frameStain = frameStainDetails[1]
                } else if (category === "standard_frame") {

                    let finishingOptionOneDetails = this.getFrameProfileKeyFromFinish(this.props.lineItem, catOneOptions)
                    finishingOptionOneName = finishingOptionOneDetails[0]
                    finishingOptionOne = finishingOptionOneDetails[1]

                    catTwoOptions = MuseaAPI.setupFinishCatTwoOptions(finishingOptionOne, "category", this.state.finishingCategory, finishingServiceOptions, this.handleFinishingOptionTwoChange)

                    let finishingOptionTwoDetails = this.getFrameMatKeyFromFinish(this.props.lineItem, catTwoOptions)
                    finishingOptionTwoName = finishingOptionTwoDetails[0]
                    finishingOptionTwo = finishingOptionTwoDetails[1]

                    frameGlassOptions = MuseaAPI.setupFrameGlassOptions(finishingOptionTwo, finishingServiceOptions, finishingOptionOne, this.handleFrameGlassChange)

                    let frameGlassDetails = this.getFrameGlassKeyFromFinish(this.props.lineItem, frameGlassOptions)
                    frameGlassName = frameGlassDetails[0]
                    frameGlass = frameGlassDetails[1]

                } else {
                    category = "none"
                    categoryName = "NONE"
                    catOneOptions = []
                    catTwoOptions = []
                }
            } else {
                category = "none"
                categoryName = "NONE"
                catOneOptions = []
                catTwoOptions = []
            }
        } else {
            finishingServices = this.state.finishingServiceOptions
            finishingServiceOptions = this.state.finishingServiceOptions
        }

        this.setState({
            formattedPrintSizeOptions: formattedOptions,
            finishingServiceOptions: finishingServiceOptions,
            finishingCategory: category,
            finishingCategoryName: categoryName,
            finishingOptionOneForCategory: catOneOptions,
            finishingOptionOne: finishingOptionOne,
            finishingOptionOneName: finishingOptionOneName,
            finishingOptionTwoForCategory: catTwoOptions,
            finishingOptionTwo: finishingOptionTwo,
            finishingOptionTwoName: finishingOptionTwoName,
            frameGlassOptions: frameGlassOptions,
            frameGlassName: frameGlassName,
            frameGlass: frameGlass,
            frameStainOptions: frameStainOptions,
            frameStainName: frameStainName,
            frameStain: frameStain,
        })
        return true
    }


    formatMountNameFromFinish(fullLineItem, catOptions) {
        let matchingService, optionOneName, optionOneKey
        if (catOptions.length > 0) {
            matchingService = catOptions.filter(obj => (obj.key === this.state.lineItem.finishingServiceGuid))
        } else {
            matchingService = []
        }

        if (matchingService.length > 0) {
            optionOneName = matchingService[0].props.formattedname
            optionOneKey = matchingService[0].key
        }

        return [optionOneName, optionOneKey]
    }

    formatMountDetailFromFinish(fullLineItem, mountDetailOptions) {
        let matchingService, optionTwoName, optionTwoKey
        if (mountDetailOptions.length > 0) {
            matchingService = mountDetailOptions.filter(obj => (obj.key === this.state.lineItem.finishingServiceDetailGuid))
        } else {
            matchingService = []
        }

        if (matchingService.length > 0) {
            optionTwoName = matchingService[0].props.formattedname
            optionTwoKey = matchingService[0].key
        }

        return [optionTwoName, optionTwoKey]
    }

    formatOptionOneNameFromFinish(fullLineItem, catOptions) {
        let matchingService, optionOneName, optionOneKey
        if (catOptions.length > 0) {
            matchingService = catOptions[0].filter(obj => (obj.key === this.state.lineItem.finishingServiceGuid))
        } else {
            matchingService = []
        }

        if (matchingService.length > 0) {
            optionOneName = matchingService[0].props.formattedname
            optionOneKey = matchingService[0].key
        }

        return [optionOneName, optionOneKey]
    }

    getFrameProfileKeyFromFinish(fullLineItem, frameOptions) {
        let matchingService, optionOneName, optionOneKey
        let newProfile = fullLineItem.frameProfile.replace(' 3/4', '-.75').replace(' 1.5', '-1.5')
        if (frameOptions.length > 0) {
            matchingService = frameOptions.filter(obj => (obj.key === newProfile))
        } else {
            matchingService = []
        }

        if (matchingService.length > 0) {
            optionOneName = matchingService[0].props.formattedname
            optionOneKey = matchingService[0].key
        }

        return [optionOneName, optionOneKey]
    }

    getFrameMatKeyFromFinish(fullLineItem, frameOptions) {
        let matchingService, optionTwoName, optionTwoKey
        if (frameOptions[0].length > 0) {
            matchingService = frameOptions[0].filter(obj => (obj.key === fullLineItem.finishingDetailTwo))
        } else {
            matchingService = []
        }

        if (matchingService.length > 0) {
            optionTwoName = matchingService[0].props.formattedname
            optionTwoKey = matchingService[0].key
        }
        return [optionTwoName, optionTwoKey]
    }

    getFrameGlassKeyFromFinish(fullLineItem, frameGlassOptions) {
        let matchingService, frameGlassName, frameGlassKey
        if (frameGlassOptions[0].length > 0) {
            matchingService = frameGlassOptions[0].filter(obj => (
                obj.props.formattedname.toLowerCase().includes(fullLineItem.finishingDetailThree.toLowerCase())
            ))
        } else {
            matchingService = []
        }

        if (matchingService.length > 0) {
            frameGlassName = matchingService[0].props.formattedname
            frameGlassKey = matchingService[0].key
        }
        return [frameGlassName, frameGlassKey]
    }

    getFrameStainKeyFromFinish(fullLineItem, frameStainOptions) {
        let matchingService, frameStainName, frameStainKey
        if (frameStainOptions.length > 0) {
            matchingService = frameStainOptions.filter(obj => (
                fullLineItem.finishingDetailOne.toLowerCase().includes(obj.props.formattedname.toLowerCase())
            ))
        } else {
            matchingService = []
        }
        if (matchingService.length > 0) {
            frameStainName = matchingService[0].props.formattedname
            frameStainKey = matchingService[0].key
        }
        return [frameStainName, frameStainKey]
    }



    async handlePaperPricingChange(e, name, val, valName) {
        let formattedOptions
        let MuseaAPI = new MuseaApi()
        //(e, "paperPricing", objGuid, obj.attributes.name)
        if (e !== null && e.type !== null) {
            e.preventDefault()
        }
        var objName = name + "Name"
        var openDiv = name + "-dropdown"
        if (e !== null && e.type !== null) {
            this.toggleDropdownOpen(e, openDiv)
        }

        //this.state.paperPricing !== null &&
        formattedOptions = MuseaAPI.formatPrintSizeOptions(val, this.state.products, this.handlePrintSizeChange)

        this.setState({
            [name]: val,
            [objName]: valName,
            printSizePricing: '',
            printSizePricingName: "",
            formattedPrintSizeOptions: formattedOptions,
            finishingCategoryName: '',
            finishingCategory: '',
            finishingPricing: "",
            finishingDetailPricing: "",
            finishingOptionOneForCategory: {},
            finishingOptionTwoForCategory: "",
            finishingOptionOne: "",
            finishingOptionOneName: "",
            finishingOptionTwo: "",
            finishingOptionTwoName: "",
            frameGlass: "",
            frameGlassName: "",
            frameStain: "",
            frameStainName: "",
            selectedFinishDetails: "",
            selectedFinishDetailsName: "",
        })

        return true
    }

    async handlePrintBorderChange(e, name, val, valName) {
        let formattedOptions
        let MuseaAPI = new MuseaApi()
        if (e !== null && e.type !== null) {
            e.preventDefault()
        }
        var objName = name + "Name"
        var openDiv = name + "-dropdown"
        if (e !== null && e.type !== null) {
            this.toggleDropdownOpen(e, openDiv)
        }
        this.props.updatePricingSelection(val, name)

        if (this.state.paperPricing !== null && name === "paperPricing") {
            formattedOptions = MuseaAPI.formatPrintSizeOptions(val, this.state.products, this.handlePrintSizeChange)
        } else {
            formattedOptions = this.state.formattedPrintSizeOptions
        }

        this.setState({
            [name]: val,
            [objName]: valName,
            formattedPrintSizeOptions: formattedOptions
        })

        return true
    }

    async handlePrintSizeChange(e, name, val, valName) {
        let finishingServices
        let MuseaAPI = new MuseaApi()
        //(e, "printSizePricing", objGuid, obj.name)
        if (e !== null && e.type !== null) {
            e.preventDefault()
        }
        var objName = name + "Name"
        var openDiv = name + "-dropdown"
        if (e !== null && e.type !== null) {
            this.toggleDropdownOpen(e, openDiv)
        }
        //const name = e.target.name;
        //const val = e.target.value;

        // this.props.updateCropOrientation(this.state.paperPricing, val, this.state.products, "printSizeChange")
        // this.props.updatePricingSelection(val, "printSizePricing")
        // this.props.updatePricingSelection("", "finishingCategory")
        // this.props.updatePricingSelection("", "finishingPricing")
        // this.props.updatePricingSelection("", "finishingDetailPricing")
        if (this.state.printSizePricing !== val) {
            finishingServices = await MuseaAPI.getFinishingServices(val)
        } else {
            finishingServices = this.state.finishingServiceOptions
        }

        let finishingServiceOptions = MuseaAPI.mergeAttributesForFinishingOptions(finishingServices, this.handleFinishingOptionOneChange)

        this.setState({
            [objName]: valName,
            [name]: val,
            finishingCategoryName: '',
            finishingCategory: '',
            finishingServiceOptions: finishingServiceOptions,
            finishingPricing: "",
            finishingDetailPricing: "",
            finishingOptionOneForCategory: {},
            finishingOptionTwoForCategory: "",
        })
        return true
    }

    async handleFinishingServiceCategoryChange(e, name, val, valName) {
        let MuseaAPI = new MuseaApi()
        //(e, "finishingCategory", "none", "none")
        if (e !== null && e.type !== null) {
            e.preventDefault()
        }
        var objName = name + "Name"
        var openDiv = name + "-dropdown"
        if (e !== null && e.type !== null) {
            this.toggleDropdownOpen(e, openDiv)
        }
        //const name = e.target.name;
        //const val = e.target.value;
        let catOneOptions = MuseaAPI.setupFinishCatOneOptions(val, this.state.finishingServiceOptions, this.state.paperPricingName)
        if (val === "mount") {
            //&& catOneOptions.length === 1
            let defaultMount = catOneOptions[0].key
            // this.props.updatePricingSelection("mount", "finishingCategory")
            // this.props.updatePricingSelection("", "finishingPricing")
            // this.props.updatePricingSelection("", "finishingDetailPricing")
            //this.props.updatePricingSelection(defaultMount, "finishingPricing")

            let catTwoOptions = MuseaAPI.setupFinishCatTwoOptions(defaultMount, "mount", "mount", this.state.finishingServiceOptions, this.handleFinishingOptionTwoChange)

            this.setState({
                [name]: val,
                [objName]: valName,
                finishingOptionOneForCategory: catOneOptions,
                finishingOptionTwoForCategory: catTwoOptions,
                finishingPricing: "",
                finishingDetailPricing: "",
                finishingOptionOne: "",
                finishingOptionTwo: ""
            })
        } else if (val === "four_ply_mat") {
            // this.props.updatePricingSelection("four_ply_mat", "finishingCategory")
            // this.props.updatePricingSelection("", "finishingPricing")
            // this.props.updatePricingSelection("", "finishingDetailPricing")
            this.setState({
                [name]: val,
                [objName]: valName,
                finishingOptionOneForCategory: catOneOptions,
                finishingOptionTwoForCategory: "",
                finishingPricing: "",
                finishingDetailPricing: "",
                finishingOptionOne: "",
                finishingOptionTwo: ""
            })
        } else if (val === "eight_ply_mat") {
            // this.props.updatePricingSelection("eight_ply_mat", "finishingCategory")
            // this.props.updatePricingSelection("", "finishingPricing")
            // this.props.updatePricingSelection("", "finishingDetailPricing")
            this.setState({
                [name]: val,
                [objName]: valName,
                finishingOptionOneForCategory: catOneOptions,
                finishingOptionTwoForCategory: "",
                finishingPricing: "",
                finishingDetailPricing: "",
                finishingOptionOne: "",
                finishingOptionTwo: ""
            })
        } else if (val === "standard_frame") {

            // this.props.updatePricingSelection("standard_frame", "finishingCategory")
            // this.props.updatePricingSelection("", "finishingPricing")
            // this.props.updatePricingSelection("", "finishingDetailPricing")
            this.setState({
                [name]: val,
                [objName]: valName,
                finishingOptionOneForCategory: catOneOptions,
                finishingPricing: "",
                finishingDetailPricing: "",
                finishingOptionOne: "",
                finishingOptionTwo: ""
            })
        } else if (val === "premium_frame") {

            // this.props.updatePricingSelection("premium_frame", "finishingCategory")
            // this.props.updatePricingSelection("", "finishingPricing")
            // this.props.updatePricingSelection("", "finishingDetailPricing")
            this.setState({
                [name]: val,
                [objName]: valName,
                finishingOptionOneForCategory: catOneOptions,
                finishingPricing: "",
                finishingDetailPricing: "",
                finishingOptionOne: "",
                finishingOptionTwo: ""
            })
        } else if (val === "none") {
            // this.props.updatePricingSelection("none", "finishingCategory")
            // this.props.updatePricingSelection("", "finishingPricing")
            // this.props.updatePricingSelection("", "finishingDetailPricing")
            this.setState({
                finishingCategory: "none",
                finishingCategoryName: "NONE",
                finishingPricing: "",
                finishingDetailPricing: "",
                finishingOptionOne: "",
                finishingOptionTwo: ""
            })
        }
        return true
    }

    async handleFinishingOptionOneChange(e, name, val, valName) {
        let MuseaAPI = new MuseaApi()
        //(e, "finishingOptionOne", keyThreeFourths, nameThreeFourths)
        if (e !== null && e.type !== null) {
            e.preventDefault()
        }
        var objName = name + "Name"
        var openDiv = name + "-dropdown"
        if (e !== null && e.type !== null) {
            this.toggleDropdownOpen(e, openDiv)
        }
        if (this.state.finishingCategory === "premium_frame") {
            let catTwoOptions = MuseaAPI.setupFinishCatTwoOptions(val, "category", this.state.finishingCategory, this.state.finishingServiceOptions, this.handleFinishingOptionTwoChange)

            this.setState({
                [name]: val,
                [objName]: valName,
                finishingOptionTwoForCategory: catTwoOptions,
                finishingPricing: "",
                finishingDetailPricing: "",
                finishingOptionTwo: "",
                frameGlassOption: "",
                frameStainOption: ""
            })
        } else if (this.state.finishingCategory === "standard_frame") {
            let catTwoOptions = MuseaAPI.setupFinishCatTwoOptions(val, "category", this.state.finishingCategory, this.state.finishingServiceOptions, this.handleFinishingOptionTwoChange)

            this.setState({
                [name]: val,
                [objName]: valName,
                finishingOptionTwoForCategory: catTwoOptions,
                finishingPricing: "",
                finishingDetailPricing: "",
                finishingOptionTwo: "",
                frameGlassOption: "",
                frameStainOption: ""
            })
        } else if (this.state.finishingCategory === "four_ply_mat" || this.state.finishingCategory === "eight_ply_mat" || this.state.finishingCategory === "mount") {
            // this.props.updatePricingSelection(val, "finishingPricing")
            // this.props.updatePricingSelection("", "finishingDetailPricing")
            this.setState({
                [name]: val,
                [objName]: valName,
                finishingPricing: val,
                finishingDetailPricing: "",
                finishingOptionTwo: "",
                frameGlassOption: "",
                frameStainOption: ""
            })
        }
        return true
    }

    async handleFinishingOptionTwoChange(e, name, val, valName) {
        let frameGlassOptions
        let MuseaAPI = new MuseaApi()
        //(e, "finishingOptionTwo", key, name)
        if (e !== null && e.type !== null) {
            e.preventDefault()
        }
        var objName = name + "Name"
        var openDiv = name + "-dropdown"
        if (e !== null && e.type !== null) {
            this.toggleDropdownOpen(e, openDiv)
        }
        console.log("option 2 category ln417: " + this.state.finishingCategory)
        if (val.length > 0) {

            if (this.state.finishingCategory === "premium_frame") {
                frameGlassOptions = MuseaAPI.setupFrameGlassOptions(val, this.state.finishingServiceOptions, this.state.finishingOptionOne, this.handleFrameGlassChange)
            }
            if (this.state.finishingCategory === "standard_frame") {
                frameGlassOptions = MuseaAPI.setupStandardFrameGlassOptions(val, this.state.finishingServiceOptions, this.state.finishingOptionOne, this.handleFrameGlassChange)
            }
            if (this.state.finishingCategory === "mount") {
                // this.props.updatePricingSelection(val, "finishingDetailPricing")
                this.setState({
                    [name]: val,
                    [objName]: valName,
                    finishingDetailPricing: val,
                })
            } else {
                this.setState({
                    [name]: val,
                    [objName]: valName,
                    finishingPricing: "",
                    finishingDetailPricing: "",
                    frameGlassOptions: frameGlassOptions,
                    frameStainOption: "",
                    frameStainName: ""
                })
            }
        }
        return true
    }

    async handleFrameGlassChange(e, name, val, valName) {
        let frameStainOptions
        if (e !== null && e.type !== null) {
            e.preventDefault()
        }
        var objName = name + "Name"
        var openDiv = name + "-dropdown"
        if (e !== null && e.type !== null) {
            this.toggleDropdownOpen(e, openDiv)
        }
        if (val.length > 0) {

            if (this.state.finishingCategory === "premium_frame") {
                const MuseaAPI = new MuseaApi()
                frameStainOptions = MuseaAPI.setupFrameStainOptions(val, this.state.finishingServiceOptions, this.handleFrameStainChange)
            }

            // this.props.updatePricingSelection(val, "finishingPricing")
            // this.props.updatePricingSelection("", "finishingDetailPricing")
            this.setState({
                [name]: val,
                [objName]: valName,
                frameStainOptions: frameStainOptions,
            })
        }
        return true
    }

    async handleFrameStainChange(e, name, val, valName) {
        if (e !== null && e.type !== null) {
            e.preventDefault()
        }
        var objName = name + "Name"
        var openDiv = name + "-dropdown"
        if (e !== null && e.type !== null) {
            this.toggleDropdownOpen(e, openDiv)
        }
        if (val.length > 0) {
            let isValidLineItem = this.validateLineItem(val)
            // this.props.updatePricingSelection(val, "finishingDetailPricing")
            this.setState({
                [name]: val,
                [objName]: valName,
                isValidLineItem: isValidLineItem,
            })
        }
        return true
    }

    clearSelections() {
        this.setState({
            qty: 1,
            paperPricing: "",
            paperPricingName: "",
            printSizePricing: "",
            printSizePricingName: "",
            printBorder: "",
            formattedPrintSizeOptions: "",
            finishingServiceOptions: {},
            finishingCategory: "",
            finishingCategoryName: "",
            finishingOptionOne: "",
            finishingOptionOneName: "",
            finishingOptionTwo: "",
            finishingOptionTwoName: "",
            finishingPricing: "",
            finishingOptionOneForCategory: [],
            frameGlass: "",
            frameGlassName: "",
            frameStain: "",
            frameStainName: "",
            selectedFinishDetails: "",
            selectedFinishDetailsName: "",
        })
        this.props.updateSelections("clearSelections", "", [])
        return true
    }
    handleFinishingServiceChange(e, name, val, valName) {
    }

    async getProductsFromApi() {
        let paperPricingFunction = this.handlePaperPricingChange
        let MuseaAPI = new MuseaApi()
        await MuseaAPI.getProducts().then(products => {
            let thePaperOptions = MuseaAPI.paperOptions(products, paperPricingFunction)
            this.setState({
                products: products,
                paperOptions: thePaperOptions,
                loadingProducts: false,
                errorState: false
            })
        })
            .catch(err =>
                this.throwError(err))
    }


    throwError(err) {
        let errorObject, stackTrace
        if (err.response) {
            errorObject = err.response.data.error
            if (err.response.data.error.more_info !== undefined) {
                stackTrace = err.response.data.error.more_info.join(" ,").split(",").join("\n")
            } else {
                stackTrace = ""
            }
        } else {
            errorObject = { status: 500, class_name: "" + err.name, developer_message: err.message }
            stackTrace = err.stack
        }
        let errorMessage = "\nERROR CODE: " + errorObject.status + "\n ERROR CLASS: " + errorObject.class_name + " \nERROR MESSAGE: " + errorObject.developer_message
        console.log("ERROR MESSAGE: " + errorMessage + "\n MORE INFO: " + stackTrace)

        this.setState({
            loading: false,
            query: "",
            errorState: true,
            errorMessage: errorMessage
        })
    }

    calculatePrice() {
        console.log(this.state.qty)
        let finishingPrice = 0;

        const paperOptions = this.state.paperOptions;
        const products = this.state.products;
        const finishingServiceOptions = this.state.finishingServiceOptions;

        const finishingCategory = this.state.finishingCategory;
        const printSizePricing = this.state.printSizePricing;
        const paperPricing = this.state.paperPricing;
        const finishingPricing = this.state.finishingPricing;

        const selectedProduct = products.find((value) => value.id === paperPricing)
        if (selectedProduct === undefined || selectedProduct.attributes.print_size_pricings === undefined)
            return;


        const selectedPaperSizePrice = (selectedProduct.attributes.print_size_pricings
            .find((value) => value.data.id === printSizePricing))
        if (selectedPaperSizePrice === undefined)
            return;

        const paperPrice = selectedPaperSizePrice.data.attributes.price;

        if (finishingCategory.toLowerCase() === "four_ply_mat") {
            finishingPrice = (finishingServiceOptions["four_ply_mat"].find((value) => value.key === finishingPricing)).props.price
        } else if (finishingCategory.toLowerCase() === "eight_ply_mat") {
            finishingPrice = (finishingServiceOptions["eight_ply_mat"].find((value) => value.key === finishingPricing)).props.price
        } else if (finishingCategory.toLowerCase() === "premium_frame") {
            finishingPrice = (finishingServiceOptions["premium_frame"].find((value) => value.key === finishingPricing)).props.price
        } else if (finishingCategory.toLowerCase() === "standard_frame") {
            finishingPrice = (finishingServiceOptions["standard_frame"].find((value) => value.key === finishingPricing)).props.price
        } else if (finishingCategory.toLowerCase() === "mount") {
            finishingPrice = (finishingServiceOptions["mount"].find((value) => value.key === finishingPricing)).props.price
        }


        const resultPrice = (parseFloat(paperPrice) + parseFloat(finishingPrice)) * this.state.qty
        console.log("Paper Price: ", paperPrice, "Finishing Price", finishingPrice, "Quantity", this.state.qty, "Finishing Category", finishingCategory.toLowerCase())
        this.setState({
            resultPrice: resultPrice
        })
    }
    render() {
        const { paperPricing, paperPricingName, qty, printSizePricing, printSizePricingName, finishingCategory, finishingCategoryName, finishingOptionOneForCategory, finishingOptionOne, finishingOptionOneName, finishingOptionTwo, finishingOptionTwoName, finishingOptionTwoForCategory, frameStain, frameStainName, frameStainOptions, frameGlass, frameGlassName, frameGlassOptions } = this.state;



        let qtyField = <div>
            <input type="text"
                className="quantityField"
                style={{ marginTop: '40px', width: '100%' }}
                placeholder="QUANTITY"
                name="qty"
                onChange={this.handleQtyChange}
                value={qty}
                pattern="[0-9]*" />
        </div>


        let paperField

        paperField = <div data-delay="0" onClick={(e) => { this.toggleDropdownOpen(e, "paperPricing-dropdown") }} className="dropdown-13 w-dropdown paperPricing-dropdown-top">
            <div className="dropdown-toggle-5 w-dropdown-toggle" style={{}}>
                <div className="w-icon-dropdown-toggle">
                </div>
                <div>

                    <div className="text-block-153" style={{ textAlign: 'center', whiteSpace: 'pre-line' }}>{paperPricing.length > 0 ? paperPricingName : "PAPER TYPE"}</div>

                </div>
            </div>
            <nav className="w-dropdown-list dropdown-list-4 paperPricing-dropdown"
                style={{ width: '150px', height: '400px', overflowY: 'scroll' }} >
                {this.state.paperOptions}
            </nav>
        </div>


        let printSizeField
        if (paperPricing.length > 0) {
            printSizeField = <div data-delay="0" onClick={(e) => { this.toggleDropdownOpen(e, "printSizePricing-dropdown") }} className="dropdown-13 w-dropdown printSizePricing-dropdown-top">
                <div className="dropdown-toggle-5 w-dropdown-toggle">
                    <div className="w-icon-dropdown-toggle">
                    </div>
                    <div className="text-block-153" style={{ textAlign: 'center', whiteSpace: 'pre-line' }}>{printSizePricing && printSizePricing.length > 1 && printSizePricingName.length > 1 ? printSizePricingName : "PRINT SIZE"}</div>
                </div>
                <nav className="w-dropdown-list dropdown-list-4 printSizePricing-dropdown"
                    style={{ width: '150px', height: '400px', overflowY: 'scroll' }} >
                    {this.state.formattedPrintSizeOptions}
                </nav>
            </div>
        } else {
            printSizeField = <div data-delay="0" onClick={() => { alert("You must select a Paper Type first.") }} className="dropdown-13 w-dropdown print-size-dropdown-top">
                <div className="dropdown-toggle-5 w-dropdown-toggle">
                    <div className="w-icon-dropdown-toggle">
                    </div>
                    <div className="text-block-153" style={{ textAlign: 'center', whiteSpace: 'pre-line' }}>{printSizePricing.length > 1 && printSizePricingName.length > 1 ? printSizePricingName : "PRINT SIZE"}</div>
                </div>
            </div>
        }


        let finishingCategoryField
        let finishingCategoryFields = []
        finishingCategoryFields.push(<a key="none" style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { this.handleFinishingServiceCategoryChange(e, "finishingCategory", "none", "none") }} className="w-dropdown-link line-item-select-link"> NONE </a>)

        if (this.state.finishingServiceOptions && this.state.finishingServiceOptions.four_ply_mat && this.state.finishingServiceOptions.four_ply_mat.length > 0) {
            finishingCategoryFields.push(<a key="four_ply_mat" style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { this.handleFinishingServiceCategoryChange(e, "finishingCategory", "four_ply_mat", "4-PLY MAT") }} className="w-dropdown-link line-item-select-link"> 4-PLY MAT </a>)
        }

        if (this.state.finishingServiceOptions && this.state.finishingServiceOptions.eight_ply_mat && this.state.finishingServiceOptions.eight_ply_mat.length > 0) {
            finishingCategoryFields.push(<a key="eight_ply_mat" style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { this.handleFinishingServiceCategoryChange(e, "finishingCategory", "eight_ply_mat", "8-PLY MAT") }} className="w-dropdown-link line-item-select-link"> 8-PLY MAT </a>)
        }

        if (this.state.finishingServiceOptions && this.state.finishingServiceOptions.mount && this.state.finishingServiceOptions.mount.length > 0) {
            finishingCategoryFields.push(<a key="mount" style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { this.handleFinishingServiceCategoryChange(e, "finishingCategory", "mount", "MOUNT") }} className="w-dropdown-link line-item-select-link"> MOUNT </a>)
        }

        if (this.state.finishingServiceOptions && this.state.finishingServiceOptions.standard_frame && this.state.finishingServiceOptions.standard_frame.length > 0) {
            finishingCategoryFields.push(<a key="standard_frame" style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { this.handleFinishingServiceCategoryChange(e, "finishingCategory", "standard_frame", "STANDARD FRAME") }} className="w-dropdown-link line-item-select-link"> STANDARD FRAME </a>)
        }

        if (this.state.finishingServiceOptions && this.state.finishingServiceOptions.premium_frame && this.state.finishingServiceOptions.premium_frame.length > 0) {
            finishingCategoryFields.push(<a key="premium_frame" style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { this.handleFinishingServiceCategoryChange(e, "finishingCategory", "premium_frame", "PREMIUM FRAME") }} className="w-dropdown-link line-item-select-link"> PREMIUM FRAME </a>)
        }

        if (printSizePricing.length > 0) {
            finishingCategoryField = <div data-delay="0" onClick={(e) => { this.toggleDropdownOpen(e, "finishingCategory-dropdown") }} className="dropdown-16 w-dropdown finishingCategory-dropdown-top">
                <div className="dropdown-toggle-8 w-dropdown-toggle">
                    <div className="w-icon-dropdown-toggle">
                    </div>
                    <div className="text-block-153" style={{ textAlign: 'center', whiteSpace: 'pre-line' }}>{finishingCategory.length > 1 && finishingCategoryName.length > 1 ? finishingCategoryName : "FINISHING SERVICE"} </div>
                </div>
                <nav className="dropdown-list-4 w-dropdown-list finishingCategory-dropdown"
                    style={{ width: '150px' }} >

                    {finishingCategoryFields}

                </nav>
            </div>
        } else {
            finishingCategoryField = <div data-delay="0" onClick={() => { alert("You must select a Paper Type and Print Size first.") }} className="dropdown-16 w-dropdown finishingCategory-dropdown-top">
                <div className="dropdown-toggle-8 w-dropdown-toggle">
                    <div className="w-icon-dropdown-toggle">
                    </div>
                    <div className="text-block-153" style={{ textAlign: 'center', whiteSpace: 'pre-line' }}>{finishingCategory.length > 1 && finishingCategoryName.length > 1 ? finishingCategoryName : "FINISHING SERVICE"} </div>
                </div>
            </div>
        }
        let finishingOptionOneField, finishingOptionOneTopLabel, finishingOptionOneHeight
        if (finishingCategory.length > 0 && finishingCategoryName.toUpperCase() !== "NONE") {
            if (finishingCategoryName !== null) {
                if (finishingCategoryName.toLowerCase() === "4-ply mat") {
                    finishingOptionOneTopLabel = "MAT SIZE"
                    finishingOptionOneHeight = '400px'
                } else if (finishingCategoryName.toLowerCase() === "8-ply mat") {
                    finishingOptionOneTopLabel = "MAT SIZE"
                    finishingOptionOneHeight = '400px'
                } else if (finishingCategoryName.toLowerCase() === "premium frame") {
                    finishingOptionOneTopLabel = "FRAME WOOD"
                    finishingOptionOneHeight = '400px'
                } else if (finishingCategoryName.toLowerCase() === "standard frame") {
                    finishingOptionOneTopLabel = "FRAME WOOD"
                    finishingOptionOneHeight = '400px'
                } else if (finishingCategoryName.toLowerCase() === "mount") {
                    finishingOptionOneTopLabel = "MOUNT SIZE"
                    finishingOptionOneHeight = '50px'
                } else {
                    finishingOptionOneTopLabel = "FINISHING DETAIL"
                    finishingOptionOneHeight = '400px'
                }
            } else {
                finishingOptionOneTopLabel = "FINISHING DETAIL"
                finishingOptionOneHeight = '400px'
            }
            finishingOptionOneField = <div data-delay="0" onClick={(e) => { this.toggleDropdownOpen(e, "finishingOptionOne-dropdown") }} className="dropdown-13 w-dropdown finishingOptionOne-dropdown-top">
                <div className="dropdown-toggle-5 w-dropdown-toggle">
                    <div className="w-icon-dropdown-toggle">
                    </div>
                    <div className="text-block-153" style={{ textAlign: 'center', whiteSpace: 'pre-line' }}>{finishingOptionOne && finishingOptionOne.length > 1 ? finishingOptionOneName : finishingOptionOneTopLabel}</div>
                </div>
                <nav className="w-dropdown-list finishingOptionOne-dropdown" style={{ width: '150px', height: finishingOptionOneHeight, overflowY: 'scroll' }}>
                    {finishingOptionOneForCategory}

                </nav>
            </div>

        } else {
            finishingOptionOneField = <div></div>
        }

        let finishingOptionTwoField, finishingOptionTwoTopLabel
        // console.log("finishingCategoryName line 1023: " + finishingCategoryName)
        if (finishingCategory && finishingCategory.length > 0 && finishingOptionOne && finishingOptionOne.length > 0 && finishingCategory !== "four_ply_mat" && finishingCategory !== "eight_ply_mat") {
            if (finishingCategoryName !== null) {
                if (finishingCategoryName.toLowerCase() === "4-ply mat" || finishingCategoryName.toLowerCase() === "8-ply mat") {
                    finishingOptionTwoTopLabel = "MAT CLASS"
                } else if (finishingCategoryName.toLowerCase() === "premium frame") {
                    finishingOptionTwoTopLabel = "FRAME MAT"
                } else if (finishingCategoryName.toLowerCase() === "standard frame") {
                    finishingOptionTwoTopLabel = "FRAME MAT"
                } else if (finishingCategoryName.toLowerCase() === "mount") {
                    finishingOptionTwoTopLabel = "MOUNT COLOR"
                } else {
                    finishingOptionTwoTopLabel = "FINISHING DETAIL"
                }
            } else {
                finishingOptionTwoTopLabel = "FINISHING DETAIL"
            }
            finishingOptionTwoField = <div data-delay="0" onClick={(e) => { this.toggleDropdownOpen(e, "finishingOptionTwo-dropdown") }} className="dropdown-13 w-dropdown finishingOptionTwo-dropdown-top">
                <div className="dropdown-toggle-5 w-dropdown-toggle">
                    <div className="w-icon-dropdown-toggle">
                    </div>
                    <div className="text-block-154" style={{ textAlign: 'center', whiteSpace: 'pre-line' }}>{finishingOptionTwo && finishingOptionTwo.length > 1 ? finishingOptionTwoName : finishingOptionTwoTopLabel}</div>
                </div>
                <nav className="w-dropdown-list finishingOptionTwo-dropdown">
                    {finishingOptionTwoForCategory}
                </nav>
            </div>
        } else {
            finishingOptionTwoField = <div></div>
        }


        let frameGlassField
        if (finishingOptionOne && finishingOptionOne.length > 0 && finishingOptionTwo && finishingOptionTwo.length > 0 && (finishingCategory === "premium_frame" || finishingCategory === "standard_frame")) {
            frameGlassField = <div data-delay="0" onClick={(e) => { this.toggleDropdownOpen(e, "frameGlass-dropdown") }} className="dropdown-13 w-dropdown frameGlass-dropdown-top">
                <div className="dropdown-toggle-5 w-dropdown-toggle">
                    <div className="w-icon-dropdown-toggle">
                    </div>
                    <div className="text-block-155" style={{ textAlign: 'center', whiteSpace: 'pre-line' }}>{frameGlass && frameGlass.length > 1 ? frameGlassName : "ACRYLIC"}</div>
                </div>
                <nav className="w-dropdown-list frameGlass-dropdown">
                    {frameGlassOptions}
                </nav>
            </div>
        } else {
            frameGlassField = <div></div>
        }

        let frameStainField
        if (finishingOptionOne && finishingOptionOne.length > 0 && finishingCategory === "premium_frame" && finishingOptionTwo && finishingOptionTwo.length > 0 && frameGlass && frameGlass.length > 0) {
            frameStainField = <div data-delay="0" onClick={(e) => { this.toggleDropdownOpen(e, "frameStain-dropdown") }} className="dropdown-13 w-dropdown frameStain-dropdown-top">
                <div className="dropdown-toggle-5 w-dropdown-toggle">
                    <div className="w-icon-dropdown-toggle">
                    </div>
                    <div className="text-block-156" style={{ textAlign: 'center', whiteSpace: 'pre-line' }}>{frameStain && frameStain.length > 1 && frameStainName && frameStainName.length > 1 ? frameStainName : "FRAME STAIN"}</div>
                </div>
                <nav className="w-dropdown-list frameStain-dropdown">
                    {frameStainOptions}
                </nav>
            </div>
        } else {
            frameStainField = <div></div>
        }



        return (
            <div>
                <NavBar />
                <div className="container-11 w-container" id="PriceCalculator" style={{ width: "470px" }}>
                    {
                        this.state.loadingProducts ?
                            <div style={{ marginTop: "50%", textAlign: 'center' }}>
                                loading products...
                                </div>
                            :
                            <div>
                                {qtyField}
                                {paperField}
                                {printSizeField}
                                {finishingCategoryField}
                                {finishingOptionOneField}
                                {finishingOptionTwoField}
                                {frameGlassField}
                                {frameStainField}
                                <h3>Subtotal Price: {this.state.resultPrice}</h3>
                                <div>All orders are charged $10 for shipping</div>
                                <div className="button-28 w-button"
                                    onClick={() => this.calculatePrice()}
                                >Calculate</div>
                            </div>
                    }


                    <br />
                    {/* <h4 style={{ textAlign: "center", display: "block" }}>Total: {total}</h4> */}
                </div >
            </div>

        )
    }
}

export default PriceCalculator;
