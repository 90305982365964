import React, { Component } from "react";
import NavBar from "./components/NavBar.js";
import Honeybadger from '@honeybadger-io/js'
import ErrorBoundary from '@honeybadger-io/js'
import MuseaApi from "./modules/MuseaApi.js";
import axios from "axios";

import Auth from "./modules/Auth.js";
class PriceLists extends Component {
    constructor(props) {
        super(props);
        const hb_config = {
            api_key: process.env.REACT_APP_HB_KEY,
            environment: process.env.REACT_APP_CURRENT_ENV,
            revision: 'current'
        }

        this.honeybadger = Honeybadger.configure(hb_config)

        if (this.props.location.state) {
            props = this.props.location.state
        } else {
            props = this.props
        }

        this.state = {
        };
    }
    componentDidMount() {
        let museaApi = new MuseaApi
        museaApi.checkAndResetCache()
        this.getPriceList()
    }

    getPriceList() {
        //e.preventDefault();
        var token = Auth.getToken()
        axios
            .get("api/v2/price-lists",
                { headers: { 'token': token } },
            )
            .then(res => {
                console.log(res.data);
                return true;
            })
            .catch(err =>
                this.throwError(err)
            )
    };

    throwError(err) {
        let errorObject, stackTrace
        if (err.response) {
            errorObject = err.response.data.error
            if (err.response.data.error.more_info !== undefined) {
                stackTrace = err.response.data.error.more_info.join(" ,").split(",").join("\n")
            } else {
                stackTrace = ""
            }
        } else {
            errorObject = { status: 500, class_name: "" + err.name, developer_message: err.message }
            stackTrace = err.stack
        }
        let errorMessage = "\nERROR CODE: " + errorObject.status + "\n ERROR CLASS: " + errorObject.class_name + " \nERROR MESSAGE: " + errorObject.developer_message
        console.log("ERROR MESSAGE: " + errorMessage + "\n MORE INFO: " + stackTrace)

        this.setState({
            loading: false,
            query: "",
            errorState: true,
            errorMessage: errorMessage
        })
    }

    render() {
        return (
            <div>
                <NavBar />
                <div className="CreditBalance">
                    <div className="container-11 w-container">
                        <div className="text-block-32">
                        </div>
                    </div>

                    <div className="container-13 w-container">
                        <div className="text-block-34 text-block-35">
                            updating user...
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PriceLists;
