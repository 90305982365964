import React, { Component } from "react";
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Link } from "react-router-dom";
import MuseaApi from "../modules/MuseaApi.js"

class LineItemSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selections: this.props.selections,
      labOrder: this.props.labOrder,
      guid: this.props.guid,
      loadingProducts: true,
      products: this.props.products ? this.props.products : [],
      sortedBy: "",
      lineItemGuid: this.props.lineItemGuid ? this.props.lineItemGuid : '',
      lineItem: this.props.lineItem ? this.props.lineItem : '',
      qty: this.props.qty ? this.props.qty : 1,
      paperPricing: this.props.paperPricing ? this.props.paperPricing : "",
      paperPricingName: this.props.paperPricingName ? this.props.paperPricingName : "",
      printSizePricing: this.props.printSizePricing ? this.props.printSizePricing : "",
      printSizePricingName: this.props.printSizePricingName ? this.props.printSizePricingName : "",
      printBorder: this.props.printBorder ? this.props.printBorder : "",
      finishingServiceOptions: {},
      finishingCategory: this.props.finishingCategory ? this.props.finishingCategory : "",
      finishingCategoryName: this.props.finishingCategoryName ? this.props.finishingCategoryName : "",
      finishingOptionOne: this.props.finishingOptionOne ? this.props.finishingOptionOne : "",
      finishingOptionOneName: this.props.finishingOptionOneName ? this.props.finishingOptionOneName : "",
      finishingOptionTwo: this.props.finishingOptionTwo ? this.props.finishingOptionTwo : "",
      finishingOptionTwoName: this.props.finishingOptionTwoName ? this.props.finishingOptionTwoName : "",
      frameGlass: this.props.frameGlass ? this.props.frameGlass : "",
      frameGlassName: this.props.frameGlassName ? this.props.frameGlassName : "",
      frameStain: this.props.frameStain ? this.props.frameStain : "",
      frameStainName: this.props.frameStainName ? this.props.frameStainName : "",
      finishingPricing: this.props.finishingPricing ? this.props.finishingPricing : "",
      finishingDetailPricing: this.props.finishingDetailPricing ? this.props.finishingDetailPricing : "",
      errorState: false
    };

    this.clearSelections = this.clearSelections.bind(this);
    this.handleFinishingServiceChange = this.handleFinishingServiceChange.bind(this);
    this.handlePaperPricingChange = this.handlePaperPricingChange.bind(this);
    this.handlePrintSizeChange = this.handlePrintSizeChange.bind(this);
    this.handlePrintBorderChange = this.handlePrintBorderChange.bind(this);
    this.handleFinishingServiceCategoryChange = this.handleFinishingServiceCategoryChange.bind(this);
    this.handleFinishingOptionOneChange = this.handleFinishingOptionOneChange.bind(this);
    this.handleFinishingOptionTwoChange = this.handleFinishingOptionTwoChange.bind(this);
    this.handleFrameGlassChange = this.handleFrameGlassChange.bind(this);
    this.handleFrameStainChange = this.handleFrameStainChange.bind(this);
    //this.handleFinishingServiceDetailChange = this.handleFinishingServiceDetailChange.bind(this);
    this.handleQtyChange = this.handleQtyChange.bind(this);
    this.setAllStatesForLineItem = this.setAllStatesForLineItem.bind(this);
  }


  componentDidMount() {
    const script = document.createElement("style");
    script.innerHtml = " ";
    document.body.appendChild(script);
    if (this.state.lineItemGuid && this.state.lineItemGuid.length > 0) {
      if (this.state.products.length < 1) {
        this.getProductsFromApi()
          .then(data => { this.setAllStatesForLineItem() })
      } else {
        this.setAllStatesForLineItem()
      }
    } else {
      this.getProductsFromApi()
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setState({
        selections: nextProps.selections,
        labOrder: nextProps.labOrder,
      });
    }
  }

  async getProductsFromApi() {
    let paperPricingFunction = this.handlePaperPricingChange
    let MuseaAPI = new MuseaApi()
    await MuseaAPI.getProducts().then(products => {
      let thePaperOptions = MuseaAPI.paperOptions(products, paperPricingFunction)
      this.setState({
        products: products,
        paperOptions: thePaperOptions,
        loadingProducts: false,
        errorState: false
      })
    })
      .catch(err =>
        this.throwError(err))
  }

  async setAllStatesForLineItem() {
    let MuseaAPI = new MuseaApi()
    let formattedOptions = MuseaAPI.formatPrintSizeOptions(this.state.paperPricing, this.state.products, this.handlePrintSizeChange)
    this.setupFinishingServiceOptions(formattedOptions)
  }

  clearSelections() {
    this.setState({
      qty: 1,
      paperPricing: "",
      paperPricingName: "",
      printSizePricing: "",
      printSizePricingName: "",
      printBorder: "",
      formattedPrintSizeOptions: "",
      finishingServiceOptions: {},
      finishingCategory: "",
      finishingCategoryName: "",
      finishingOptionOne: "",
      finishingOptionOneName: "",
      finishingOptionTwo: "",
      finishingOptionTwoName: "",
      finishingPricing: "",
      finishingOptionOneForCategory: [],
      frameGlass: "",
      frameGlassName: "",
      frameStain: "",
      frameStainName: "",
      selectedFinishDetails: "",
      selectedFinishDetailsName: "",
    })
    this.props.updateSelections("clearSelections", "", [])
    return true
  }

  async handlePaperPricingChange(e, name, val, valName) {
    //     // console.log(val);
    //     console.log(valName);
    // // Adding Canvas - Black Wrap
    //     if(valName === "Canvas - Black Wrap"){
    //       document.getElementsByClassName("ReactCrop__crop-selection");
    //     }

    // // 

    let formattedOptions
    let MuseaAPI = new MuseaApi()
    //(e, "paperPricing", objGuid, obj.attributes.name)
    if (e !== null && e.type !== null) {
      e.preventDefault()
    }
    var objName = name + "Name"
    var openDiv = name + "-dropdown"
    if (e !== null && e.type !== null) {
      this.toggleDropdownOpen(e, openDiv)
    }

    //this.state.paperPricing !== null &&
    formattedOptions = MuseaAPI.formatPrintSizeOptions(val, this.state.products, this.handlePrintSizeChange)

    this.setState({
      [name]: val,
      [objName]: valName,
      printSizePricing: '',
      printSizePricingName: "",
      formattedPrintSizeOptions: formattedOptions,
      finishingCategoryName: '',
      finishingCategory: '',
      finishingPricing: "",
      finishingDetailPricing: "",
      finishingOptionOneForCategory: {},
      finishingOptionTwoForCategory: "",
      finishingOptionOne: "",
      finishingOptionOneName: "",
      finishingOptionTwo: "",
      finishingOptionTwoName: "",
      frameGlass: "",
      frameGlassName: "",
      frameStain: "",
      frameStainName: "",
      selectedFinishDetails: "",
      selectedFinishDetailsName: "",
    })

    return true
  }

  async handlePrintBorderChange(e, name, val, valName) {
    let formattedOptions
    let MuseaAPI = new MuseaApi()
    if (e !== null && e.type !== null) {
      e.preventDefault()
    }


    console.log(e, name, val, valName)
    
    var objName = name + "Name"
    var openDiv = name + "-dropdown"
    if (e !== null && e.type !== null) {
      this.toggleDropdownOpen(e, openDiv)
    }
    this.props.updatePricingSelection(val, name)

    if (this.state.paperPricing !== null && name === "paperPricing") {
      console.log("Line Item Side Bar val" , val)
      formattedOptions = MuseaAPI.formatPrintSizeOptions(val, this.state.products, this.handlePrintSizeChange)
    } else {
      formattedOptions = this.state.formattedPrintSizeOptions
    }

    this.setState({
      [name]: val,
      [objName]: valName,
      formattedPrintSizeOptions: formattedOptions
    })

    return true
  }

  async handlePrintSizeChange(e, name, val, valName) {
    let finishingServices
    let MuseaAPI = new MuseaApi()
    //(e, "printSizePricing", objGuid, obj.name)
    if (e !== null && e.type !== null) {
      e.preventDefault()
    }
    var objName = name + "Name"
    var openDiv = name + "-dropdown"
    if (e !== null && e.type !== null) {
      this.toggleDropdownOpen(e, openDiv)
    }
    //const name = e.target.name;
    //const val = e.target.value;

    this.props.updateCropOrientation(this.state.paperPricing, val, this.state.products, "printSizeChange")
    this.props.updatePricingSelection(val, "printSizePricing")
    this.props.updatePricingSelection("", "finishingCategory")
    this.props.updatePricingSelection("", "finishingPricing")
    this.props.updatePricingSelection("", "finishingDetailPricing")
    console.log("val: " + val)
    console.log("this.state.printSizePricing: " + this.state.printSizePricing)
    if (this.state.printSizePricing !== val) {
      finishingServices = await MuseaAPI.getFinishingServices(val)
      // await 
    } else {
      finishingServices = this.state.finishingServiceOptions
    }

    let finishingServiceOptions = MuseaAPI.mergeAttributesForFinishingOptions(finishingServices, this.handleFinishingOptionOneChange)

    this.setState({
      [objName]: valName,
      [name]: val,
      finishingCategoryName: '',
      finishingCategory: '',
      finishingServiceOptions: finishingServiceOptions,
      finishingPricing: "",
      finishingDetailPricing: "",
      finishingOptionOneForCategory: {},
      finishingOptionTwoForCategory: "",
    })
    return true
  }

  async handleFinishingServiceCategoryChange(e, name, val, valName) {
    let MuseaAPI = new MuseaApi()
    //(e, "finishingCategory", "none", "none")
    if (e !== null && e.type !== null) {
      e.preventDefault()
    }
    var objName = name + "Name"
    var openDiv = name + "-dropdown"
    if (e !== null && e.type !== null) {
      this.toggleDropdownOpen(e, openDiv)
    }
    //const name = e.target.name;
    //const val = e.target.value;
    let catOneOptions = MuseaAPI.setupFinishCatOneOptions(val, this.state.finishingServiceOptions, this.state.paperPricingName)
    if (val === "mount") {
      //&& catOneOptions.length === 1
      let defaultMount = catOneOptions[0].key
      this.props.updatePricingSelection("mount", "finishingCategory")
      this.props.updatePricingSelection("", "finishingPricing")
      this.props.updatePricingSelection("", "finishingDetailPricing")
      //this.props.updatePricingSelection(defaultMount, "finishingPricing")

      let catTwoOptions = MuseaAPI.setupFinishCatTwoOptions(defaultMount, "mount", "mount", this.state.finishingServiceOptions, this.handleFinishingOptionTwoChange)

      this.setState({
        [name]: val,
        [objName]: valName,
        finishingOptionOneForCategory: catOneOptions,
        finishingOptionTwoForCategory: catTwoOptions,
        finishingPricing: "",
        finishingDetailPricing: "",
        finishingOptionOne: "",
        finishingOptionTwo: ""
      })
    } else if (val === "four_ply_mat") {
      this.props.updatePricingSelection("four_ply_mat", "finishingCategory")
      this.props.updatePricingSelection("", "finishingPricing")
      this.props.updatePricingSelection("", "finishingDetailPricing")
      this.setState({
        [name]: val,
        [objName]: valName,
        finishingOptionOneForCategory: catOneOptions,
        finishingOptionTwoForCategory: "",
        finishingPricing: "",
        finishingDetailPricing: "",
        finishingOptionOne: "",
        finishingOptionTwo: ""
      })
    } else if (val === "eight_ply_mat") {
      this.props.updatePricingSelection("eight_ply_mat", "finishingCategory")
      this.props.updatePricingSelection("", "finishingPricing")
      this.props.updatePricingSelection("", "finishingDetailPricing")
      this.setState({
        [name]: val,
        [objName]: valName,
        finishingOptionOneForCategory: catOneOptions,
        finishingOptionTwoForCategory: "",
        finishingPricing: "",
        finishingDetailPricing: "",
        finishingOptionOne: "",
        finishingOptionTwo: ""
      })
    } else if (val === "contemporary_frame") {

      this.props.updatePricingSelection("contemporary_frame", "finishingCategory")
      this.props.updatePricingSelection("", "finishingPricing")
      this.props.updatePricingSelection("", "finishingDetailPricing")
      this.setState({
        [name]: val,
        [objName]: valName,
        finishingOptionOneForCategory: catOneOptions,
        finishingPricing: "",
        finishingDetailPricing: "",
        finishingOptionOne: "",
        finishingOptionTwo: ""
      })
    } else if (val === "hardwood_frame") {

      this.props.updatePricingSelection("hardwood_frame", "finishingCategory")
      this.props.updatePricingSelection("", "finishingPricing")
      this.props.updatePricingSelection("", "finishingDetailPricing")
      this.setState({
        [name]: val,
        [objName]: valName,
        finishingOptionOneForCategory: catOneOptions,
        finishingPricing: "",
        finishingDetailPricing: "",
        finishingOptionOne: "",
        finishingOptionTwo: ""
      })
    } else if (val === "desktop_frame") {

      this.props.updatePricingSelection("desktop_frame", "finishingCategory")
      this.props.updatePricingSelection("", "finishingPricing")
      this.props.updatePricingSelection("", "finishingDetailPricing")
      this.setState({
        [name]: val,
        [objName]: valName,
        finishingOptionOneForCategory: catOneOptions,
        finishingPricing: "",
        finishingDetailPricing: "",
        finishingOptionOne: "",
        finishingOptionTwo: ""
      })
    } else if (val === "hardwood_float_canvas_frame") {

      this.props.updatePricingSelection("hardwood_float_canvas_frame", "finishingCategory")
      this.props.updatePricingSelection("", "finishingPricing")
      this.props.updatePricingSelection("", "finishingDetailPricing")
      this.setState({
        [name]: val,
        [objName]: valName,
        finishingOptionOneForCategory: catOneOptions,
        finishingPricing: "",
        finishingDetailPricing: "",
        finishingOptionOne: "",
        finishingOptionTwo: ""
      })
    } else if (val === "none") {
      this.props.updatePricingSelection("none", "finishingCategory")
      this.props.updatePricingSelection("", "finishingPricing")
      this.props.updatePricingSelection("", "finishingDetailPricing")
      this.setState({
        finishingCategory: "none",
        finishingCategoryName: "NONE",
        finishingPricing: "",
        finishingDetailPricing: "",
        finishingOptionOne: "",
        finishingOptionTwo: ""
      })
    }
    return true
  }

  async handleFinishingOptionOneChange(e, name, val, valName) {
    let MuseaAPI = new MuseaApi()
    //(e, "finishingOptionOne", keyThreeFourths, nameThreeFourths)
    if (e !== null && e.type !== null) {
      e.preventDefault()
    }
    var objName = name + "Name"
    var openDiv = name + "-dropdown"
    if (e !== null && e.type !== null) {
      this.toggleDropdownOpen(e, openDiv)
    }
    if (this.state.finishingCategory === "hardwood_frame") {
      let catTwoOptions = MuseaAPI.setupFinishCatTwoOptions(val, "category", this.state.finishingCategory, this.state.finishingServiceOptions, this.handleFinishingOptionTwoChange)

      this.setState({
        [name]: val,
        [objName]: valName,
        finishingOptionTwoForCategory: catTwoOptions,
        finishingPricing: "",
        finishingDetailPricing: "",
        finishingOptionTwo: "",
        frameGlassOption: "",
        frameStainOption: ""
      })
    } else if (this.state.finishingCategory === "contemporary_frame") {
      let catTwoOptions = MuseaAPI.setupFinishCatTwoOptions(val, "category", this.state.finishingCategory, this.state.finishingServiceOptions, this.handleFinishingOptionTwoChange)

      this.setState({
        [name]: val,
        [objName]: valName,
        finishingOptionTwoForCategory: catTwoOptions,
        finishingPricing: "",
        finishingDetailPricing: "",
        finishingOptionTwo: "",
        frameGlassOption: "",
        frameStainOption: ""
      })
    } else if (this.state.finishingCategory === "desktop_frame") {
      let catTwoOptions = MuseaAPI.setupFinishCatTwoOptions(val, "category", this.state.finishingCategory, this.state.finishingServiceOptions, this.handleFinishingOptionTwoChange)

      this.setState({
        [name]: val,
        [objName]: valName,
        finishingOptionTwoForCategory: catTwoOptions,
        finishingPricing: "",
        finishingDetailPricing: "",
        finishingOptionTwo: "",
        frameGlassOption: "",
        frameStainOption: ""
      })
    } else if (this.state.finishingCategory === "hardwood_float_canvas_frame") {
      let catTwoOptions = MuseaAPI.setupFinishCatTwoOptions(val, "category", this.state.finishingCategory, this.state.finishingServiceOptions, this.handleFinishingOptionTwoChange)

      this.setState({
        [name]: val,
        [objName]: valName,
        finishingOptionTwoForCategory: catTwoOptions,
        finishingPricing: "",
        finishingDetailPricing: "",
        finishingOptionTwo: "",
        frameGlassOption: "",
        frameStainOption: ""
      })
    } else if (this.state.finishingCategory === "four_ply_mat" || this.state.finishingCategory === "eight_ply_mat" || this.state.finishingCategory === "mount") {
      this.props.updatePricingSelection(val, "finishingPricing")
      this.props.updatePricingSelection("", "finishingDetailPricing")
      this.setState({
        [name]: val,
        [objName]: valName,
        finishingPricing: val,
        finishingDetailPricing: "",
        finishingOptionTwo: "",
        frameGlassOption: "",
        frameStainOption: ""
      })
    }
    return true
  }

  async handleFinishingOptionTwoChange(e, name, val, valName) {
    let frameGlassOptions
    let MuseaAPI = new MuseaApi()
    //(e, "finishingOptionTwo", key, name)
    if (e !== null && e.type !== null) {
      e.preventDefault()
    }
    var objName = name + "Name"
    var openDiv = name + "-dropdown"
    if (e !== null && e.type !== null) {
      this.toggleDropdownOpen(e, openDiv)
    }
    console.log("option 2 category ln417: " + this.state.finishingCategory)
    if (val.length > 0) {

      if (this.state.finishingCategory === "hardwood_frame") {
        frameGlassOptions = MuseaAPI.setupFrameGlassOptions(val, this.state.finishingServiceOptions, this.state.finishingOptionOne, this.handleFrameGlassChange)
      }
      if (this.state.finishingCategory === "desktop_frame") {
        frameGlassOptions = MuseaAPI.setupFrameGlassOptionsDesktop(val, this.state.finishingServiceOptions, this.state.finishingOptionOneName, this.state.finishingOptionOne, this.handleFrameGlassChange)
      }
      if (this.state.finishingCategory === "hardwood_float_canvas_frame") {
        frameGlassOptions = MuseaAPI.setupFrameGlassOptionsHardwoodFloat(val, this.state.finishingServiceOptions, this.state.finishingOptionOne, this.handleFrameGlassChange)
      }
      if (this.state.finishingCategory === "contemporary_frame") {
        frameGlassOptions = MuseaAPI.setupUvFrameGlassOptions(val, this.state.finishingServiceOptions, this.state.finishingOptionOne, this.handleFrameGlassChange)
      }
      if (this.state.finishingCategory === "mount") {
        this.props.updatePricingSelection(val, "finishingDetailPricing")
        this.setState({
          [name]: val,
          [objName]: valName,
          finishingDetailPricing: val,
        })
      } else {
        this.setState({
          [name]: val,
          [objName]: valName,
          finishingPricing: "",
          finishingDetailPricing: "",
          frameGlassOptions: frameGlassOptions,
          frameStainOption: "",
          frameStainName: ""
        })
      }
    }
    return true
  }

  async handleFrameGlassChange(e, name, val, valName) {
    let frameStainOptions
    if (e !== null && e.type !== null) {
      e.preventDefault()
    }
    var objName = name + "Name"
    var openDiv = name + "-dropdown"
    if (e !== null && e.type !== null) {
      this.toggleDropdownOpen(e, openDiv)
    }
    if (val.length > 0) {

      if (this.state.finishingCategory === "hardwood_frame") {
        const MuseaAPI = new MuseaApi()
        frameStainOptions = MuseaAPI.setupFrameStainOptions(val, this.state.finishingServiceOptions, this.handleFrameStainChange)
      }
      if (this.state.finishingCategory === "desktop_frame") {
        const MuseaAPI = new MuseaApi()
        if (val.includes("sample")) {
          let frameGlassOne = this.state.frameGlassOptions.filter(item => !item.key.includes("sample"))[0].key
          frameStainOptions = MuseaAPI.setupFrameStainOptions(frameGlassOne, this.state.finishingServiceOptions, this.handleFrameStainChange)
        } else {
          frameStainOptions = MuseaAPI.setupFrameStainOptions(val, this.state.finishingServiceOptions, this.handleFrameStainChange)
        }
      }
      if (this.state.finishingCategory === "hardwood_float_canvas_frame") {
        const MuseaAPI = new MuseaApi()
        frameStainOptions = MuseaAPI.setupFrameStainOptions(val, this.state.finishingServiceOptions, this.handleFrameStainChange)
      }

      this.props.updatePricingSelection(val, "finishingPricing")
      this.props.updatePricingSelection("", "finishingDetailPricing")
      this.setState({
        [name]: val,
        [objName]: valName,
        frameStainOptions: frameStainOptions,
      })
    }
    return true
  }

  async handleFrameStainChange(e, name, val, valName) {
    if (e !== null && e.type !== null) {
      e.preventDefault()
    }
    var objName = name + "Name"
    var openDiv = name + "-dropdown"
    if (e !== null && e.type !== null) {
      this.toggleDropdownOpen(e, openDiv)
    }
    if (val.length > 0) {
      let isValidLineItem = this.validateLineItem(val)
      this.props.updatePricingSelection(val, "finishingDetailPricing")
      this.setState({
        [name]: val,
        [objName]: valName,
        isValidLineItem: isValidLineItem,
      })
    }
    return true
  }

  handleFinishingServiceChange(e, name, val, valName) {
  }

  toggleDropdownOpen(e, openDiv) {
    if (e !== null && e.type !== null) {
      e.preventDefault()
    }
    var openDivClass = "." + openDiv
    var openDivClassTop = "." + openDiv + "-top"
    let elem
    if (e.target.classList.contains("w--open")) {
      e.target.classList.remove("w--open")
      document.querySelector(openDivClass).classList.remove('w--open');
      elem = document.querySelector(openDivClassTop);
      elem.style.zIndex = '100'
      //this.props.toggleOpenDiv(e.target, "close")
    } else {
      var openDivs = document.querySelectorAll('.w--open')
      if (openDivs !== null && openDivs.length >= 1) {
        openDivs.forEach(div => {
          div.classList.remove("w--open")
          div.parentElement.style.zIndex = '100'
          div.style.zIndex = '100'
          //console.log("classList " + div.classList)
          //console.log("zindex " + div.style.zIndex)
        })
      }
      e.target.classList.add("w--open")
      document.querySelector(openDivClass).classList.add('w--open');
      elem = document.querySelector(openDivClassTop);
      elem.style.zIndex = '999'
      //this.props.toggleOpenDiv(e.target, "open")
      //document.addEventListener('mousedown', this.handleClickOutside(e.target));
    }
  }


  validateLineItem = (val) => {
    return true
  }

  handleQtyChange(e) {
    if (e.target.validity.valid) {
      this.props.updatePricingSelection(e.target.value, "qty")
      this.setState({
        qty: e.target.value
      })
    } else {
      console.log("not doing nothing")
    }
  }

  throwError(err) {
    let errorObject, stackTrace
    if (err.response) {
      errorObject = err.response.data.error
      if (err.response.data.error.more_info !== undefined) {
        stackTrace = err.response.data.error.more_info.join(" ,").split(",").join("\n")
      } else {
        stackTrace = ""
      }
    } else {
      errorObject = { status: 500, class_name: "" + err.name, developer_message: err.message }
      stackTrace = err.stack
    }
    let errorMessage = "\nERROR CODE: " + errorObject.status + "\n ERROR CLASS: " + errorObject.class_name + " \nERROR MESSAGE: " + errorObject.developer_message
    console.log("ERROR MESSAGE: " + errorMessage + "\n MORE INFO: " + stackTrace)

    this.setState({
      loading: false,
      query: "",
      errorState: true,
      errorMessage: errorMessage
    })
  }

  async setupFinishingServiceOptions(formattedOptions) {
    let category, categoryName, finishingServices, finishingServiceOptions, catOneOptions, catTwoOptions, frameGlassOptions, frameStainOptions, finishingOptionOne, finishingOptionOneName, finishingOptionTwo, finishingOptionTwoName, frameGlass, frameGlassName, frameStain, frameStainName
    let MuseaAPI = new MuseaApi()

    if (this.state.printSizePricing !== null) {
      finishingServices = await MuseaAPI.getFinishingServices(this.state.printSizePricing)
      finishingServiceOptions = MuseaAPI.mergeAttributesForFinishingOptions(finishingServices, this.handleFinishingOptionOneChange)
      this.props.updateCropOrientation(this.state.paperPricing, this.state.printSizePricing, this.state.products, "setupLineItem")
      //this.props.setCropOptionsForLineItem()

      if (this.state.finishingCategory !== null) {
        category = this.state.finishingCategory.toLowerCase()
        categoryName = this.state.finishingCategory.toUpperCase()
        console.log("category line 582: " + category)

        //this.setupFinishingOptionsForCategory(this.state.finishingCategory)

        let MuseaAPI = new MuseaApi()

        catOneOptions = await MuseaAPI.setupFinishCatOneOptions(this.state.finishingCategory, finishingServiceOptions, this.state.paperPricingName)

        if (category === "mount") {
          let defaultMount = catOneOptions[0].key
          catTwoOptions = MuseaAPI.setupFinishCatTwoOptions(defaultMount, "mount", this.state.finishingCategory, finishingServiceOptions, this.handleFinishingOptionTwoChange)

          let finishingOptionOneDetails = this.formatMountNameFromFinish(this.props.lineItem, catOneOptions)
          finishingOptionOneName = finishingOptionOneDetails[0]
          finishingOptionOne = finishingOptionOneDetails[1]

          let finishingOptionTwoDetails = this.formatMountDetailFromFinish(this.props.lineItem, catTwoOptions)
          finishingOptionTwoName = finishingOptionTwoDetails[0]
          finishingOptionTwo = finishingOptionTwoDetails[1]

        } else if (category === "four_ply_mat") {
          catTwoOptions = []
          let finishingOptionOneDetails = this.formatOptionOneNameFromFinish(this.props.lineItem, catOneOptions)
          finishingOptionOneName = finishingOptionOneDetails[0]
          finishingOptionOne = finishingOptionOneDetails[1]
        } else if (category === "eight_ply_mat") {
          catTwoOptions = []
          let finishingOptionOneDetails = this.formatOptionOneNameFromFinish(this.props.lineItem, catOneOptions)
          finishingOptionOneName = finishingOptionOneDetails[0]
          finishingOptionOne = finishingOptionOneDetails[1]
        } else if (category === "hardwood_frame") {

          let finishingOptionOneDetails = this.getFrameProfileKeyFromFinish(this.props.lineItem, catOneOptions)
          finishingOptionOneName = finishingOptionOneDetails[0]
          finishingOptionOne = finishingOptionOneDetails[1]

          catTwoOptions = MuseaAPI.setupFinishCatTwoOptions(finishingOptionOne, "category", this.state.finishingCategory, finishingServiceOptions, this.handleFinishingOptionTwoChange)

          let finishingOptionTwoDetails = this.getFrameMatKeyFromFinish(this.props.lineItem, catTwoOptions)
          finishingOptionTwoName = finishingOptionTwoDetails[0]
          finishingOptionTwo = finishingOptionTwoDetails[1]

          frameGlassOptions = MuseaAPI.setupFrameGlassOptions(finishingOptionTwo, finishingServiceOptions, finishingOptionOne, this.handleFrameGlassChange)

          let frameGlassDetails = this.getFrameGlassKeyFromFinish(this.props.lineItem, frameGlassOptions)
          frameGlassName = frameGlassDetails[0]
          frameGlass = frameGlassDetails[1]

          frameStainOptions = MuseaAPI.setupFrameStainOptions(frameGlass, finishingServiceOptions, this.handleFrameStainChange)

          let frameStainDetails = this.getFrameStainKeyFromFinish(this.props.lineItem, frameStainOptions)
          frameStainName = frameStainDetails[0]
          frameStain = frameStainDetails[1]
        } else if (category === "contemporary_frame") {

          let finishingOptionOneDetails = this.getFrameProfileKeyFromFinish(this.props.lineItem, catOneOptions)
          finishingOptionOneName = finishingOptionOneDetails[0]
          finishingOptionOne = finishingOptionOneDetails[1]

          catTwoOptions = MuseaAPI.setupFinishCatTwoOptions(finishingOptionOne, "category", this.state.finishingCategory, finishingServiceOptions, this.handleFinishingOptionTwoChange)

          let finishingOptionTwoDetails = this.getFrameMatKeyFromFinish(this.props.lineItem, catTwoOptions)
          finishingOptionTwoName = finishingOptionTwoDetails[0]
          finishingOptionTwo = finishingOptionTwoDetails[1]

          frameGlassOptions = MuseaAPI.setupFrameGlassOptions(finishingOptionTwo, finishingServiceOptions, finishingOptionOne, this.handleFrameGlassChange)

          let frameGlassDetails = this.getFrameGlassKeyFromFinish(this.props.lineItem, frameGlassOptions)
          frameGlassName = frameGlassDetails[0]
          frameGlass = frameGlassDetails[1]
        } else if (category === "hardwood_float_canvas_frame") {

          let finishingOptionOneDetails = this.getFrameProfileKeyFromFinish(this.props.lineItem, catOneOptions)
          finishingOptionOneName = finishingOptionOneDetails[0]
          finishingOptionOne = finishingOptionOneDetails[1]

          catTwoOptions = MuseaAPI.setupFinishCatTwoOptions(finishingOptionOne, "category", this.state.finishingCategory, finishingServiceOptions, this.handleFinishingOptionTwoChange)

          let finishingOptionTwoDetails = this.getFrameMatKeyFromFinish(this.props.lineItem, catTwoOptions)
          finishingOptionTwoName = finishingOptionTwoDetails[0]
          finishingOptionTwo = finishingOptionTwoDetails[1]

          frameGlassOptions = MuseaAPI.setupFrameGlassOptions(finishingOptionTwo, finishingServiceOptions, finishingOptionOne, this.handleFrameGlassChange)

          let frameGlassDetails = this.getFrameGlassKeyFromFinish(this.props.lineItem, frameGlassOptions)
          frameGlassName = frameGlassDetails[0]
          frameGlass = frameGlassDetails[1]

          frameStainOptions = MuseaAPI.setupFrameStainOptions(frameGlass, finishingServiceOptions, this.handleFrameStainChange)

          let frameStainDetails = this.getFrameStainKeyFromFinish(this.props.lineItem, frameStainOptions)
          frameStainName = frameStainDetails[0]
          frameStain = frameStainDetails[1]

        } else {
          category = "none"
          categoryName = "NONE"
          catOneOptions = []
          catTwoOptions = []
        }
      } else {
        category = "none"
        categoryName = "NONE"
        catOneOptions = []
        catTwoOptions = []
      }
    } else {
      finishingServices = this.state.finishingServiceOptions
      finishingServiceOptions = this.state.finishingServiceOptions
    }

    this.setState({
      formattedPrintSizeOptions: formattedOptions,
      finishingServiceOptions: finishingServiceOptions,
      finishingCategory: category,
      finishingCategoryName: categoryName,
      finishingOptionOneForCategory: catOneOptions,
      finishingOptionOne: finishingOptionOne,
      finishingOptionOneName: finishingOptionOneName,
      finishingOptionTwoForCategory: catTwoOptions,
      finishingOptionTwo: finishingOptionTwo,
      finishingOptionTwoName: finishingOptionTwoName,
      frameGlassOptions: frameGlassOptions,
      frameGlassName: frameGlassName,
      frameGlass: frameGlass,
      frameStainOptions: frameStainOptions,
      frameStainName: frameStainName,
      frameStain: frameStain,
    })
    return true
  }


  formatMountNameFromFinish(fullLineItem, catOptions) {
    let matchingService, optionOneName, optionOneKey
    if (catOptions.length > 0) {
      matchingService = catOptions.filter(obj => (obj.key === this.state.lineItem.finishingServiceGuid))
    } else {
      matchingService = []
    }

    if (matchingService.length > 0) {
      optionOneName = matchingService[0].props.formattedname
      optionOneKey = matchingService[0].key
    }

    return [optionOneName, optionOneKey]
  }

  formatMountDetailFromFinish(fullLineItem, mountDetailOptions) {
    let matchingService, optionTwoName, optionTwoKey
    if (mountDetailOptions.length > 0) {
      matchingService = mountDetailOptions.filter(obj => (obj.key === this.state.lineItem.finishingServiceDetailGuid))
    } else {
      matchingService = []
    }

    if (matchingService.length > 0) {
      optionTwoName = matchingService[0].props.formattedname
      optionTwoKey = matchingService[0].key
    }

    return [optionTwoName, optionTwoKey]
  }

  formatOptionOneNameFromFinish(fullLineItem, catOptions) {
    let matchingService, optionOneName, optionOneKey
    if (catOptions.length > 0) {
      matchingService = catOptions[0].filter(obj => (obj.key === this.state.lineItem.finishingServiceGuid))
    } else {
      matchingService = []
    }

    if (matchingService.length > 0) {
      optionOneName = matchingService[0].props.formattedname
      optionOneKey = matchingService[0].key
    }

    return [optionOneName, optionOneKey]
  }

  getFrameProfileKeyFromFinish(fullLineItem, frameOptions) {
    let matchingService, optionOneName, optionOneKey
    let newProfile = fullLineItem.frameProfile.replace(' 3/4', '-.75').replace(' 1.5', '-1.5')
    if (frameOptions.length > 0) {
      matchingService = frameOptions.filter(obj => (obj.key === newProfile))
    } else {
      matchingService = []
    }

    if (matchingService.length > 0) {
      optionOneName = matchingService[0].props.formattedname
      optionOneKey = matchingService[0].key
    }

    return [optionOneName, optionOneKey]
  }

  getFrameMatKeyFromFinish(fullLineItem, frameOptions) {
    let matchingService, optionTwoName, optionTwoKey
    if (frameOptions[0].length > 0) {
      matchingService = frameOptions[0].filter(obj => (obj.key === fullLineItem.finishingDetailTwo))
    } else {
      matchingService = []
    }

    if (matchingService.length > 0) {
      optionTwoName = matchingService[0].props.formattedname
      optionTwoKey = matchingService[0].key
    }
    return [optionTwoName, optionTwoKey]
  }

  getFrameGlassKeyFromFinish(fullLineItem, frameGlassOptions) {
    let matchingService, frameGlassName, frameGlassKey
    if (frameGlassOptions[0].length > 0) {
      matchingService = frameGlassOptions[0].filter(obj => (
        obj.props.formattedname.toLowerCase().includes(fullLineItem.finishingDetailThree.toLowerCase())
      ))
    } else {
      matchingService = []
    }

    if (matchingService.length > 0) {
      frameGlassName = matchingService[0].props.formattedname
      frameGlassKey = matchingService[0].key
    }
    return [frameGlassName, frameGlassKey]
  }

  getFrameStainKeyFromFinish(fullLineItem, frameStainOptions) {
    let matchingService, frameStainName, frameStainKey
    if (frameStainOptions.length > 0) {
      matchingService = frameStainOptions.filter(obj => (
        fullLineItem.finishingDetailOne.toLowerCase().includes(obj.props.formattedname.toLowerCase())
      ))
    } else {
      matchingService = []
    }
    if (matchingService.length > 0) {
      frameStainName = matchingService[0].props.formattedname
      frameStainKey = matchingService[0].key
    }
    return [frameStainName, frameStainKey]
  }



  render() {
    const { selections, paperPricing, paperPricingName, qty, printSizePricing, printSizePricingName, finishingCategory, finishingCategoryName, finishingOptionOneForCategory, finishingOptionOne, finishingOptionOneName, finishingOptionTwo, finishingOptionTwoName, finishingOptionTwoForCategory, frameStain, frameStainName, frameStainOptions, frameGlass, frameGlassName, frameGlassOptions } = this.state;
    let selectButton, printBorderField, printBorder,printBorderName

    printBorder = []

    if (this.state.lineItemGuid && this.state.lineItemGuid.length > 0) {
      selectButton = <div></div>
    } else if (selections.length > 0) {
      selectButton = <div>
        <a onClick={() => { this.clearSelections() }}
          className="button-44 w-button">
          RESET
        </a>
      </div>
    } else {
      selectButton = <div>
        <a data-for='select_all' data-tip='React-tooltip' onClick={() => { this.props.updateSelections("addAll", "", selections); console.log("Line Item Sidebar", selections); }}
          className="button-44 w-button">
          SELECT ALL
        </a>

        <ReactTooltip id='select_all' place="bottom" type="dark" effect="float">
          <span>If you just finished uploading files <br /> and SELECT ALL does not seem to be working, <br /> please reload after a few seconds <br /> as the uploaded images might still be processing. </span>
        </ReactTooltip>
      </div>
    }


    let qtyField = <div>
      <input type="text"
        className="quantityField"
        style={{ marginTop: '40px' }}
        placeholder="QUANTITY"
        name="qty"
        onChange={this.handleQtyChange}
        value={qty}
        pattern="[0-9]*" />
    </div>


    let paperField

    if (selections.length > 0) {
      paperField = <div data-delay="0" onClick={(e) => { this.toggleDropdownOpen(e, "paperPricing-dropdown") }} className="dropdown-13 w-dropdown paperPricing-dropdown-top">
        <div className="dropdown-toggle-5 w-dropdown-toggle" style={{}}>
          <div className="w-icon-dropdown-toggle">
          </div>
          <div>

            <div className="text-block-153" style={{ textAlign: 'center', whiteSpace: 'pre-line' }}>{paperPricing.length > 0 ? paperPricingName : "PAPER TYPE"}</div>

          </div>
        </div>
        <nav className="w-dropdown-list dropdown-list-4 paperPricing-dropdown"
          style={{ width: '150px', height: '400px', overflowY: 'scroll' }} >
          {this.state.paperOptions}
        </nav>
      </div>
    } else {
      paperField = <div data-delay="0" onClick={() => { alert("Please select at least one photo to begin.") }} className="dropdown-13 w-dropdown paper-type-dropdown-top">
        <div className="dropdown-toggle-5 w-dropdown-toggle" style={{}}>
          <div className="w-icon-dropdown-toggle">
          </div>
          <div>
            <div className="text-block-153" style={{ textAlign: 'center', whiteSpace: 'pre-line' }}>{paperPricing.length > 0 ? paperPricingName : "PAPER TYPE"}</div>
          </div>
        </div>
      </div>
    }

    let printSizeField
    if (selections.length > 0 && paperPricing.length > 0) {
      printSizeField = <div data-delay="0" onClick={(e) => { this.toggleDropdownOpen(e, "printSizePricing-dropdown") }} className="dropdown-13 w-dropdown printSizePricing-dropdown-top">
        <div className="dropdown-toggle-5 w-dropdown-toggle">
          <div className="w-icon-dropdown-toggle">
          </div>
          <div className="text-block-153" style={{ textAlign: 'center', whiteSpace: 'pre-line' }}>{printSizePricing && printSizePricing.length > 1 && printSizePricingName.length > 1 ? printSizePricingName : "PRINT SIZE"}</div>
        </div>
        <nav className="w-dropdown-list dropdown-list-4 printSizePricing-dropdown"
          style={{ width: '150px', height: '400px', overflowY: 'scroll' }} >
          {this.state.formattedPrintSizeOptions}
        </nav>
      </div>
    } else {
      printSizeField = <div data-delay="0" onClick={() => { alert("You must select a Paper Type first.") }} className="dropdown-13 w-dropdown print-size-dropdown-top">
        <div className="dropdown-toggle-5 w-dropdown-toggle">
          <div className="w-icon-dropdown-toggle">
          </div>
          <div className="text-block-153" style={{ textAlign: 'center', whiteSpace: 'pre-line' }}>{printSizePricing.length > 1 && printSizePricingName.length > 1 ? printSizePricingName : "PRINT SIZE"}</div>
        </div>
      </div>
    }

    // if (selections.length > 0 && printSizePricing.length > 0) {
    //   printBorderField = <div data-delay="0" onClick={(e) => { this.toggleDropdownOpen(e, "printBorder-dropdown") }} className="dropdown-15 w-dropdown printBorder-dropdown-top">
    //     <div className="dropdown-toggle-7 w-dropdown-toggle">
    //       <div className="w-icon-dropdown-toggle">
    //       </div>
    //       <div className="text-block-153" style={{ textAlign: 'center', whiteSpace: 'pre-line' }}>{printBorder.length > 1 ? printBorderName : "PRINT BORDER"}</div>
    //     </div>
    //     <nav className="w-dropdown-list dropdown-list-4 printBorder-dropdown" style={{ width: '150px' }} >

    //       <a key="none" style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { this.handlePrintBorderChange(e, "printBorder", "none", "NONE") }} className="w-dropdown-link line-item-select-link"> NONE </a>
    //       <a key="one_eighth" style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { this.handlePrintBorderChange(e, "printBorder", "one_eighth", "1/8\"") }} className="w-dropdown-link line-item-select-link"> 1/8&quot;</a>
    //       <a key="one_fourth" style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { this.handlePrintBorderChange(e, "printBorder", "one_fourth", "1/4\"") }} className="w-dropdown-link line-item-select-link"> 1/4&quot;</a>
    //       <a key="one_half" style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { this.handlePrintBorderChange(e, "printBorder", "one_half", "1/2\"") }} className="w-dropdown-link line-item-select-link"> 1/2&quot;</a>
    //       <a key="three_fourths" style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { this.handlePrintBorderChange(e, "printBorder", "three_fourths", "3/4\"") }} className="w-dropdown-link line-item-select-link"> 3/4&quot;</a>
    //       <a key="one" style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { this.handlePrintBorderChange(e, "printBorder", "one", "1\"") }} className="w-dropdown-link line-item-select-link"> 1&quot;</a>
    //       <a key="one_and_one_half" style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { this.handlePrintBorderChange(e, "printBorder", "one_and_one_half", "1.5\"") }} className="w-dropdown-link line-item-select-link"> 1.5&quot;</a>
    //       <a key="two" style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { this.handlePrintBorderChange(e, "printBorder", "two", "2\"") }} className="w-dropdown-link line-item-select-link"> 2&quot;</a>
    //       <a key="three" style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { this.handlePrintBorderChange(e, "printBorder", "three", "3\"") }} className="w-dropdown-link line-item-select-link"> 3&quot;</a>
    //     </nav>
    //   </div>
    // } else {

    //   printBorderField = <div data-delay="0" onClick={(e) => { alert("You must select a Paper Type and Print Size first.") }} className="dropdown-15 w-dropdown print-border-dropdown-top">
    //     <div className="dropdown-toggle-7 w-dropdown-toggle">
    //       <div className="w-icon-dropdown-toggle">
    //       </div>
    //       <div className="text-block-153" style={{ textAlign: 'center', whiteSpace: 'pre-line' }}>{printBorder.length > 1 ? printBorderName : "PRINT BORDER"}</div>
    //     </div>
    //   </div>
    // }

    let finishingCategoryField
    let finishingCategoryFields = []
    finishingCategoryFields.push(<a key="none" style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { this.handleFinishingServiceCategoryChange(e, "finishingCategory", "none", "none") }} className="w-dropdown-link line-item-select-link"> NONE </a>)

    if (this.state.finishingServiceOptions && this.state.finishingServiceOptions.four_ply_mat && this.state.finishingServiceOptions.four_ply_mat.length > 0) {
      finishingCategoryFields.push(<a key="four_ply_mat" style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { this.handleFinishingServiceCategoryChange(e, "finishingCategory", "four_ply_mat", "4-PLY MAT") }} className="w-dropdown-link line-item-select-link"> 4-PLY MAT </a>)
    }

    if (this.state.finishingServiceOptions && this.state.finishingServiceOptions.eight_ply_mat && this.state.finishingServiceOptions.eight_ply_mat.length > 0) {
      finishingCategoryFields.push(<a key="eight_ply_mat" style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { this.handleFinishingServiceCategoryChange(e, "finishingCategory", "eight_ply_mat", "8-PLY MAT") }} className="w-dropdown-link line-item-select-link"> 8-PLY MAT </a>)
    }

    if (this.state.finishingServiceOptions && this.state.finishingServiceOptions.mount && this.state.finishingServiceOptions.mount.length > 0) {
      finishingCategoryFields.push(<a key="mount" style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { this.handleFinishingServiceCategoryChange(e, "finishingCategory", "mount", "MOUNT") }} className="w-dropdown-link line-item-select-link"> MOUNT </a>)
    }

    if (this.state.finishingServiceOptions && this.state.finishingServiceOptions.contemporary_frame && this.state.finishingServiceOptions.contemporary_frame.length > 0) {
      finishingCategoryFields.push(<a key="contemporary_frame" style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { this.handleFinishingServiceCategoryChange(e, "finishingCategory", "contemporary_frame", "CONTEMPORARY FRAME") }} className="w-dropdown-link line-item-select-link"> CONTEMPORARY FRAME </a>)
    }

    if (this.state.finishingServiceOptions && this.state.finishingServiceOptions.hardwood_frame && this.state.finishingServiceOptions.hardwood_frame.length > 0) {
      finishingCategoryFields.push(<a key="hardwood_frame" style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { this.handleFinishingServiceCategoryChange(e, "finishingCategory", "hardwood_frame", "HARDWOOD FRAME") }} className="w-dropdown-link line-item-select-link"> HARDWOOD FRAME </a>)
    }

    if (this.state.finishingServiceOptions && this.state.finishingServiceOptions.hardwood_float_canvas_frame && this.state.finishingServiceOptions.hardwood_float_canvas_frame.length > 0) {
      finishingCategoryFields.push(<a key="hardwood_float_canvas_frame" style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { this.handleFinishingServiceCategoryChange(e, "finishingCategory", "hardwood_float_canvas_frame", "HARDWOOD FLOAT FRAME") }} className="w-dropdown-link line-item-select-link"> HARDWOOD FLOAT FRAME </a>)
    }

    if (this.state.finishingServiceOptions && this.state.finishingServiceOptions.desktop_frame && this.state.finishingServiceOptions.desktop_frame.length > 0) {
      finishingCategoryFields.push(<a key="desktop_frame" style={{ textAlign: 'center', whiteSpace: 'pre-line' }} onClick={(e) => { this.handleFinishingServiceCategoryChange(e, "finishingCategory", "desktop_frame", "DESKTOP FRAME") }} className="w-dropdown-link line-item-select-link"> DESKTOP FRAME </a>)
    }

    if (selections.length > 0 && printSizePricing.length > 0) {
      finishingCategoryField = <div data-delay="0" onClick={(e) => { this.toggleDropdownOpen(e, "finishingCategory-dropdown") }} className="dropdown-16 w-dropdown finishingCategory-dropdown-top">
        <div className="dropdown-toggle-8 w-dropdown-toggle">
          <div className="w-icon-dropdown-toggle">
          </div>
          <div className="text-block-153" style={{ textAlign: 'center', whiteSpace: 'pre-line' }}>{finishingCategory.length > 1 && finishingCategoryName.length > 1 ? finishingCategoryName : "FINISHING SERVICE"} </div>
        </div>
        <nav className="dropdown-list-4 w-dropdown-list finishingCategory-dropdown"
          style={{ width: '150px' }} >

          {finishingCategoryFields}

        </nav>
      </div>
    } else {
      finishingCategoryField = <div data-delay="0" onClick={() => { alert("You must select a Paper Type and Print Size first.") }} className="dropdown-16 w-dropdown finishingCategory-dropdown-top">
        <div className="dropdown-toggle-8 w-dropdown-toggle">
          <div className="w-icon-dropdown-toggle">
          </div>
          <div className="text-block-153" style={{ textAlign: 'center', whiteSpace: 'pre-line' }}>{finishingCategory.length > 1 && finishingCategoryName.length > 1 ? finishingCategoryName : "FINISHING SERVICE"} </div>
        </div>
      </div>
    }

    // console.log("finishingCategoryName line 979: " + finishingCategoryName)
    let finishingOptionOneField, finishingOptionOneTopLabel, finishingOptionOneHeight
    //console.log("finishingCategoryName " + finishingCategoryName)
    if (selections.length > 0 && finishingCategory.length > 0 && finishingCategoryName.toUpperCase() !== "NONE") {
      if (finishingCategoryName !== null) {
        if (finishingCategoryName.toLowerCase() === "4-ply mat") {
          finishingOptionOneTopLabel = "MAT SIZE"
          finishingOptionOneHeight = '400px'
        } else if (finishingCategoryName.toLowerCase() === "8-ply mat") {
          finishingOptionOneTopLabel = "MAT SIZE"
          finishingOptionOneHeight = '400px'
        } else if (finishingCategoryName.toLowerCase() === "hardwood frame") {
          finishingOptionOneTopLabel = "FRAME WOOD"
          finishingOptionOneHeight = '400px'
        } else if (finishingCategoryName.toLowerCase() === "hardwood float canvas frame") {
          finishingOptionOneTopLabel = "FRAME WOOD"
          finishingOptionOneHeight = '400px'
        } else if (finishingCategoryName.toLowerCase() === "contemporary frame") {
          finishingOptionOneTopLabel = "FRAME WOOD"
          finishingOptionOneHeight = '400px'
        } else if (finishingCategoryName.toLowerCase() === "desktop frame") {
          finishingOptionOneTopLabel = "FRAME WOOD"
          finishingOptionOneHeight = '400px'
        } else if (finishingCategoryName.toLowerCase() === "mount") {
          finishingOptionOneTopLabel = "MOUNT SIZE"
          finishingOptionOneHeight = '50px'
        } else {
          finishingOptionOneTopLabel = "FINISHING DETAIL"
          finishingOptionOneHeight = '400px'
        }
      } else {
        finishingOptionOneTopLabel = "FINISHING DETAIL"
        finishingOptionOneHeight = '400px'
      }
      finishingOptionOneField = <div data-delay="0" onClick={(e) => { this.toggleDropdownOpen(e, "finishingOptionOne-dropdown") }} className="dropdown-17 w-dropdown finishingOptionOne-dropdown-top">
        <div className="dropdown-toggle-9 w-dropdown-toggle">
          <div className="w-icon-dropdown-toggle">
          </div>
          <div className="text-block-153" style={{ textAlign: 'center', whiteSpace: 'pre-line' }}>{finishingOptionOne && finishingOptionOne.length > 1 ? finishingOptionOneName : finishingOptionOneTopLabel}</div>
        </div>
        <nav className="w-dropdown-list finishingOptionOne-dropdown" style={{ width: '150px', height: finishingOptionOneHeight, overflowY: 'scroll' }}>
          {finishingOptionOneForCategory}

        </nav>
      </div>

    } else {
      finishingOptionOneField = <div></div>
    }

    let finishingOptionTwoField, finishingOptionTwoTopLabel
    // console.log("finishingCategoryName line 1023: " + finishingCategoryName)
    if (selections.length > 0 && finishingCategory && finishingCategory.length > 0 && finishingOptionOne && finishingOptionOne.length > 0 && finishingCategory !== "four_ply_mat" && finishingCategory !== "eight_ply_mat") {
      if (finishingCategoryName !== null) {
        if (finishingCategoryName.toLowerCase() === "4-ply mat" || finishingCategoryName.toLowerCase() === "8-ply mat") {
          finishingOptionTwoTopLabel = "MAT CLASS"
        } else if (finishingCategoryName.toLowerCase() === "hardwood frame") {
          finishingOptionTwoTopLabel = "FRAME MAT"
        } else if (finishingCategoryName.toLowerCase() === "hardwood float canvas frame") {
          finishingOptionTwoTopLabel = "FRAME MAT"
        } else if (finishingCategoryName.toLowerCase() === "desktop frame") {
          finishingOptionTwoTopLabel = "FRAME MAT"
        } else if (finishingCategoryName.toLowerCase() === "contemporary frame") {
          finishingOptionTwoTopLabel = "FRAME MAT"
        } else if (finishingCategoryName.toLowerCase() === "mount") {
          finishingOptionTwoTopLabel = "MOUNT COLOR"
        } else {
          finishingOptionTwoTopLabel = "FINISHING DETAIL"
        }
      } else {
        finishingOptionTwoTopLabel = "FINISHING DETAIL"
      }
      finishingOptionTwoField = <div data-delay="0" onClick={(e) => { this.toggleDropdownOpen(e, "finishingOptionTwo-dropdown") }} className="dropdown-18 w-dropdown finishingOptionTwo-dropdown-top">
        <div className="dropdown-toggle-12 w-dropdown-toggle">
          <div className="w-icon-dropdown-toggle">
          </div>
          <div className="text-block-154" style={{ textAlign: 'center', whiteSpace: 'pre-line' }}>{finishingOptionTwo && finishingOptionTwo.length > 1 ? finishingOptionTwoName : finishingOptionTwoTopLabel}</div>
        </div>
        <nav className="w-dropdown-list finishingOptionTwo-dropdown">
          {finishingOptionTwoForCategory}
        </nav>
      </div>
    } else {
      finishingOptionTwoField = <div></div>
    }


    let frameGlassField
    if (selections && selections.length > 0 && finishingOptionOne && finishingOptionOne.length > 0 && finishingOptionTwo && finishingOptionTwo.length > 0 && (finishingCategory === "hardwood_frame" || finishingCategory === "contemporary_frame" || finishingCategory === "desktop_frame" || finishingCategory === "hardwood_float_canvas_frame")) {
      frameGlassField = <div data-delay="0" onClick={(e) => { this.toggleDropdownOpen(e, "frameGlass-dropdown") }} className="dropdown-19 w-dropdown frameGlass-dropdown-top">
        <div className="dropdown-toggle-10 w-dropdown-toggle">
          <div className="w-icon-dropdown-toggle">
          </div>
          <div className="text-block-155" style={{ textAlign: 'center', whiteSpace: 'pre-line' }}>{frameGlass && frameGlass.length > 1 ? frameGlassName : "ACRYLIC"}</div>
        </div>
        <nav className="w-dropdown-list frameGlass-dropdown">
          {frameGlassOptions}
        </nav>
      </div>
    } else {
      frameGlassField = <div></div>
    }

    let frameStainField
    if (selections && selections.length > 0 && finishingOptionOne && finishingOptionOne.length > 0 && (finishingCategory === "hardwood_frame" || finishingCategory === "hardwood_float_canvas_frame" || finishingCategory === "desktop_frame") && finishingOptionTwo && finishingOptionTwo.length > 0 && frameGlass && frameGlass.length > 0 ) {
      frameStainField = <div data-delay="0" onClick={(e) => { this.toggleDropdownOpen(e, "frameStain-dropdown") }} className="dropdown-20 w-dropdown frameStain-dropdown-top">
        <div className="dropdown-toggle-11 w-dropdown-toggle">
          <div className="w-icon-dropdown-toggle">
          </div>
          <div className="text-block-156" style={{ textAlign: 'center', whiteSpace: 'pre-line' }}>{frameStain && frameStain.length > 1 && frameStainName && frameStainName.length > 1 ? frameStainName : "FRAME STAIN"}</div>
        </div>
        <nav className="w-dropdown-list frameStain-dropdown">
          {frameStainOptions}
        </nav>
      </div>
    } else {
      frameStainField = <div></div>
    }

    if (this.state.loadingProducts) {
      return (
        <div className="w-col w-col-2">
          <div style={{ marginTop: "50%", textAlign: 'center' }}>
            loading products...
            </div>

          <div className="button-43 w-button" >
            <Link style={{ color: 'white' }} to={{
              pathname: `/lab-orders/${this.state.guid}/upload-progress`,
              state: {
                orderGuid: `${this.state.guid}`
              }
            }}>
              UPLOAD MORE
              </Link>
          </div>
          {selectButton}
        </div>
      )
    } else {
      return (
        <div className="w-col w-col-2">
          {qtyField}
          {paperField}
          {printSizeField}
          {printBorderField}
          {finishingCategoryField}
          {finishingOptionOneField}
          {finishingOptionTwoField}
          {frameGlassField}
          {frameStainField}

          <Link style={{ color: 'white' }} to={{
            pathname: `/lab-orders/${this.state.guid}/upload-progress`,
            state: {
              orderGuid: `${this.state.guid}`
            }
          }}>
            <div className="button-43 w-button" >
              UPLOAD MORE
              </div>
          </Link>
          {selectButton}
        </div>
      )
    }
  }
}

export default LineItemSidebar;
